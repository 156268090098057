import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import PaginatedTable from '../../components/tables/PaginatedTable';
import Table from '../../components/tables/Table';
import { getEvaluatorData } from './evaluatorAPI';
import { assignTaskThunk, uploadEvaluatorThunk, deleteEvaluatorThunk, deactivateEvaluatorThunk, evaluatorListThunk, bulkUploadEvaluatorsThunk,  } from './evaluatorSlice';
import EvaluatorSingleUploadForm from './EvaluatorSingleUploadForm';
import EvaluatorBulkUploadForm from './EvaluatorBulkUploadForm';
import { CloudDownloadRounded, DriveEta } from '@material-ui/icons';

const Evaluator = () => {
    const dispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm();
    const evaluators = useSelector(state => state.evaluators);
    const { allCampaigns, campaignTypes } = useSelector(state=> state.corporate);
    const branches = useSelector(state => state.branches);
    const { campaignID } = useSelector(state => state.dataSummary);

    useEffect(()=>{
        dispatch(evaluatorListThunk());
    }, [])
    
    const activeEvaluators = evaluators.filter(evaluator => evaluator.status === 'active');


    const table = {
        title : 'Evaluators',
        tHeaders : [ 'Access code', 'Name', 'Email', 'Phone Number', 'Zone', 'State', 'Status', 'Created At', 'Action', ],
        tProperties : [  'access_code', 'full_name', 'email', 'phone', 'zone', 'state', 'status', 'createdAt',  ],
    }

    const [taskFormDisplay, setTaskFormDisplay] = useState("hidden");
    const handleClickAssignTask = (record) => {
        setTaskFormDisplay("");
        setProfileCardDisplay("hidden")
        setTaskFormObject({...taskFormObject, evaluator_access_code: record.access_code, evaluator_name: record.full_name});
        //console.log({record})

    }


    // VIEW EVALUATOR PROFILE
    const [profileCardDisplay, setProfileCardDisplay] = useState("hidden");
    const [profileCardObject, setProfileCardObject] = useState({});
    const handleClickView = (record) => {
        setProfileCardDisplay("");
        //console.log({record})
        const token = sessionStorage.getItem('token');
        toast.loading('loading Profile...')
        getEvaluatorData(record.access_code, campaignID, token).then((res) => {
            toast.loading('loading ')
            return {
                wages: res.wages,
                assigned: res.completionScope.assign,
                done: res.completionScope.done,
                rejected: res.totalRejected,
                pending: res.totalPending,
            }
        }).then(result=>{     
            setProfileCardObject({ ...record, ...result });
            toast.remove()
        }).catch(e=> {
            console.log(e.message);
            toast.remove();
            const details= {
                wages: 0,
                assigned: '0',
                done: '0',
                rejected: '0',
                pending: '0',
            }
            setProfileCardObject({...record, ...details});
        });
        
    }

    //console.log({profileCardObject})



    /* ACTIONS */

    // Assign Task
    const [taskFormObject, setTaskFormObject] = useState({});
    const handleOnChangeTaskForm = (e) => {
        setTaskFormObject({ ...taskFormObject, [e.target.name]: e.target.value })
    }
    const [taskTypeCheckList, setTaskTypeCheckList] = useState([]);
    const [task_type, setTaskType] = useState([]);
    const handleTask_Type = (e)=>{
        if(e.target.value == 'ALL'){
            const allChecks = campaignTypes?.map(type=> type.type);
            e.target.checked? setTaskTypeCheckList(allChecks) : setTaskTypeCheckList([]);
        } else if(e.target.checked) {
            setTaskTypeCheckList([...task_type, e.target.value])
            taskTypeCheckList.length == (campaignTypes?.length - 1)? document.getElementById('ALL_TASK_CHECKBOX').checked = true : document.getElementById('ALL_TASK_CHECKBOX').checked = false;
        } else {
            setTaskTypeCheckList(taskTypeCheckList.filter(task => task !== e.target.value));
            document.getElementById('ALL_TASK_CHECKBOX').checked = false;
        }
    }

    useEffect(()=>{
        if(taskTypeCheckList.length >= campaignTypes?.length){
            setTaskType(['ALL']);
        }
        else if(taskTypeCheckList.length < 3){
            setTaskType(taskTypeCheckList);
        }
        else{
            setTaskType([]);
        }
    } ,[campaignTypes?.length, taskTypeCheckList])

    console.log({taskTypeCheckList, task_type})

    const handleSubmitTaskForm = (e) => {
        e.preventDefault();
        if (!taskFormObject.campaign_id) { taskFormObject.campaign_id = allCampaigns[allCampaigns.length -1].campaign_id; };

        toast.remove();
        task_type.length? 
        task_type.forEach(type=> {
            const copyObject = { ...taskFormObject, branch_id: taskFormObject.branch1_id, task_type: type };
            const copyObject2 = taskFormObject.branch2_id? { ...taskFormObject, branch_id: taskFormObject.branch2_id, task_type: type } : null;
            
            taskFormObject.branch2_id? dispatch(assignTaskThunk([copyObject, copyObject2])).then(({payload})=> {
                return payload.success? setTaskFormDisplay("hidden") : null;
            })
            : dispatch(assignTaskThunk([copyObject])).then(({ payload })=> {
                return payload.success? setTaskFormDisplay("hidden") : null;
            });
        })
        : toast.error('Please select at least one task type');
    }

    // Upload Evaluators
    const [uploadFormDisplay, setUploadFormDisplay] = useState("hidden");
    const [bulkUploadFormDisplay, setBulkUploadFormDisplay] = useState("hidden");

    const showSingleUploadForm = () => {
        setBulkUploadFormDisplay("hidden");
        uploadFormDisplay === "hidden"? setUploadFormDisplay("") : setUploadFormDisplay("hidden");
    }
    const showBulkUploadForm = () => {
        setUploadFormDisplay("hidden");
        bulkUploadFormDisplay === "hidden"? setBulkUploadFormDisplay("") : setBulkUploadFormDisplay("hidden");
    }

    const handleSingleUploadEvaluator = (record) => {
        dispatch(uploadEvaluatorThunk({...record, evaluator_name: record.evaluator_name.toUpperCase()}))
        .then(({meta})=> {
            if (meta.requestStatus === "fulfilled") { setUploadFormDisplay("hidden"); dispatch(evaluatorListThunk()); setTaskFormObject({}); }
            else { toast.remove(); toast.error('Upload Failed, Please Try Again')}
        });
    }

    const handleBulkUploadEvaluator = ({file, campaign_id}) => {
        const formData = new FormData();
        formData.append('file', file[0]);
        formData.append('campaign_id', campaign_id);

        dispatch(bulkUploadEvaluatorsThunk(formData))
        .then(({meta})=> {
            toast.remove(); toast.success('Uploaded Successfully!'); dispatch(evaluatorListThunk());
            if (meta.requestStatus === "fulfilled") { setTaskFormDisplay("hidden"); }
        });
        
        //console.log({ file: file[0], campaign_id});
    }

    // BLACKLIST
    //==> Deactivate or Delete Evaluator
    const [blacklistFormDisplay, setBlacklistFormDisplay] = useState('hidden');
    const [confirmActionDisplay, setConfirmActionFormDisplay] = useState('hidden');
    const [blacklistRecord, setBlacklistRecord] = useState({});
    const [blacklistAction, setBlacklistAction] = useState('Deactivate');
    const handleClickBlacklist = (record) => {
        setBlacklistRecord(record);
        setBlacklistFormDisplay('');
    }

    const handleClickDeleteEvaluator = (e) => {
        e.preventDefault();
        setBlacklistAction('Delete');
        setBlacklistFormDisplay('hidden');
        setConfirmActionFormDisplay('');
    }

    const handleClickDeactivateEvaluator = (e) => {
        e.preventDefault();
        setBlacklistAction('Deactivate');
        setBlacklistFormDisplay('hidden');
        setConfirmActionFormDisplay('');
    }

    const handleConfirmAction = (e) => {
        e.preventDefault();
        (blacklistAction == 'Delete'? dispatch(deleteEvaluatorThunk(blacklistRecord.id)) : dispatch(deactivateEvaluatorThunk(blacklistRecord.id)))
        .then(({meta})=> {
            if (meta.requestStatus == 'fulfilled') {
                toast.remove();
                toast.success(`Evalautor ${blacklistAction}d Successfully!`);
                setConfirmActionFormDisplay('hidden');
                dispatch(evaluatorListThunk());
            }
            else {
                toast.remove();
                toast.error('Evaluator Blacklist Failed, Please try again')
            }
        })
        .then(({meta})=> {
            if (meta.requestStatus == 'fulfilled') {
                toast.remove();
                toast.success('Evalautor List Updated!');
            }
            else {
                toast.remove();
                toast.error('Evaluator List Refresh Failed, Please Refresh Page')
            }
        })
    }



    //FILTER LOGIC
    const [filteredEvaluators, setFilteredEvaluators] = useState(evaluators);

    const [searchTerm, setSearchTerm] = useState('');
    const handleSearchFilter = (e) => {
        setSearchTerm(e.target.value);
    }

    const [zoneFilterTerm, setZoneFilterTerm] = useState('');
    const handleZoneFilter = (e) => {
        setZoneFilterTerm(e.target.value);
    }

    const [statusFilterTerm, setStatusFilterTerm] = useState('');
    const handleStatusFilter = (e) => {
        setStatusFilterTerm(e.target.value);
    }

    const [locationFilterTerm, setLocationFilterTerm] = useState('');
    const handleLocationFilter = (e) => {
        setLocationFilterTerm(e.target.value);
    }

    useEffect(() => {
        const ST = searchTerm.toLowerCase();
        const ZFT = zoneFilterTerm.toLowerCase();
        const SFT = statusFilterTerm.toLowerCase();
        const LFT = locationFilterTerm.toLowerCase();

        //if(SFT && !LFT && !ST && !ZFT){

        //}




        if(ST.length && !ZFT.length) {
            setFilteredEvaluators(evaluators.filter(evaluator => evaluator.full_name.toLowerCase().includes(ST) || evaluator.access_code.toLowerCase().includes(ST)));
        } 
        else if(!ST.length && ZFT.length) {
            setFilteredEvaluators(evaluators.filter(evaluator => evaluator.zone.toLowerCase() === ZFT));
        }
        else if(ST.length && ZFT.length) {
            setFilteredEvaluators(evaluators.filter(evaluator => (evaluator.full_name.toLowerCase().includes(ST) || evaluator.access_code.toLowerCase().includes(ST)) && evaluator.zone.toLowerCase() === ZFT));
        }
        else if(!ST.length && !ZFT.length) {
            setFilteredEvaluators(evaluators);
        }
        else { setFilteredEvaluators(evaluators); }

    }, [evaluators, searchTerm, zoneFilterTerm, statusFilterTerm, locationFilterTerm])

    



    return (
        <div className='flex flex-col p-2 bg-slate-100'>
            <div className="flex w-full my-2 justify-start items-center">
                <h1 className="font-bold w-max mx-3 text-xl text-slate-400">Manage Evaluators - </h1>
                <p className="font-medium text-slate-500">
                    Here you can <span className="text-blue-400"> Upload, View, Blacklist and Assign tasks to Evaluators </span>
                </p>
            </div>
{/* SUMMARY CARDS */}
            <div className='flex justify-center w-full'>
                <div className="flex flex-col bg-white rounded border shadow items-center w-1/3 p-4 m-2">
                    <h1 className='font-bold text-blue-600'>Total Evaluators</h1>
                    <p className='font-bold text-xl text-slate-700'>{evaluators.length}</p>
                </div>
                <div className="flex flex-col bg-white rounded border shadow items-center w-1/3 p-4 m-2">
                    <h1 className='font-bold text-blue-600'>Active Evaluators</h1>
                    <p className='font-bold text-xl text-slate-700'>{activeEvaluators.length}</p>
                </div>
            </div>
            <div className='flex justify-start'>
                <button onClick={showSingleUploadForm} className="bg-dayoAshBlue text-white px-4 py-2 rounded-lg mx-2 my-4">Add Evaluator</button>
                <button onClick={showBulkUploadForm} className="bg-dayoAshBlue text-white px-4 py-2 rounded-lg mx-2 my-4">Bulk Upload Evaluators</button>
            </div>

{/* UPLOAD FORM */}
            <div className={`${uploadFormDisplay} flex justify-center w-full py-4 my-4 bg-inherit rounded-lg`}>
                <EvaluatorSingleUploadForm onSubmitForm={handleSingleUploadEvaluator} />
            </div>
            <div className={`${bulkUploadFormDisplay} flex justify-center w-full py-4 my-4 bg-inherit rounded-lg`}>
                <EvaluatorBulkUploadForm onSubmitForm={handleBulkUploadEvaluator} />
            </div>

{/* TABLE AND FILTERS */}
            <div className='flex flex-col border border-slate-100 shadow'>
                <div className="flex items-center justify-between w-full m-1">
                    
                    <div className='w-full'>
                        <input type='text' onChange={handleSearchFilter} className="w-1/3 m-3 px-3 py-2 rounded-full border border-slate-300 focus:outline-blue-400" placeholder="Search Evaluator Name or Code" />
                        <select onChange={handleZoneFilter} className="w-1/6 m-3 px-3 py-2 rounded border border-slate-300 focus:outline-blue-400 ">
                            <option value="">Zones</option>
                            <option value="SW">South West</option>
                            <option value="SE">South East</option>
                            <option value="SS">South South</option>
                            <option value="NE">North East</option>
                            <option value="NW">North West</option>
                            <option value="NC">North Central</option>
                        </select>
                    </div>
                    {/*<select onChange={handleStatusFilter} className="w-1/6 m-3 px-3 py-2 rounded border border-slate-300 focus:outline-blue-400 ">
                        <option value="ALL">Status</option>
                        <option value="active">Active</option>
                        <option value="Inactive">Inactive</option>
                    </select>*/}
                    <div className=" flex items-center justify-around rounded min-w-max h-12 pl-2 pr-0 py-0 mx-2 bg-dayoAshBlue hover:bg-blue-600 text-white"><CloudDownloadRounded /><ReactHtmlTableToExcel className='rounded p-3 px-5 mx-5 bg-dayoAshBlue hover:bg-blue-600 text-white' table={"evaluatorsTable"} buttonText={"Download Table"} filename="Evaluators List" sheet="evaluators_xls" /></div>
                    
                    <Table table={table} hidden={true} data={ filteredEvaluators } id={"evaluatorsTable"} />
                {/*
                    <select className="w-1/6 m-3 px-3 py-2 rounded border border-slate-300 focus:outline-blue-400 ">
                        <option>Performance</option>
                        <option>Poor performers (less than 60%)</option>
                        <option>Performers (more than 60%)</option>
                        <option>Super performers (more than 95%)</option>
                    </select>
                */}
                </div>

                <PaginatedTable isAction={true} actionElements={[{text: "Details", onClick: handleClickView}, {text: "Assign Task", onClick: handleClickAssignTask}, {text: "Blacklist", onClick: handleClickBlacklist, class: 'w-full rounded bg-dayoDarkGray text-slate-100 m-1 p-2 focus:bg-red-500 hover:bg-red-600'}]} data={filteredEvaluators} table={table} dataLimit={10} pageLimit={10} />

                {/**Hidden Task Assignment form and Hidden Profile Card */}
                
            </div>

{/* POP UP SECTIONS */}
            {/* Add Campaign */}
            <section className={`flex justify-center text-white items-center w-full h-full bg-transparent fixed ${taskFormDisplay}`}
                style={{backgroundColor: 'rgba(25, 25, 25, 0.2'}}
            >
                <form onChange={handleOnChangeTaskForm} onSubmit={handleSubmitTaskForm} className={`flex flex-col w-2/4 lg:w-1/4 lg:min-w-max rounded-lg bg-slate-100 shadow-md ${taskFormDisplay}`}>
                    <div className='flex w-full px-2 justify-between items-center bg-sideBarBlue rounded-t-lg'>
                        <h2 className='text-slate-100 font-bold text-sm'>{ taskFormObject.evaluator_name } - { taskFormObject.evaluator_access_code }</h2>
                        <button onClick={(e)=>{e.preventDefault(); setTaskFormDisplay("hidden")}} className="bg-dayoAshBlue text-white font-bold p-1 rounded-full h-8 w-8 my-4 hover:bg-red-500 hover:text-white">x</button>
                    </div>

                    <div className='w-full px-2'>
                        <label className='flex flex-col font-bold text-blue-900 font-md'>
                            <span className="font-bold text-blue-900 my-2">Campaign</span>
                            <select name='campaign_id' className='w-full p-2 rounded border border-slate-300' required>
                                <option disabled>Select Campaign</option>
                                {
                                    allCampaigns? allCampaigns.map(campaign => {
                                        return <option value={campaign.campaign_id}>{campaign.campaign_name}</option>
                                    }) : `Loading...`
                                }
                            </select>
                        </label>
                        <label className='flex flex-col font-bold text-blue-900 font-md'>
                            <span className="font-bold text-blue-900 my-2">Branch 1</span>
                            <input type="text" name='branch1_id' list='branches' className='w-full rounded border p-2 border-slate-300' required/>
                            <datalist id='branches'>
                                <option value=''>Branch Code</option>
                                {
                                    branches.map(branch => {
                                        return <option value={branch.code}>{`${branch.code}`}</option>
                                    })
                                }
                            </datalist>
                        </label>
                        <label className='flex flex-col font-bold text-blue-900 font-md'>
                            <span className="font-bold text-blue-900 my-2">Branch 2</span>
                            <input type="text" name='branch2_id' list='branches' className='w-full rounded border p-2 border-slate-300'/>
                            <datalist id='branches'>
                                <option value=''>Branch Code</option>
                                {
                                    branches.map(branch => {
                                        return <option value={branch.code}>{`${branch.code}`}</option>
                                    })
                                }
                            </datalist>
                        </label>
                        <label className='flex flex-col font-bold text-blue-900 font-md'>
                            <span className="font-bold text-blue-900 my-2">Assessment Date</span>
                            <input type="date" placeholder="dd-mm-yyyy" name='date_assigned' className='w-full rounded p-2 border border-slate-300' required/>
                        </label>
                        <div className='flex flex-col font-bold text-blue-900 font-md'>
                            <span className="font-bold text-blue-900 my-2">Assessment Type</span>
                            <div className='flex flex-col lg:flex-row flex-wrap justify-between w-full lg:min-w-max rounded p-2 border border-slate-300' required>
                                <label className='flex items-center w-1/4 '>
                                    <input type='checkbox' onChange={handleTask_Type} value={'ALL'} id={"ALL_TASK_CHECKBOX"} className='form-checkbox ' />
                                    <span className='mx-1 text-slate-900'>{'ALL'}</span>
                                </label>
                                {
                                    campaignTypes?.map(type => {
                                        return <label className='flex items-center w-1/4 min-w-max'>
                                                <input type='checkbox' checked={taskTypeCheckList?.includes(type.type)} onChange={handleTask_Type} value={type.type} className='form-checkbox ' />
                                                <span className='mx-1 text-slate-900'>{type.type}</span>
                                            </label>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className='w-full px-4'>
                        <input type="submit" value="Assign Task" className='w-full bg-dayoAshBlue text-white p-2 rounded-lg my-4 hover:bg-sideBarBlue'/>
                    </div>                       
                </form>
            </section>

            {/* Evaluator's Profile */}
            <section className={`flex justify-center text-white items-center w-full h-full bg-transparent fixed ${profileCardDisplay}`}
                style={{backgroundColor: 'rgba(25, 25, 25, 0.2'}}
            >
                <div className={`flex flex-col w-1/3 md:w-120 rounded-lg p-1 bg-sideBarBlue border border-blue-400 shadow-md ${profileCardDisplay}`}>
                    <div className='flex w-full bg-sideBarBlue justify-between items-center'>
                        <h2 className='text-white font-bold ml-3'>{ `${profileCardObject.full_name} (${profileCardObject.access_code})`  }</h2>
                        <button onClick={(e)=>{e.preventDefault(); setProfileCardDisplay("hidden")}} className="bg-dayoAshBlue text-white text-center p-1 h-8 w-8 rounded-full mr-4 my-4 hover:bg-red-500">x</button>
                    </div>
                    <div className='flex flex-col px-1 py-2 text-slate-600 items-start w-full bg-white'>
                        <p className='p-2 m-1 w-full border bg-slate-100'>Total assigned tasks: <span className='text-blue-500 '>{profileCardObject.assigned}</span></p>
                        <p className='p-2 m-1 w-full border bg-slate-100'>Total Submitted entries: <span className='text-blue-500 '>{profileCardObject.done}</span></p>
                        <p className='p-2 m-1 w-full border bg-slate-100'>Rejected Entries: <span className='text-red-500 '>{profileCardObject.rejected}</span></p>
                        <p className='p-2 m-1 w-full border bg-slate-100'>Pending Entries: <span className='text-slate-500 '>{profileCardObject.pending}</span></p>
                        <p className='p-2 m-1 w-full border bg-slate-100'>Total Earning: <span className='text-green-600 '>NGN {profileCardObject.wages}</span></p>
                    </div>
                </div>
            </section>

            {/* Blacklist Evaluator */}
            <section className={`flex justify-center text-white items-center w-4/5 h-full bg-transparent fixed ${blacklistFormDisplay}`}
                style={{backgroundColor: 'rgba(25, 25, 25, 0.2'}}
            >
                <form className={`flex flex-col w-1/2 min-w-max h-1/3 min-h-max lg:w-1/2 rounded-lg bg-slate-100 border border-slate-200 shadow-md`}>
                    <div className='flex w-full px-2 rounded-t-lg justify-between items-center bg-sideBarBlue'>

                        <button onClick={(e)=>{e.preventDefault(); setBlacklistFormDisplay("hidden")}} className="bg-white text-red-600 font-bold px-2 py-0 text-center rounded-full my-4 hover:bg-red-500 hover:text-white">x</button>
                    </div>
                    <div className="w-full h-full px-2">
                        <h1 className="text-center text-slate-600 font-md">Blacklist Evaluator: <span className='text-sideBarBG'>{blacklistRecord.full_name? `${blacklistRecord.full_name}`  : ''}</span> - {blacklistRecord.access_code? blacklistRecord.access_code : ''} </h1>
                        <h1 className="flex items-center justify-center text-slate-600 font-md h-1/3">Choose an Action Below</h1>
                        <div className="flex justify-between px-4">
                            <button onClick={handleClickDeactivateEvaluator} className="text-center w-2/3 p-3 mx-2 bg-dayoOrange hover:bg-orange-600 font-bold rounded" >Deactivate Evaluator</button>
                            <button onClick={handleClickDeleteEvaluator} className="text-center w-1/3 min-w-max p-3 mx-2 bg-red-500 hover:bg-red-600 font-bold rounded" >Delete Evaluator</button>
                        </div>
                        <button onClick={(e)=>{e.preventDefault(); setBlacklistFormDisplay("hidden")}} className="flex items-center justify-center w-1/2 p-3 mt-2 mb-0 mx-auto bg-dayoAshBlue hover:bg-sideBarBlue font-bold rounded" >Cancel</button>
                        
                    </div>
                                         
                </form>
            </section>
            
            {/* Confirm Action */}
            <section className={`flex justify-center text-white items-center w-4/5 h-full bg-transparent fixed ${confirmActionDisplay}`}
                style={{backgroundColor: 'rgba(25, 25, 25, 0.2'}}
            >
                <form onSubmit={handleConfirmAction} className={`flex flex-col w-1/2 h-1/3 min-h-max lg:w-1/2 rounded-lg bg-slate-100 border border-slate-200 shadow-md`}>
                    <div className='flex w-full px-2 rounded-t-lg justify-between items-center bg-slate-200'>
                        <button onClick={(e)=>{e.preventDefault(); setConfirmActionFormDisplay("hidden")}} className="bg-white text-red-600 font-bold px-2 py-0 text-center rounded-full my-4 hover:bg-red-500 hover:text-white">x</button>
                    </div>
                    <div className="w-full h-full px-2">
                        
                    <h1 className="text-center text-slate-600 font-md"><span className={`font-bold  ${blacklistAction == 'Delete'? 'text-red-500 ' : 'text-amber-600'} `}>{blacklistAction}</span> Evaluator: <span className='text-sideBarBG'>{blacklistRecord.full_name? `${blacklistRecord.full_name}`  : ''}</span> - {blacklistRecord.access_code? blacklistRecord.access_code : ''} </h1>
                        <h1 className="flex items-center justify-center text-slate-600 font-md h-1/3">Are you sure you want to {blacklistAction} this Evaluator?</h1>
                        <div className="flex justify-between">
                            <input type="submit" className="flex items-center justify-center w-1/2 p-3 my-2 bg-red-600 hover:bg-red-500 font-bold rounded" value={`${blacklistAction} Evaluator`} />
                            <button onClick={(e)=>{e.preventDefault(); setConfirmActionFormDisplay("hidden")}} className="flex items-center justify-center w-1/2 p-3 my-2 mx-1 bg-dayoAshBlue hover:bg-sideBarBlue font-bold rounded" >Cancel</button>
                        </div>
                        
                    </div>
                                         
                </form>
            </section>
        </div>
    )
}

export default Evaluator;