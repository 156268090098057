// column chart
import { data } from 'autoprefixer';
import ReactApexChart from 'react-apexcharts';

const GroupedBarChart = ({ data, isData2, xaxis, titleText, className }) => {
	const series = data.map(item => ({
		name: item.name,
		data: !isData2? item.data : item.data2
	}))
	const state = {
		series: series,
		options: {
			chart: {
				height: 430,
				width: '100%',
				type: 'bar',
				animation: {
					enabled: "true",
					easing: "easeinout",
					speed: 800,
					animateGradually: {
						enabled: true,
					},
					dynamicAnimation: {
						enabled: true,
						speed: 350
					}
				},
			},
			colors: ['#0096ff', '#00B8FF', '#afafaf', '#cccccc'] || data.map(item=> item.colorCode),
			plotOptions: {
				bar: {
					columnWidth: '50%',
					distributed: false,
					horizontal: true,
				},
			},
			stroke: {
			  show: true,
			  width: 1.5,
			  colors: ['#fff']
			},
			tooltip: {
			  shared: false,
			  intersect: true
			},
			dataLabels: {
				enabled: true,
			},
			legend: {
				show: true,
			},
			xaxis: {
				categories: xaxis,
				colors: ['#fff', '#afafaf', '#afafaf', '#cccccc'],
				labels: {
					show: false,
					style: {
						colors: ['#fff', '#afafaf',],
						fontSize: '12px',
						fontFamily: 'Helvetica, Arial, sans-serif',
						fontWeight: 400,
						cssClass: 'apexcharts-xaxis-label',
					},
				},
			},
			yaxis: {
				labels: {
					show: true,
					style: {
						colors: [ 'gray',],
						fontSize: '18px',
						fontFamily: 'lato, Arial, sans-serif',
						fontWeight: 600,
						cssClass: 'y-axis-column-text',
					},
				},
			},
			title: {
				text: titleText? titleText : 'Grouped Bar Chart',
				floating: true,
				style: {
					color: 'gray',
					fontFamily: 'roboto, Arial, sans-serif',
					fontSize: '20px',
					fontWeight: 900,
				}
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: '100%',
							height: 295,
						},			
						colors: ['#025dff', '#ccc'],
						dataLabels: {
							enabled: true,
						},
						legend: {
							position: 'bottom',
						},
						title: {
							text: titleText? titleText : 'Title of Chart',
						},
					},
				},
			],
		},
	};

	return (
		<div className={className? className : 'w-full rounded-lg p-1 bg-white'}>
			<ReactApexChart options={state.options} series={state.series} type="bar" height={380} />
		</div>
	);
};

export default GroupedBarChart;