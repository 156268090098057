
import { useForm } from "react-hook-form";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { useSelector } from "react-redux";
import Table from "../../components/tables/Table";


const BranchBulkUploadForm= ({onSubmitForm}) => {
    
    const { register, handleSubmit, errors } = useForm();
    const { LGA } = useSelector(state => state.dataSummary);

    const LGATable={
        tHeaders: ['LGA ID', 'LGA', 'STATE', 'ZONE', 'GROUP ID'],
        tProperties: ['lga_id', 'lga', 'state', 'zone', 'group_id']
    }

    return (
        <div className={`flex w-max p-2 m-4 bg-white shadow-md rounded-lg`}>
            <form className={` flex flex-col w-1/2 lg:w-1/2 p-4 rounded border border-slate-300 bg-300 justify-center shadow-md `} onSubmit={handleSubmit(onSubmitForm)}>
                <h1 className="text-slate-600 font-bold m-2 ml-0">Bulk Upload Branch</h1>
                <input
                    {...register("file")}
                    type="file"
                    className="border border-slate-500 rounded p-2"
                    accepted=".csv .xlsx .xls"
                    required
                />
                <input className="rounded w-full p-3 m-2 mx-auto text-slate-100 bg-sideBarBlue hover:font-bold border border-sideBarBlue hover:text-white" type="submit" value="Upload" />
            </form>

            <div className="m-3 min-w-max">
                <h1 className="text-slate-600 font-bold m-2 ml-0">Upload Directives (for beginners)</h1>
                <ol>
                    <li>1. Download Template File and LGA Mapping File</li>
                    <li>2. Fill Template file correctly, Ensuring:
                        <p className="indent-4">a. Branch Code MUST Start with Correct Bank Name Code</p>
                        <p className="text-slate-500 indent-8">e.g "ACB for Access bank etc."</p>
                        <p className="indent-4">b. Branch Code is Composed of Three Numbers: </p>
                        <p className="indent-8 text-red-500">ACB25 is WRONG</p>
                        <p className="indent-8 text-green-700">ACB025 is CORRECT</p>
                    </li>
                    <li>3. Upload Branches file</li>
                    <li>5. Click Upload</li>
                </ol>
                
                <ReactHtmlTableToExcel className="py-3 px-4 m-4 w-max h-max text-white bg-green-500" table={"templateUpload"} filename={"templateEvaluatorUpload"} sheet={"templateEvaluatorUpload"} buttonText={"Download Template File"} />
                <ReactHtmlTableToExcel className="py-3 px-4 m-4 w-max h-max text-white bg-green-500" table={"lgaExcel"} filename={"LGA List"} sheet={"LGA Nationwide"} buttonText={"Download LGA Mapping File"} />
            </div>

            <Table table={LGATable} data={LGA} id={'lgaExcel'} hidden={true} />

            <table id="templateUpload" className="hidden">
                <thead>
                    <tr>
                        {
                            ["ADDRESS", "LGA ID", "BRANCH NAME", "BRANCH CODE", "BANK NAME"].map(header => {
                                return <th key={header}>{header}</th>
                            })
                        }
                    </tr>
                </thead>
            </table>
        </div>
    )
}

export default BranchBulkUploadForm;