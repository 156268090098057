import axios from "axios";
import { today } from "../report/Report";
//const campaignID = Number(sessionStorage.getItem('campaignID')) || 3;

const baseURL = 'https://mysteryshopper.connectifie.com/api/v1.0';

export const fetchDataSummary = async (token, campaignID=3) =>{
    const { data } = await axios.get(`${baseURL}/evaluator-list?campaign_id=${campaignID}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    const dataSummary= {
        statusCount: data.StatusCount,
        zoneCount: data.zoneCount,
        stateCount: data.stateCount
    }
    //console.log({ dataSummary });

    return dataSummary;
}

export const fetchZonalTrend = async (token, dates, campaignID=3) =>{
    const response = await dates.map(async (date)=> {
        const {data} = await axios.get(`${baseURL}/campaign/assessment/scope-daily/${date}?campaign_id=${campaignID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const { zoneScope, groupScope } = data;
        return {...zoneScope, ...groupScope};
    });

    const zonalTrend = await Promise.all(response);

    const makePercentage= (done, assigned) => {
        if(assigned === 0 || done === 0) {
            return 0;
        }
        return Math.round((done/assigned)*100) > 100 ? 100 : Math.round((done/assigned)*100);
    }


    const pastWeekZoneResult = zonalTrend.map(zoneScope=>{
        return {
            groupOne: makePercentage(zoneScope.groupOne.done, zoneScope.groupOne.assigned),
            northCentral: makePercentage(zoneScope.nc.done, zoneScope.nc.assigned),
            northEast: makePercentage(zoneScope.ne.done, zoneScope.ne.assigned),
            northWest: makePercentage(zoneScope.nw.done, zoneScope.nw.assigned),
            southEast: makePercentage(zoneScope.se.done, zoneScope.se.assigned),
            southSouth: makePercentage(zoneScope.ss.done, zoneScope.ss.assigned),
            southWest: makePercentage(zoneScope.sw.done, zoneScope.sw.assigned),
        }
    })
    return pastWeekZoneResult;

}

export const fetchOverallScopeCoverage = async (token, campaignID=3) =>{
    //console.log({campaignID})
    const { data: scopeCoverage } = await axios.get(`${baseURL}/campaign/assessment/scope?campaign_id=${campaignID}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    //console.log({ scopeCoverage })
    return scopeCoverage;
}

export const fetchDailyScopeCoverage = async (token, date, campaignID=3) => {
    const formattedDate= date.split('-')[0].length < 4? date : date.split('-').reverse().join('-');
    const { data: dailyScope } = await axios.get(`${baseURL}/campaign/assessment/scope-daily/${formattedDate}?campaign_id=${campaignID}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    console.log({campaignID, formattedDate, typeData: dailyScope?.assessmentTypeScope })

    return dailyScope;
}

export const fetchDailyCampaignProgress = async (token, dates, campaignID=3, ) => {
    try{
        const response = await dates.map(async (date)=> {
            const { data: dailyScope } = await axios.get(`${baseURL}/campaign/assessment/scope-daily/${date}?campaign_id=${campaignID}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return dailyScope;
        });

        const dailyScope = await Promise.all(response);

        const makePercentage= (done, assigned) => {
            if(assigned === 0 || done === 0) {
                return 0;
            }
            return Math.round((done/assigned)*100) > 100 ? 100 : Math.round((done/assigned)*100);
        }

        const allCampaignLongDailyCompletion = dailyScope.map((day, i)=>{
            const date = dates[i];
            const totalDoneOfDay = campaignID === 3? day.assessmentTypeScope.intern.done : day.groupScope.groupOne.done + day.groupScope.groupTwo.done + day.groupScope.groupThree.done;
            const totalAssignedOfDay = campaignID === 3? day.assessmentTypeScope.intern.assigned : day.groupScope.groupOne.assigned + day.groupScope.groupTwo.assigned + day.groupScope.groupThree.assigned;

            //console.log({campaignID, totalDoneOfDay, totalAssignedOfDay, date})

            return {data: makePercentage(totalDoneOfDay, totalAssignedOfDay), date};
        })

        //console.log({ allCampaignLongDailyCompletion })

        return allCampaignLongDailyCompletion;
        

        

    } catch(e){
        console.log(e);
    }
}

export const fetchCummulativeCampaignProgress = async (token, campaignID=3) => {
    const { data: cumulativeProgress } = await axios.get(`${baseURL}/campaign/scope/cummulative/${campaignID}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    const result = cumulativeProgress.cummulativeScope.map(day=> {

        return { data: Math.ceil(day.scopeInPercentage), date: day.day }
    })

    return result.reverse();
}

export const fetchDates = async (token, campaignID=3) => {
    const { data: dates } = await axios.get(`${baseURL}/all-date/${campaignID}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    //console.log({dates})
    return dates.allDaysOfAssessment.reverse();
}

export const FetchStates = async (token, ) => { 
    try{
        const { data } = await axios.get(`${baseURL}/report/all-state`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        //console.log({ data })
        return data.allState;
    } catch(e) {
        console.log(e.message)
    }
}

export const FetchLGAs = async (token, ) => { 
    try{
        const { data } = await axios.get(`${baseURL}/lga`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        //console.log({ data })
        return data;
    } catch(e) {
        console.log(e.message)
    }
}

