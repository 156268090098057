/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import Table from "../../components/tables/Table";
import { allBranchThunk, allFLEThunk, allInternThunk, allSECThunk, deleteEntryThunk, editEntryThunk, postAlterEntryThunk, reportsThunk } from "./reportSlice";
import PaginatedTable from "../../components/tables/PaginatedTable";
import toast from "react-hot-toast";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import Table from "../../components/tables/Table";
import { 
    CloudDownloadRounded,
    ArrowDropDownIcon,
    KeyboardArrowDownRounded,
    KeyboardArrowRightRounded
 } from "@material-ui/icons";
import SkeletonLoad from "../../components/skeletonLoader/skeletonLoader";
import FormTable from "../../components/tables/FormTable";
import { getAlterEntry } from "./reportAPI";


/**
 * The Page loads and data is fetched on every date // entries and allReport are also re-fetched on campaign change // it has two parent sources of data - entries and allReport : therefore high level filter
 * Data is stored in state as entries (constraint by date) and allReport (no date constraint)
 * these stateData are passed through identical filters: parent filter which are the single filters and family filters which are the multiple filters (e.g. Group: state, Group: state : lga ..etc.)
 * filtered stateData is passed as data into the Table components.
 * NOTE --> A table object acts as a database that is used to dynamically generate the table's dynamic data depending on the filters.
 * 
*/

const date= new Date();
const month = (date.getMonth()+1) > 9? date.getMonth()+1: (`0${date.getMonth() + 1}`);
const day = date.getDate() > 9? date.getDate() : `0${date.getDate()}`;
export const today= `${date.getFullYear()}-${month}-${day}`;


//==> Table of all entries
const table = {
    /*all: {
        name: "all",
        tHeaders: [ 'Evaluator Code', 'Bank Name', 'Assessment Type', 'Branch Code', 'Score' , 'Actions'],
        tProperties: [ 'evaluator_code', 'bank_name', 'type', 'branch_id', 'score']
    },*/
    branch: {
        name: 'branch',
        tHeaders: [ 'Evaluator Code', 'Bank Name', 'Branch Code', 'Start Time', /*'Branch Address', 'Branch State', 'Branch Zone',*/ 'Entry Experience', 'Ambience', 'Service', 'ATM', 'Total Score', 'Assessment Type', 'Total Score (%)' , 'Submission Timestamp', 'Actions'],
        tProperties: [ 'evaluator_code', 'bank_name','branch_id', 'start_time', /*'branch_address', 'branch_state', 'branch_zone',*/ 'Entry', 'branchAmbience', 'services', 'atm', 'totalScore', 'assessment_type', 'totalScorePercentage', 'timestamp']
    },
    fle: {
        name: 'fle',
        tHeaders: [ 'Evaluator Code', 'Bank Name', 'Branch Code', 'Staff Role', 'First Name', 'Last Name', 'Gender',/* 'Validated Surname?', 'Branch Code', 'Branch Address', 'Branch State', 'Zone',*/ 'Appearance', 'Behaviour', 'Service', 'Product Knowledge', 'Total Score', 'Assessment Type', 'Total Score (%)', 'Submission Timestamp' , 'Actions'],
        tProperties: [ 'evaluator_code', 'bank_name', 'branch_id', 'employee_role', 'employee_first_name', 'employee_last_name',  'employee_gender', /*  'validated_surname', 'branch_id', 'branch_address', 'branch_state', 'zone',*/ 'appearance', 'behaviour', 'services', 'product_knownledge', 'totalScore', 'assessment_type', 'totalScorePercentage', 'timestamp']
    },
    security: {
        name: 'security',
        tHeaders: [ 'Evaluator Code', 'Bank Name', 'Branch Code', 'Staff Role', 'First Name', 'Last Name', 'Gender', /*'Branch Group', 'Surname', 'Firstname', 'Validated Surname?', 'Gender', 'Job Role', 'Branch Address', 'Branch State', 'Zone',*/ 'Welcome Greeting', 'Appearance', 'Behaviour', 'Total Score', 'Assessment Type', 'Total Score (%)', 'Submission Timestamp' , 'Actions'],
        tProperties: [ 'evaluator_code', 'bank_name', 'branch_id', 'employee_role', 'employee_first_name', 'employee_last_name',  'employee_gender', /*'branch_group', 'staff_id', 'surname', 'firstname',  'validated_surname',  'gender', 'job_role', 'branch_address', 'branch_state', 'zone',*/ 'welcome_greeting', 'appearance', 'behaviour', 'totalScore', 'assessment_type', 'totalScorePercentage', 'timestamp',]
    },
    intern: {
        name: 'intern',
        tHeaders: [ 'Evaluator Code', 'Bank Name', 'Branch Code', 'Role', 'First Name', 'Last Name', 'Gender', /* 'Validated Surname?', 'Gender', 'Branch Code', 'Branch Address', 'Branch State', 'Zone',*/ 'Appearance', 'Behaviour', 'Service', 'Product Knowledge', 'Communication', 'Total Score', 'Assessment Type', 'Total Score (%)', 'Submission Timestamp' , 'Actions'],
        tProperties: [ 'evaluator_code', 'bank_name', 'branch_id', 'employee_role', 'employee_first_name', 'employee_last_name',   'employee_gender',/* 'employee_first_name', 'employee_last_name', 'validated_surname',  'gender', 'branch_id', 'branch_address', 'branch_state', 'zone',*/ 'appearance', 'attitude_and_behaviour', 'customers_services', 'product_knownledge', 'communication', 'totalScore', 'assessment_type', 'totalScorePercentage', 'timestamp']
    },
    alterAssessment: {
        name: 'alter assessment',
        tHeaders: [ 'Answer ID', 'Evaluator Code', 'Assessed Branch', 'Question', 'Category', 'Edit Score' ],
        tProperties: [ "evaluator_answer_id", "evaluator_access_code", "campaign_assessment_branch_id", "question", "question_category", "score" ]
    }
}

const Reports = () => {
    const dispatch= useDispatch();
    const { entries, allReports } = useSelector(state => state.reports); // state Data
    const reports = useSelector(state => state.reports);
    const { dates, campaignID } = useSelector(state => state.dataSummary);
    const { campaignTypes, campaignBanks } = useSelector(state => state.corporate);
    const { token } = useSelector(state=> state.auth);
    //const evaluators = useSelector(state => state.evaluators);
    const branches = useSelector(state => state.branches);

    /** On Component First (Did) Mount */
    useEffect(()=>{
		dispatch(reportsThunk());
        setSelectdate(sessionStorage.getItem("selectDate") || previousDay);
    }, [])

    /* FEATURES */ 

    //=> REPORT TABLE AND ENTRIES SELECT

    const [currentTable, setCurrentTable] = useState(table.branch);
    const handleSetTable = (e) => {
        const currentDiv= document.getElementById(`${e.target.innerText.toLowerCase()}`);
        const siblings= [...currentDiv.parentElement.children];
        siblings.forEach(child => {child.style.transform = 'scale(0.9)'});

        currentDiv.style.transform= 'scale(1)';
        table[`${e.target.innerText.toLowerCase()}`]? setCurrentTable(table[`${e.target.innerText.toLowerCase()}`]) : setCurrentTable(table.branch);
    }

    

    //==> Date range of all entries
    const previousDay = dates? dates.find(date=> date !== today) : null;
    const [selectDate, setSelectdate] = useState(sessionStorage.getItem('selectDate')? sessionStorage.getItem('selectDate') : previousDay);
    useEffect(()=> {
        if(selectDate !== 'All'){
            dispatch(reportsThunk(selectDate))
        }
        else if(selectDate === 'All' && currentTable.name === "branch") {
            dispatch(allBranchThunk());
        }
        else if(selectDate === 'All' && currentTable.name === "fle") {
            dispatch(allFLEThunk());
        }
        else if(selectDate === 'All' && currentTable.name === "security") {
            dispatch(allSECThunk());
        }
        else if(selectDate === 'All' && currentTable.name === "intern") {
            dispatch(allInternThunk());
        }
    }, [campaignID, selectDate, currentTable.name]);
    const handleDateSelect = (e) => {
        sessionStorage.setItem('selectDate', e.target.value);
        setSelectdate(e.target.value);
    };

    //==> Summary Cards Features
    const [selectedBank, setSelectedBank] = useState(null);
    const handleSelectBank = (bank) =>{
        selectedBank !== bank? setSelectedBank(bank) : setSelectedBank(null);
    }

    //==> Table Entries: Data Flow
    const [currentEntries, setCurrentEntries] = useState(entries && selectDate !== 'All'? entries.overview : allReports && selectDate === 'All'? allReports[`${currentTable.name.toUpperCase()}`] : []);
    // State Data Filter - Parent Data Filter
    useEffect(() => {
        //Table Specific tableFilter: role for FLE with several roles
        currentTable.name !== 'fle'? setRoleTermFilter('') : setRoleTermFilter(roleTermFilter);
        if (entries && selectDate !== 'All' && currentTable.name === "all") {
            setCurrentEntries(entries.overview);
        }
        else if (entries && selectDate !== 'All' && currentTable.name === "branch") {
            setCurrentEntries(entries.branch);
        }
        else if (entries && selectDate !== 'All' && currentTable.name === "fle") {
            setCurrentEntries(entries.fle);
        }
        else if (entries && selectDate !== 'All' && currentTable.name === "security") {
            setCurrentEntries(entries.security);
        }
        else if (entries && selectDate !== 'All' && currentTable.name === "intern") {
            setCurrentEntries(entries.intern);
        }/*
        else if (allReports && selectDate === 'All' && currentTable.name === "overview") {
            return null;
        }*/
        else if (allReports && selectDate === 'All') {
            allReports.length && allReports[0].assessment_type.toLowerCase() !== currentTable.name? setCurrentEntries([]) : setCurrentEntries(allReports);
        }

    }, [currentTable, entries, allReports]);

    

    //==> Filters of table data
    const [searchTerm, setSearchTerm] = useState('');
    const [groupFilterTerm, setGroupFilterTerm] = useState('');
    const [roleTermFilter, setRoleTermFilter] = useState('');
    const [bankTermFilter, setBankTermFilter] = useState('');
    const [stateTermFilter, setStateTermFilter] = useState('');
    const [zoneTermFilter, setZoneTermFilter] = useState('');

    const [currentEntriesFiltered, setCurrentEntriesFiltered] = useState(currentEntries);
    //console.log({currentEntries});
    useEffect(() => {
        const ST = searchTerm.toLowerCase();
        const GT = groupFilterTerm.toLowerCase();
        const RT = roleTermFilter.toLowerCase();
        const BT = bankTermFilter.toLowerCase();
        //const PT = performanceFilterTerm.toLowerCase();

        const getBranchGroup = (branchCode) => {
            const branch= branches.find(branch=> branch.code === branchCode)
            return branch? `${branch.group}` : '';
        }

        if (BT.length && !RT.length && !ST.length && !GT.length ) {
            const filtered = currentEntries.filter(entry=> {
                return entry.bank_name.toLowerCase() === BT;
            })
            setCurrentEntriesFiltered(filtered);
        }
        else if (BT.length && RT.length && !ST.length && !GT.length ) {
            const filtered = currentEntries.filter(entry=> {
                return entry.bank_name.toLowerCase() === BT && entry.employee_role.toLowerCase() === RT;
            })
            setCurrentEntriesFiltered(filtered);
        }
        else if (BT.length && !RT.length && ST.length && !GT.length ) {
            const filtered = currentEntries.filter(entry=> {
                return entry.bank_name.toLowerCase() === BT && (entry.evaluator_code.toLowerCase().includes(ST) || entry.branch_id.toLowerCase().includes(ST));
            })
            setCurrentEntriesFiltered(filtered);
        }
        else if (BT.length && !RT.length && !ST.length && GT.length ) {
            const filtered = currentEntries.filter(entry=> {
                return entry.bank_name.toLowerCase() === BT && getBranchGroup(entry.branch_id).toLowerCase() === GT;
            })
            setCurrentEntriesFiltered(filtered);
        }
        else if (BT.length && RT.length && ST.length && !GT.length ) {
            const filtered = currentEntries.filter(entry=> {
                return entry.bank_name.toLowerCase() === BT && entry.employee_role.toLowerCase() === RT && (entry.evaluator_code.toLowerCase().includes(ST) || entry.branch_id.toLowerCase().includes(ST));
            })
            setCurrentEntriesFiltered(filtered);
        }
        else if (BT.length && RT.length && !ST.length && GT.length ) {
            const filtered = currentEntries.filter(entry=> {
                return entry.bank_name.toLowerCase() === BT && entry.employee_role.toLowerCase() === RT && getBranchGroup(entry.branch_id).toLowerCase() === GT;
            })
            setCurrentEntriesFiltered(filtered);
        }
        else if (BT.length && !RT.length && ST.length && GT.length ) {
            const filtered = currentEntries.filter(entry=> {
                return entry.bank_name.toLowerCase() === BT && (entry.evaluator_code.toLowerCase().includes(ST) || entry.branch_id.toLowerCase().includes(ST)) && getBranchGroup(entry.branch_id).toLowerCase() === GT;
            })
            setCurrentEntriesFiltered(filtered);
        }
        else if (BT.length && RT.length && ST.length && GT.length ) {
            const filtered = currentEntries.filter(entry=> {
                return entry.bank_name.toLowerCase() === BT && entry.employee_role.toLowerCase() === RT && (entry.evaluator_code.toLowerCase().includes(ST) || entry.branch_id.toLowerCase().includes(ST)) && getBranchGroup(entry.branch_id).toLowerCase() === GT;
            })
            setCurrentEntriesFiltered(filtered);
        }

        else if (!BT.length && RT.length && !ST.length && !GT.length) {
            const filtered = currentEntries.filter(entry => entry.employee_role.toLowerCase() === RT);
            setCurrentEntriesFiltered(filtered);
        }
        else if (!BT.length && RT.length && ST.length && !GT.length) {
            const filtered = currentEntries.filter(entry => entry.employee_role.toLowerCase() === RT && (entry.evaluator_code.toLowerCase().includes(ST) || entry.branch_id.toLowerCase().includes(ST)));
            setCurrentEntriesFiltered(filtered);
        }
        else if (!BT.length && RT.length && !ST.length && GT.length) {
            const filtered = currentEntries.filter(entry => {
                return entry.employee_role.toLowerCase() === RT && getBranchGroup(entry.branch_id).toLowerCase() === GT;
            })
            setCurrentEntriesFiltered(filtered);
        }
        else if (!BT.length && RT.length && ST.length && GT.length) {
            const filtered = currentEntries.filter(entry=> {
                return entry.employee_role.toLowerCase() === RT && (entry.evaluator_code.toLowerCase().includes(ST) || entry.branch_id.toLowerCase().includes(ST)) && getBranchGroup(entry.branch_id).toLowerCase() === GT;
            })
            setCurrentEntriesFiltered(filtered);
        }
        else if(!BT.length && !RT.length && ST.length && !GT.length ) { 
            const filtered = currentEntries.filter(entry => {
                return entry.evaluator_code.toLowerCase().includes(ST) || entry.branch_id.toLowerCase().includes(ST)
            })
            setCurrentEntriesFiltered(filtered);
        }
        else if(!BT.length && !RT.length && ST.length && GT.length) { 
            const filtered = currentEntries.filter(entry => {
                //console.log(getBranchGroup(entry.branch_id))
                return (entry.evaluator_code.toLowerCase().includes(ST) || entry.branch_id.toLowerCase().includes(ST)) && 
                (getBranchGroup(entry.branch_id).toLowerCase() === GT)
            })
            setCurrentEntriesFiltered(filtered);
        }
        else if(!BT.length && !RT.length && !ST.length && GT.length) { 
            const filtered = currentEntries.filter(entry => {
                return getBranchGroup(entry.branch_id).toLowerCase() === GT;
            })
            setCurrentEntriesFiltered(filtered);
        }
        else { setCurrentEntriesFiltered(currentEntries) }


    }, [entries, allReports, currentEntries, searchTerm, groupFilterTerm, roleTermFilter, bankTermFilter/*, performanceFilterTerm*/]);


/**ACTIONS
 * This shows actions and their side effects
 * Edit, Delete, Alter [Score]
 */
    // EDIT REPORT
    const [editFormDisplay, setEditFormDisplay] = useState("hidden");
    const [editReportObj, setEditReportObj] = useState({});
    const handleClickEdit = (report) => {
        //console.log({report})
        setEditFormDisplay("")
        const { id: evaluator_assessment_id, employee_first_name = '', employee_last_name = '', employee_role = '', branch_id : branch_code='', evaluator_code: evaluator_access_code='' } = report;
        setEditReportObj({ employee_first_name, employee_last_name, employee_role, evaluator_assessment_id, branch_code, evaluator_access_code });
    }
    const handleEditFormChange= (e) => {
        setEditReportObj(prev=>{
            //console.log({ prev })
            return {...prev, [e.target.name]: e.target.value}
        })
    }
    const handleSubmitEdit= (e) =>{
        e.preventDefault();
        //console.log({editReportObj})
        dispatch(editEntryThunk(editReportObj)).then(response=> {
            if(response.payload.success){ setEditFormDisplay("hidden"); dispatch(reportsThunk(selectDate));}
            else { toast.error('Unsuccessful, please try again')}
        })
    }

    // DELETE REPORT
    const [deleteReportID, setDeleteReportID] = useState('');
    const [deleteReportObj, setDeleteReportObj] = useState({});
    const [confirmDeleteFormDisplay, setConfirmDeleteFormDisplay] = useState('hidden');
    const handleClickDelete = (report) => {
        //console.log({ reportToDelete: report })
        setConfirmDeleteFormDisplay("")
        setDeleteReportObj(report);
        setDeleteReportID(`${report.id}`);
    }
    const handleConfirmDelete= (e) =>{
        e.preventDefault();
        //console.log({deleteReportID})
        dispatch(deleteEntryThunk(deleteReportID)).then(response=> {
            if(response.payload.success){ 
                setConfirmDeleteFormDisplay("hidden");
                // Close table actions drop down
                document.getElementById("closeActionDD").click()
                if(selectDate != 'All'){
                    dispatch(reportsThunk(selectDate));
                }
                else if(selectDate === 'All' && currentTable.name === "branch") {
                    dispatch(allBranchThunk());
                }
                else if(selectDate === 'All' && currentTable.name === "fle") {
                    dispatch(allFLEThunk());
                }
                else if(selectDate === 'All' && currentTable.name === "security") {
                    dispatch(allSECThunk());
                }
                else if(selectDate === 'All' && currentTable.name === "intern") {
                    dispatch(allInternThunk());
                }
            }
            else { return; }
        })
    }

    // ALTER REPORT
    const [alterTableHidden, setAlterTableHidden] = useState(true);
    const [alterAssessments, setAlterAssessments] = useState([]);
    const handleClickAlter = (report) => {
        setAlterTableHidden(false);
        getAlterEntry(report.id, token)
        .then(response=> {
            //console.log({ response })
            setAlterAssessments(response);
        })
    };
    const handleSubmitAlter = (formDataArray) => {
        dispatch(postAlterEntryThunk(formDataArray))
        .then(({meta})=> {
            if(meta.requestStatus == 'fulfilled'){
                setAlterTableHidden(true);
            }
        })
    };


    let currentDateBankSummary = {};

    const summaryCardObj = campaignBanks && campaignTypes? campaignBanks.map(bank=> {
        const types = campaignTypes.map(type=> {
            return {
                [type.type.toLowerCase()]: entries && entries[type.type.toLowerCase()].filter(entry=> entry.bank_name.toLowerCase() == bank.bank_name.toLowerCase()).length,
            }
        })
        const typeFormat = {};
        types.forEach(type=> {
            return Object.assign(typeFormat, type)
        })


        const evaluators = entries && campaignTypes.map(type=> [...new Set(entries[type.type.toLowerCase()].filter(entry=> entry.bank_name.toLowerCase() == bank.bank_name.toLowerCase()).map(entry=> entry.evaluator_code))].length).reduce((a,b)=> a+b);
        

        return {
            [bank.bank_name]: {
                evaluators,
                ...typeFormat,
            }
        }
    }) : [];

    summaryCardObj.forEach(bank=> Object.assign(currentDateBankSummary, bank))

    console.log({currentDateBankSummary})
    
    


    return (
    <>
        <div className={`flex flex-col items-center p-2 bg-slate-100 ${alterTableHidden? "": "hidden"}`}>
            <div className="flex w-full my-2 justify-start items-center">
                <h1 className="font-bold w-max mx-3 text-xl text-slate-400">Manage Reports - </h1>
                <p className="font-medium text-slate-500">
                    Here you can <span className="text-blue-400"> Edit, Delete or Alter Evaluator Entries </span>
                </p>
            </div>
            
            <section className="w-full p-5 m-4 bg-white border border-slate-200 shadow-md rounded ">
                <div className="flex justify-center items-center w-full">
                    <h1 className="font-bold text-lg text-dayoDarkGray w-1/4 mr-12">Evaluators Entries: <span className="font-bold text-lg text-dayoAshBlue w-1/4 mr-12">{selectDate}</span> </h1>
                    <div className="flex items-center justify-start min-w-max w-3/4">
                        <div 
                            onClick={ (e)=> { sessionStorage.setItem('selectDate', previousDay); if(dates) setSelectdate(previousDay) } } 
                            className={`m-1 w-1/6 min-w-max rounded cursor-pointer ${selectDate === previousDay? 'bg-sideBarBlue text-slate-100 font-bold px-3 py-1' : 'p-3 bg-slate-100 text-slate-500 hover:text-sideBarBlue'} ${!date? 'cursor-not-allowed' : ''} text-center`}
                        >
                            <p>Previous</p>
                            { selectDate === previousDay?  <small className="text-xs font-normal">{previousDay}</small> : null }
                        </div>
                        <div 
                            onClick={ (e)=> { setSelectdate('All') }}  
                            className={`m-1 w-2/6 min-w-max rounded  active:bg-sideBarBlue cursor-pointer ${selectDate === 'All'? 'bg-sideBarBlue text-slate-100 font-bold px-3 py-1' : 'p-3 bg-slate-100 text-slate-500 hover:text-sideBarBlue'} text-center`}
                        >
                            <p>All</p>
                            { selectDate === 'All'?  <small className="text-xs font-normal">{ ` ( ${dates? dates[dates.length-1] : 'start'} to ${dates? dates[0] : 'today'} )`}</small> : null }
                        
                        </div>
                        <div 
                            onClick={ (e)=> { sessionStorage.setItem('selectDate', today); setSelectdate(today) }} 
                            className={`m-1 w-1/6 min-w-max rounded active:bg-sideBarBlue cursor-pointer ${selectDate === today? 'bg-sideBarBlue text-slate-100 font-bold px-3 py-1' : 'p-3 bg-slate-100 text-slate-500 hover:text-sideBarBlue'} text-center`}
                        >
                            <p>Today</p>
                            { selectDate === today?  <small className="text-xs font-normal">{today.split("-").reverse().join("-")}</small> : null }
                        </div>
                        <select onChange={handleDateSelect} defaultValue={selectDate == 'All'? selectDate : previousDay} className="font-medium text-center text-blue-500 py-3 mx-3 text-sm focus:outline-none rounded border border-blue-200 hover:border-slate-100 hover:shadow-md focus:border focus:border-slate-100 focus:shadow-md">
                            <option value={''} disabled> Dates </option>
                            {
                                dates? dates.map(date => {
                                    return <option key={date} value={date}>{date}</option>
                                }) : null
                            }
                        </select>
                    </div>

                </div>

                {
                    reports.totalEvaluators? (
                        <>                        

{/**Bank specific summary cards: select bank to show summary cards */}
                        <div className='flex w-full'>
                            <div onClick={(e)=> { handleSelectBank('Campaign') }}  className={`${selectedBank === 'Campaign'? 'bg-blue-500 text-white' : 'bg-inherit text-blue-600'} cursor-pointer flex flex-col rounded border shadow-md h-max min-w-max w-1/4 p-1 m-2 items-center justify-center text-center space-x-between`}>
                                <div className="flex items-center justify-between w-full">
                                    <div className="justify-self-start w-3/4 text-center">
                                        <h1 className={`text-md font-bold`}>Overview</h1>
                                        <p>{ reports.staffAssessed.branchCount + reports.staffAssessed.fleCount + reports.staffAssessed.securityCount  }</p>
                                    </div>
                                    <div className="h-full py-3 w-1/4 shadow flex justify-center item-center">
                                    {
                                        selectedBank === 'Campaign'? <KeyboardArrowDownRounded /> : <KeyboardArrowRightRounded />
                                            
                                    }
                                    </div>
                                </div> 
                            </div>

                            <div onClick={(e)=> { handleSelectBank('Access') }}  className={`${selectedBank === 'Access'? 'bg-blue-500 text-white' : 'bg-inherit text-slate-600'} cursor-pointer flex flex-col rounded border shadow-md h-max min-w-max w-1/4 p-1 m-2 items-center justify-center text-center space-x-between`}>
                                <div className="flex items-center justify-between w-full">
                                    <div className="justify-self-start w-3/4 text-center">
                                        <h1 className={`text-md font-bold`}>Access</h1>
                                        <p>{reports.bankAssessed.access? reports.bankAssessed.access : 0}</p>
                                    </div>
                                    
                                    <div className="h-full py-3 w-1/4 flex justify-center shadow item-center">
                                    {
                                        selectedBank === 'Access'? <KeyboardArrowDownRounded /> : <KeyboardArrowRightRounded />
                                            
                                    }
                                    </div>
                                </div>
                            </div>

                            <div onClick={(e)=> { handleSelectBank('GTB') }}  className={`${selectedBank === 'GTB'? 'bg-blue-500 text-white' : 'bg-inherit text-slate-600'} cursor-pointer flex flex-col rounded border shadow-md h-max min-w-max w-1/4 p-1 m-2 items-center justify-center text-center space-x-between`}>
                                <div className="flex items-center justify-between w-full">
                                    <div className="justify-self-start w-3/4 text-center">
                                        <h1 className={`text-md font-bold`}>GTB</h1>
                                        <p>{reports.bankAssessed.gtb? reports.bankAssessed.gtb : 0}</p>
                                    </div>
                                    <div className="h-full py-3 w-1/4 flex justify-center shadow item-center">
                                    {
                                        selectedBank === 'GTB'? <KeyboardArrowDownRounded /> : <KeyboardArrowRightRounded />
                                            
                                    }
                                    </div>
                                </div>
                            </div>

                            <div onClick={(e)=> { handleSelectBank('Stanbic') }}  className={`${selectedBank === 'Stanbic'? 'bg-blue-500 text-white' : 'bg-inherit text-slate-600'} cursor-pointer flex flex-col rounded border shadow-md h-max min-w-max w-1/4 p-1 m-2 items-center justify-center text-center space-x-between`}>
                                <div className="flex items-center justify-between w-full">
                                    <div className="justify-self-start w-3/4 text-center">
                                        <h1 className={`text-md font-bold`}>Stanbic</h1>
                                        <p>{reports.bankAssessed.stanbic? reports.bankAssessed.stanbic : 0}</p>
                                    </div>
                                    <div className="h-full py-3 w-1/4 flex justify-center shadow item-center">
                                    {
                                        selectedBank === 'Stanbic'? <KeyboardArrowDownRounded /> : <KeyboardArrowRightRounded />
                                            
                                    }
                                    </div>
                                </div>                                
                            </div>

                            <div onClick={(e)=> { handleSelectBank('Zenith') }}  className={`${selectedBank === 'Zenith'? 'bg-blue-500 text-white' : 'bg-inherit text-slate-600'} cursor-pointer flex flex-col rounded border shadow-md h-max min-w-max w-1/4 p-1 m-2 items-center justify-center text-center space-x-between`}>
                                <div className="flex items-center justify-between w-full">
                                    <div className="justify-self-start w-3/4 text-center">
                                        <h1 className={`text-md font-bold`}>Zenith</h1>
                                        <p>{reports.bankAssessed.zenith? reports.bankAssessed.zenith : 0}</p>
                                    </div>
                                    <div className="h-full py-3 w-1/4 flex justify-center shadow item-center">
                                    {
                                        selectedBank === 'Zenith'? <KeyboardArrowDownRounded /> : <KeyboardArrowRightRounded />
                                            
                                    }
                                    </div>
                                </div>                                
                            </div>
                        </div>
                        {
                            selectedBank === 'Campaign' ? (
                                <div className="flex border shadow border-slate-100">
                                    <div className="flex flex-col min-h-max min-w-max w-1/4 p-2 m-1 items-center justify-center border border-slate-200 rounded shadow">
                                        <h1 className="text-md font-bold my-1 text-blue-600">Summary</h1>
                                        <div className="flex items-center justify-evenly">
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>Evaluators</h1>
                                                <h1>{reports.totalEvaluators? reports.totalEvaluators : 0}</h1>
                                            </div>
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>Zones</h1>
                                                <h1>{reports.regionCover.zone? reports.regionCover.zone : 0}</h1>
                                            </div>
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>States</h1>
                                                <h1>{reports.regionCover.state? reports.regionCover.state : 0}</h1>
                                            </div>
                                        </div>    
                                    </div>

                                    <div className="flex flex-col min-h-max min-w-max w-1/4 p-2 m-1 items-center justify-center border border-slate-200 rounded shadow">
                                        <h1 className="text-md font-bold my-1 text-blue-600">Assessment</h1>
                                        <div className="flex items-center justify-evenly">
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>Branch</h1>
                                                <h1>{reports.staffAssessed.branchCount? reports.staffAssessed.branchCount : 0}</h1>
                                            </div>
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>FLE</h1>
                                                <h1>{reports.staffAssessed.fleCount? reports.staffAssessed.fleCount : 0}</h1>
                                            </div>
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>Security</h1>
                                                <h1>{reports.staffAssessed.securityCount? reports.staffAssessed.securityCount : 0}</h1>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div className="flex flex-col min-h-max min-w-max w-1/4 p-2 m-1 items-center justify-center border border-slate-200 rounded shadow">
                                        <h1 className="text-md font-bold my-1 text-blue-600">Banks Assessed</h1>
                                        <div className="flex items-center justify-evenly">
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>GTB</h1>
                                                <h1>{reports.bankAssessed.gtb? reports.bankAssessed.gtb : 0}</h1>
                                            </div>
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>Stanbic</h1>
                                                <h1>{reports.bankAssessed.stabic? reports.bankAssessed.stabic : 0}</h1>
                                            </div>
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>Zenith</h1>
                                                <h1>{reports.bankAssessed.zenith? reports.bankAssessed.zenith : 0}</h1>
                                            </div>
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>Access</h1>
                                                <h1>{reports.bankAssessed.access? reports.bankAssessed.access : 0}</h1>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div className="flex flex-col min-h-max min-w-max w-1/4 p-2 m-1 items-center justify-center border border-slate-200 rounded shadow">
                                        <h1 className="text-md font-bold my-1 text-blue-600">Group Assessed</h1>
                                        <div className="flex items-center justify-evenly">
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>LPA</h1>
                                                <h1>{reports.group.groupOne? reports.group.groupOne : 0}</h1>
                                            </div>
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>Group 2</h1>
                                                <h1>{reports.group.groupTwo? reports.group.groupTwo : 0}</h1>
                                            </div>
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>Group 3</h1>
                                                <h1>{reports.group.groupThree? reports.group.groupThree : 0}</h1>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            ) : null
                        }

                        {
                            selectedBank !== 'Campaign' && selectedBank ? (
                                <div className={`flex border shadow border-slate-100`}>
                                    <div className="flex flex-col h-max min-w-max w-full py-2 m-1 items-center justify-center border border-slate-200 rounded shadow">
                                        <h1 className="text-md font-bold my-1 text-blue-600">{selectedBank}</h1>
                                        <div className="flex flex-wrap">
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>Evaluators</h1>
                                                <h1>{currentDateBankSummary[selectedBank.toUpperCase()]? currentDateBankSummary[selectedBank.toUpperCase()].evaluators : 0}</h1>
                                            </div>
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>Branch</h1>
                                                <h1>{currentDateBankSummary[selectedBank.toUpperCase()]? currentDateBankSummary[selectedBank.toUpperCase()].branch : 0}</h1>
                                            </div>
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>FLE</h1>
                                                <h1>{currentDateBankSummary[selectedBank.toUpperCase()]? currentDateBankSummary[selectedBank.toUpperCase()].fle : 0}</h1>
                                            </div>
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>Security</h1>
                                                <h1>{currentDateBankSummary[selectedBank.toUpperCase()]? currentDateBankSummary[selectedBank.toUpperCase()].security : 0}</h1>
                                            </div>
                                            {/*<div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>Zones</h1>
                                                <h1>{reports.regionCover.zone? reports.regionCover.zone : 0}</h1>
                                            </div>
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>States</h1>
                                                <h1>{reports.regionCover.state? reports.regionCover.state : 0}</h1>
                                            </div>*/}
                                        </div>    
                                    </div>

                                    {/*<div className="flex flex-col h-max min-w-max w-1/3  py-2 m-1 items-center justify-center border border-slate-200 rounded shadow">
                                        <h1 className="text-md font-bold my-1 text-blue-600">Assessment</h1>
                                        <div className="flex flex-wrap">
                                            
                                        </div>
                                        
                                    </div>

                                    <div className="flex flex-col h-min min-w-max w-1/3 py-2 m-1 items-center justify-center border border-slate-200 rounded shadow">
                                        <h1 className="text-md font-bold my-1 text-blue-600">Group Assessed</h1>
                                        <div className="flex flex-wrap">
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>LPA</h1>
                                                <h1>{reports.group.groupOne? reports.group.groupOne : 0}</h1>
                                            </div>
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>Group 2</h1>
                                                <h1>{reports.group.groupTwo? reports.group.groupTwo : 0}</h1>
                                            </div>
                                            <div className="flex flex-col text-slate-700 w-max px-1 mx-2 items-center justify-center space-x-between">
                                                <h1>Group 3</h1>
                                                <h1>{reports.group.groupThree? reports.group.groupThree : 0}</h1>
                                            </div>
                                        </div>
                                        
                                    </div>*/}
                                </div>
                            ) : null
                        }

                        
                        
                        </>
                    )
                    :
                    <SkeletonLoad />
                }
            </section>

{/*TABLE OF REPORTS */}
            <section className="w-full p-5 m-4 bg-white border border-slate-200 shadow-md rounded ">
    {/*Filters */}
                <div className="w-full m-1">
                    <input type='text' onChange={(e) => { setSearchTerm(e.target.value) }} value={ searchTerm } className="w-1/3 m-3 px-3 py-2 rounded-full border border-slate-300 focus:outline-blue-400" placeholder="Search Evaluator Code or Branch Code" />
                    <select onChange={(e)=> { setBankTermFilter(e.target.value) }} defaultValue={""} className="w-1/6 m-3 px-3 py-2 rounded border border-slate-300 focus:outline-blue-400 ">
                        <option value="">All Banks</option>
                        <option value='access'>Access</option>
                        <option value='gtb'>GTB</option>
                        <option value='stanbic'>Stanbic</option>
                        <option value='zenith'>Zenith</option>
                    </select>
                    <select onChange={(e) => { setGroupFilterTerm(e.target.value) }} defaultValue={""} className="w-1/6 m-3 px-3 py-2 rounded border border-slate-300 focus:outline-blue-400 ">
                        <option value="">All Groups</option>
                        <option value="1">LPA</option>
                        <option value="2">Group 2</option>
                        <option value="3">Group 3</option>
                    </select>
                    {
                        currentTable.name === 'fle'? (
                                <select onChange={(e)=>{ setRoleTermFilter(e.target.value) }} defaultValue={""} className="w-1/6 m-3 px-3 py-2 rounded border border-slate-300 focus:outline-blue-400 ">
                                    <option value="">All Roles</option>
                                    <option value="teller">Teller</option>
                                    <option value="customer care operative">Customer Care</option>
                                    <option value="other">Other</option>
                                </select>
                            ) : null
                    }
                    
                    {/*<select onChange={handleFilterPerformance} className="w-1/6 m-3 px-3 py-2 rounded border border-slate-300 focus:outline-blue-400 ">
                        <option value="DEFAULT" disabled>Filter Score</option>
                        <option value="59">less than 60%</option>
                        <option value="60">60% and more</option>
                    </select>*/}
                </div>
    {/*Types buckets and Table */}
                <div>
                    <div className="flex mb-0 justify-between items-center h-max w-full">
                        {
                            <div className="flex mb-0 justify-start items-center h-max w-full">
                            {
                                campaignTypes && campaignTypes.map((type, index) => (
                                    <div key={index} 
                                        id={type.type.toLowerCase()} 
                                        onClick={handleSetTable} 
                                        className={`${currentTable.name == type.type.toLowerCase()? 'bg-dayoAshBlue' : 'bg-slate-300'} w-1/2 text-center mx-1 p-2 h-16 md:w-1/4 lg:w-1/6 text-white rounded-t-xl`}
                                    >
                                        <h2 className=" mt-2">{type.type.toUpperCase()}</h2>
                                        <p className={`${currentTable.name === type.type.toLowerCase()? '' : 'hidden' } font-bold text-center`}>{selectDate !== 'All'? currentEntriesFiltered.length : selectDate === 'All' && allReports && allReports.length && allReports[0].assessment_type.toLowerCase() === currentTable.name? currentEntriesFiltered.length : '...'}</p>
                                    </div>
                                ))
                            }
                            </div>
                        }
                        
                        
                        <div className=" flex items-center justify-around rounded min-w-max h-12 pl-2 pr-0 py-0 bg-dayoAshBlue hover:bg-blue-600 text-white"><CloudDownloadRounded /> <ReactHtmlTableToExcel className=' mx-2 h-full bg-inherit' table={"currentReportEntries"} buttonText={`Download Current Table`} filename={`Evaluators Entries (${selectDate})`} sheet="entries_xls" /> </div>
                    </div>
                    <PaginatedTable data={currentEntriesFiltered} table={currentTable} pageLimit={10} dataLimit={10} id={'headerOfReport'} isSort={false} isAction={true} actionElements={[{text: "Edit", onClick: handleClickEdit, class: 'bg-dayoAshBlue w-full rounded px-3 py-1 my-1 text-white hover:bg-sideBarBlue'} , {text: "Upgrade", onClick: handleClickAlter, class: 'bg-dayoOrange w-full active:bg-orange-600 rounded mx-1 my-1 px-3 py-1 text-slate-100 underlined active:text-white hover:text-white hover:bg-orange-700' }, {text: "Delete", onClick: handleClickDelete, class: 'bg-dayoRed w-full active:bg-red-400 rounded mx-1 my-1 px-3 py-1 text-red-700 underlined active:text-white hover:text-white hover:bg-red-700' }]} />
                    <Table data={currentEntriesFiltered} table={currentTable} hidden={true} id={'currentReportEntries'} />
                </div>
            </section>

            <section className={`flex justify-center text-white items-center w-4/5 h-full bg-transparent fixed ${editFormDisplay}`}
                style={{backgroundColor: 'rgba(25, 25, 25, 0.2'}}
            >                
                <form onChange={handleEditFormChange} onSubmit={handleSubmitEdit} className={`flex flex-col w-1/2 h-max min-h-max lg:w-1/3 rounded-lg bg-slate-100 border border-slate-200 shadow-md`}>
                    <div className='flex rounded-t-lg w-full px-2 justify-between items-center bg-sideBarBlue'>
                        <h1>Edit Report</h1>
                        <button onClick={(e)=>{e.preventDefault(); setEditFormDisplay("hidden")}} className="bg-white text-red-600 font-bold px-2 py-0 text-center rounded-full my-4 hover:bg-red-500 hover:text-white">x</button>
                    </div>

                    <div className="w-full h-max px-2">
                        <label className='flex flex-col font-bold text-blue-900 font-md'>
                            Employee First Name
                            <input type="text" className='w-full rounded border p-2 my-2 border-slate-300 ' required name="employee_first_name" value={editReportObj.employee_first_name} />
                        </label>
                        <label className='flex flex-col font-bold text-blue-900 font-md'>
                            Employee Last Name
                            <input type="text" className='w-full rounded border p-2 my-2 border-slate-300 ' required name="employee_last_name" value={editReportObj.employee_last_name} />
                        </label>
                        <label className='flex flex-col font-bold text-blue-900 font-md'>
                            Employee Role
                            <input type="text" className='w-full rounded border p-2 my-2 border-slate-300 ' required name="employee_role" value={editReportObj.employee_role} />
                        </label>
                        <label className='flex flex-col font-bold text-blue-900 font-md'>
                            Evaluator Code
                            <input type="text" className='w-full rounded border p-2 my-2 border-slate-300 ' required name="evaluator_access_code" value={editReportObj.evaluator_access_code} />
                        </label>
                        <label className='flex flex-col font-bold text-blue-900 font-md'>
                            Branch Code
                            <input type="text" className='w-full rounded border p-2 my-2 border-slate-300 ' required name="branch_code" value={editReportObj.branch_code} />
                        </label>
                    </div>
                    <input type="submit" className="w-full p-3 my-2 bg-dayoAshBlue hover:bg-sideBarBlue font-bold rounded" value='Save Report' />
                                        
                </form>
            </section>

            <section className={`flex justify-center text-white items-center w-4/5 h-full bg-transparent fixed ${confirmDeleteFormDisplay}`}
                style={{backgroundColor: 'rgba(25, 25, 25, 0.2'}}
            >                
                <form onSubmit={handleConfirmDelete} className={`flex flex-col w-1/2 h-1/3 lg:w-1/3 rounded-lg bg-slate-100 shadow-md`}>
                    <div className='flex w-full rounded-t-lg px-2 justify-between items-center bg-sideBarBlue'>
                        <h1>Confirm Delete Report: {deleteReportObj.branch_id? deleteReportObj.branch_id.toUpperCase() : ''} </h1>
                        <button onClick={(e)=>{e.preventDefault(); setConfirmDeleteFormDisplay("hidden")}} className="bg-blue-400 text-slate-100 font-bold px-2 py-0 text-center rounded-full my-4 hover:bg-red-500 hover:text-white">x</button>
                    </div>
                    <div className="w-full h-full px-2">
                        <h1 className="flex items-center justify-center text-slate-600 font-md h-1/2">Are you sure you want to delete this report?</h1>
                        <div className="flex justify-between">
                            <input type="submit" className="flex items-center justify-center w-1/2 p-3 my-2 bg-red-600 hover:bg-red-500 font-bold rounded" value='Delete Report' />
                            <button onClick={(e)=>{e.preventDefault(); setConfirmDeleteFormDisplay("hidden")}} className="flex items-center justify-center w-1/2 p-3 my-2 mx-1 bg-dayoAshBlue hover:bg-sideBarBlue font-bold rounded" >Cancel</button>
                        </div>
                        
                    </div>
                                         
                </form>
            </section>

    {/*Form Table */}
        </div>
        <FormTable table={table.alterAssessment} data={alterAssessments? alterAssessments : []} editableProperties={["score"]} constraintKeyInData={"max_score"} onSubmitForm={handleSubmitAlter} onCancel={(e)=> { e.preventDefault(); setAlterTableHidden(true); }} hidden={alterTableHidden} />
    </>

    )
};

export default Reports;