//import { PersonRounded } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signOutThunk } from '../../features/authentication/authSlice';
import Logo from '../../utilities/assets/logoOrange.svg';
import { PersonRounded } from '@material-ui/icons';

const AdminHeader = () => {
	const dispatch= useDispatch();	
	const firstname = sessionStorage.firstname;

	const handleLogout= (e) =>{
		dispatch(signOutThunk());
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('user');
		sessionStorage.removeItem('evaluator_token');
	}

	return (
		<div className="w-full h-14 bg-slate-50 sticky top-0 z-40 shadow-md">
			<div className="h-full px-5 py-0 flex items-center justify-between">
				<div className="flex items-center h-full w-1/2">
					{/*<p className='text-slate-500'>Connectifie-MS</p>*/}
					<img src={Logo} alt="Logo" className="w-1/2 md:w-1/3 lg:w-1/6 lg:h-1/2 h-2/3" />
				</div>
				<div className="flex items-center">
					<div className="flex mr-4">
						<p><span className='text-accessOrange mr-4'><PersonRounded /></span>Welcome<span className='text-blue-500 hover:text-blue-600 '>{firstname? `, ${firstname}` : ''}</span></p>
					</div>
					<button onClick={handleLogout} className="bg-orange-600 hover:bg-red-600 px-2 py-1 rounded text-white capitalize text-sm">
						log out
					</button>
				</div>
			</div>
		</div>
	);
};

export default AdminHeader;
