import { CloudDownloadRounded, FlagRounded, KeyboardReturnRounded } from "@material-ui/icons"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import ReactHtmlTableToExcel from "react-html-table-to-excel"
import { useDispatch, useSelector } from "react-redux"
import PaginatedTable from "../../components/tables/PaginatedTable"
import Table from "../../components/tables/Table"
import { today } from "../report/Report"
import { flaggedThunk, bulkDeleteFlagThunk, bulkApproveFlagThunk, allCampaignFlaggedThunk, allFlaggedHistoryThunk, allFeedbackThunk} from "./flaggedIssuesSlice";


const table = {
    flaggedEntries: {
        name: 'flagged Entries',
        tHeaders: [ 'Evaluator Code', 'Branch', 'Business Reason', 'Assessment Type', 'Date Created', ],
        tProperties: [ 'evaluator_access_code', 'campaign_assessment_branch_id', 'reason', 'assessment_type', 'date_created']
    },
    flaggedEntriesHistory: {
        name: 'flagged History',
        tHeaders: [ 'Admin', 'Action', 'Business Reason', 'Assessment Type'],
        tProperties: [ 'user', 'Action', 'flag_reason', 'assessment_type_id' ]
    },
    flaggedBranches: {
        name: 'flagged Branches',
        tHeaders: [ 'Branch Code', 'Business Reason', 'Evaluator Code'],
        tProperties: [ 'branch_code', 'reason', 'evaluator_code']
    },
    evaluatorFeedbacks: {
        name: 'Evaluator feedbacks',
        tHeaders: [ 'Evaluator Code', 'Branch Code', 'Feedback (Complaint)', 'Campaign', 'Timestamp'],
        tProperties: [ 'feedback_evaluator_id', 'feedback_branch_id', 'feedback', 'campaign_name', 'created_at' ]
    },
}




const FlaggedIssues = () => {
    const { branches, entries, campaignFlaggedBranches, campaignFlaggedEntries, flaggedHistory, allFeedbacks } = useSelector(state => state.flagged);
    const { dates } = useSelector(state => state.dataSummary);
    const dispatch = useDispatch();

    useEffect(()=> {
		dispatch(allCampaignFlaggedThunk());
        dispatch(allFlaggedHistoryThunk());
        dispatch(allFeedbackThunk());
    },[])

    const [ selectDate, setSelectdate ] = useState('All');
    useEffect(() => {
        if (selectDate !== 'All' && selectDate !== 'History') dispatch(flaggedThunk(selectDate));
        setSelectedEntries([]);
    }, [dispatch, selectDate]);

    const handleDateSelect = (e) => {
        setSelectdate(e.target.value);
    };

    const [checkedData, setCheckedData] = useState([]);
    useEffect(()=>{
        if (selectDate === 'All') {
            const checkableData = campaignFlaggedEntries ? campaignFlaggedEntries.map(item => {
                return {
                    ...item,
                    checked: false
                }
            }) : [];
            setCheckedData(checkableData);
        }
        else if (selectDate === 'History') {
            const checkableData = flaggedHistory ? flaggedHistory.map(item => {
                return {
                    ...item,
                    checked: false
                }
            }) : [];
            setCheckedData(checkableData);
        }
        else {
            const checkableData = entries ? entries.map(item => {
                return {
                    ...item,
                    checked: false
                }
            }) : [];
            setCheckedData(checkableData);
        }
        
    }, [campaignFlaggedEntries, entries, flaggedHistory, selectDate]);

    
    //console.log(checkedData);


    // State and function to check and uncheck checkboxes for bulk actions
    const [ selectedEntries, setSelectedEntries ] = useState([]);
    const onClickSelect = (record, id) => {
        //console.log({ record });
        if(record.flag_id){
            filteredData.find(item => item.flag_id === record.flag_id).checked = !record.checked;
        }
        if (selectedEntries.includes(record)) {
            setSelectedEntries(selectedEntries.filter(entry => entry !== record));
        } else {
            setSelectedEntries([ ...selectedEntries, record ]);
        }
    }

    const onClickDeselectAll = () => {
        setSelectedEntries([]);
        filteredData.forEach(item => item.checked = false);
    }

    const onClickSelectAll = () => {
        setSelectedEntries(filteredData);
        filteredData.forEach(item => item.checked = true);
    }

    // Bulk Actions
    const [confirmFormDisplay, setConfirmFormDisplay] = useState('hidden');
    const [buttonAction, setButtonAction] = useState('Return');


    const onClickBulkDeleteFlagged= (e) => {
        e.preventDefault();
        if (selectedEntries.length) {
            setConfirmFormDisplay('');
            setButtonAction('Return'); 
        }else {
            toast.remove();
            toast.error('No flagged entries selected');
        }
    }

    const onConfirmBulkDeleteFlagged= (e) => {
        e.preventDefault();
        selectedEntries.length? dispatch(bulkDeleteFlagThunk(selectedEntries))
        .then(response=> {
            if (response){
                dispatch(flaggedThunk(selectDate));
                setSelectedEntries([]);
                setConfirmFormDisplay('hidden');
            }
        })
        : toast.error('No flagged entries selected');
    }

    const onClickBulkApproveFlagged= (e) => {
        e.preventDefault();
        if (selectedEntries.length) {
            setConfirmFormDisplay('');
            setButtonAction('Approve'); 
        }else {
            toast.remove();
            toast.error('No flagged entries selected');
        }
    }

    const onConfirmBulkApproveFlagged= (e) => {
        e.preventDefault();
        selectedEntries.length? dispatch(bulkApproveFlagThunk(selectedEntries))
        .then(({meta})=> {
            if (meta.requestStatus == 'fulfilled'){
                dispatch(flaggedThunk(selectDate));
                setSelectedEntries([]);
                setConfirmFormDisplay('hidden');
            }
        }) 
        : toast.error('No entries selected');
    }


    // Filtered Data and Filter Functions
    const [ filteredData, setFilteredData ] = useState([]);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ typeFilterTerm,  setTypeFilterTerm ] = useState('');
    useEffect(()=>{
        const TFT = typeFilterTerm.toLowerCase();
        const ST = searchTerm.toLowerCase();
        if (typeFilterTerm.length && !searchTerm.length) {
            const filtered = checkedData.filter(record=> {
                return record.assessment_type.toLowerCase() === TFT;
            });
            setFilteredData(filtered);
        }
        else if (searchTerm.length && !typeFilterTerm.length) {
            const filtered = checkedData.filter(record=> {
                return record.reason.toLowerCase().includes(ST) || record.Evaluator_id.toLowerCase().includes(ST);
            });
            setFilteredData(filtered);
        }
        else if(searchTerm.length && typeFilterTerm.length) {
            const filtered = checkedData.filter(record=> {
                return (record.reason.toLowerCase().includes(ST) || record.Evaluator_id.toLowerCase().includes(ST)) && record.assessment_type.toLowerCase() === TFT;
            })
            setFilteredData(filtered);
        } else { setFilteredData(checkedData); }
    }, [checkedData, searchTerm, typeFilterTerm]);

    const previousDay = dates? dates.find(date=> date !== today) : null;


    return (
        <div className='flex flex-col bg-slate-100 p-2'>
            <div className="flex w-full my-2 justify-start items-center">
                <h1 className="font-bold w-max mx-3 text-xl text-slate-400">Manage Flagged Issues - </h1>
                <p className="font-medium text-slate-500">
                    Here you can <span className="text-blue-400"> View flagged Entries, Branches and Flag actions history, Also Approve or Return flagged entries </span>
                </p>
            </div>
            <div className="flex items-center">{
                /**
                <h1 className=" font-bold text-lg text-slate-600 w-1/4 mr-12">Flagged Issues: {selectDate}</h1> */
            }
                
                <div className="flex items-center justify-start min-w-max w-3/4">
                    <div 
                        onClick={ (e)=> { setSelectdate('History') } } 
                        className={` m-1 w-1/6 min-w-max rounded cursor-pointer border border-slate-300 ${selectDate === "History"? 'bg-sideBarBlue text-slate-100 font-bold px-3 py-1' : 'bg-slate-100 text-slate-500 hover:text-sideBarBlue p-3'} text-center`}
                    >
                        <p>Admin History</p>
                        { selectDate === "History"?  <small className="text-xs font-normal">{`${dates? dates[dates.length-1] : 'start'} to ${dates? dates[0] : 'today'}`}</small> : null }
                    </div>
                    <div 
                        onClick={ (e)=> { setSelectdate(previousDay) } }
                        className={` m-1 w-1/6 min-w-max rounded cursor-pointer border border-slate-300 ${selectDate === previousDay? 'bg-sideBarBlue text-slate-100 font-bold px-3 py-1' : 'bg-slate-100 text-slate-500 hover:text-sideBarBlue p-3'} text-center`}
                    >
                        <p>Previous</p>
                        { selectDate === previousDay?  <small className="text-xs font-normal">{previousDay? previousDay : "..."}</small> : null }
                    </div>
                    <div 
                        onClick={ (e)=> { setSelectdate('All') }}  
                        className={` m-1 w-1/6 min-w-max rounded border border-slate-300 border border-slate-300 ${selectDate === "All"? 'bg-sideBarBlue text-slate-100 font-bold px-3 py-1' : 'bg-slate-100 text-slate-500 hover:text-sideBarBlue p-3'} text-center`}                    >
                        <p>All</p>
                        { selectDate === "All"?  <small className="text-xs font-normal">{`${dates? dates[dates.length-1] : 'start'} to ${dates? dates[0] : 'today'}`}</small> : null }
                    </div>
                    <div 
                        onClick={ (e)=> { setSelectdate(today) }} 
                        value={today} 
                        className={` m-1 w-1/6 min-w-max rounded cursor-pointer border border-slate-300 ${selectDate === today? 'bg-sideBarBlue text-slate-100 font-bold px-3 py-1' : 'bg-slate-100 text-slate-500 hover:text-sideBarBlue p-3'} text-center`}                    >
                        <p>Today</p>
                        {selectDate === today? <small className="text-xs font-normal">{today? today.split("-").reverse().join("-") : "..."}</small> : null }
                    </div>
                    <select onChange={handleDateSelect} defaultValue={previousDay} className="font-medium text-center text-blue-500 py-3 mx-3 text-sm focus:outline-none rounded border border-blue-200 hover:border-slate-100 hover:shadow-md focus:border focus:border-slate-100 focus:shadow-md">
                        <option value={''} disabled> Dates </option>
                        {
                            dates? dates.filter(date=> date !== today).map(date => {
                                return <option key={date} value={date}>{date}</option>
                            }) : null
                        }
                    </select>
                </div>
            </div>
            <section className="w-full p-5 my-4 bg-white shadow-md rounded ">
                <div className="flex w-full mb-2 p-2 bg-slate-25 shadow-md justify-between items-center">
                    <h1 className="w-1/5 font-bold text-lg text-slate-700">Flagged Entries :  {selectDate}{/*: <span className="font-medium text-blue-500 text-sm">{reportDate}</span>*/}</h1>
                    { selectDate !== "History"? 
                    (
                        <>
                        <input type='text' placeholder='Search Evaluator Code or Reason' onChange={(e)=> setSearchTerm(e.target.value)} className="w-2/5 lg:w-1/4 text-center mr-12 rounded-full border border-slate-300 hover:border-blue-300 focus:outline focus:outline-blue-400 focus:border-blue-600 p-2" />
                        <select onChange={(e)=> setTypeFilterTerm(e.target.value)} className="w-1/4 lg:w-1/4 text-center mr-12 rounded border border-slate-350 hover:border-blue-300 focus:outline focus:outline-blue-400 focus:border-blue-600 p-2">
                            <option value="">All Assessment Types</option>
                            <option value="Branch">Branch</option>
                            <option value="FLE">FLE</option>
                            <option value="Security">Security</option>
                        </select>
                        </>
                    )
                    :
                    <div></div>
                        
                    }

                </div>
                
                <div className="w-full flex justify-between items-center m-1 my-4">
                    <div>
                        <button onClick={onClickBulkDeleteFlagged} className={`p-3 m-1 w-1/4 min-w-max rounded active:bg-blue-600 active:text-slate-100 cursor-pointer hover:font-bold ${selectedEntries.length? 'bg-dayoAshBlue text-slate-100 font-bold hover:bg-sideBarBlue' : 'bg-slate-100 text-blue-600'} text-center`}>{ selectedEntries.length? `Return ${selectedEntries.length} ${selectedEntries.length > 1? 'Entries' : 'Entry'}` : null }<KeyboardReturnRounded /></button>
                        <button onClick={onClickBulkApproveFlagged} className={`p-3 m-1 w-1/4 min-w-max rounded active:bg-red-400 active:text-slate-100 cursor-pointer hover:font-bold ${selectedEntries.length? 'bg-red-500 text-slate-100 font-bold hover:bg-red-600' : 'bg-dayoRed text-red-600'} text-center`}>{ selectedEntries.length? `Approve ${selectedEntries.length} ${selectedEntries.length > 1? 'Entries' : 'Entry'}` : null } <FlagRounded /></button>
                    </div>
                    <div className=" flex items-center justify-around rounded min-w-max h-12 pl-2 pr-0 py-0 bg-dayoAshBlue hover:bg-blue-600 text-white"><CloudDownloadRounded /> <ReactHtmlTableToExcel className=' mx-2 h-full bg-inherit' table={"flaggedEntries"} buttonText={"Download Table as XLS"} filename={`Flagged_entries (${selectDate})`} sheet="flagged_entries_xls" /></div>
                </div>
                <PaginatedTable data={filteredData} table={selectDate !== 'History'? table.flaggedEntries : table.flaggedEntriesHistory} pageLimit={12} dataLimit={selectDate !== 'History' && selectDate !== 'All'? 15 : 20}  isSelectable={selectDate !== "History"? true : false} onClickDeselectAll={onClickDeselectAll} onClickSelectAll={onClickSelectAll} onClickSelect={onClickSelect} isAction={false} actionElements={[/*{text: "Confirm", onClick: handleAcceptFlag, bgColor: 'bg-red-500'}, {text: "Delete", onClick: handleUnflagReport, bgColor: 'bg-blue-500' }*/]} />
                <Table data={filteredData} table={table.flaggedEntries} hidden={true} id={"flaggedEntries"} />
            </section>

            <section className="w-full p-5 my-4 bg-white shadow-md rounded ">
                <div className=" flex justify-between items-center w-full p-5 my-4 bg-white shadow-md rounded ">
                    <h1 className="self-start font-bold text-lg text-red-700">Evaluator Complaints</h1>
                    <div className=" flex items-center justify-around rounded min-w-max h-12 pl-2 pr-0 py-0 bg-dayoAshBlue hover:bg-blue-600 text-white"><CloudDownloadRounded /> <ReactHtmlTableToExcel className=' mx-2 h-full bg-inherit' buttonText="Download Feedbacks as XLS" table="feedback-table" filename="Download_feedback" sheet="feedback_xls" /></div>
                </div>
                
                <PaginatedTable data={allFeedbacks && allFeedbacks} table={table.evaluatorFeedbacks} pageLimit={10} dataLimit={10}  isAction={false} actionElements={[/*{text: "View Evaluator", onClick: testOnClick }, {text: "Unflag Report", onClick: handleUnflagReport, bgColor: 'bg-green-500' }*/]} />
                <Table hidden={true} data={allFeedbacks && allFeedbacks} table={table.evaluatorFeedbacks} id={"feedback-table"} />
            
            </section>

            <section className="w-full p-5 my-4 bg-white shadow-md rounded ">
                <div className=" flex justify-between items-center w-full p-5 my-4 bg-white shadow-md rounded ">
                    <h1 className="self-start font-bold text-lg text-red-700">Flagged Branches</h1>
                    <div className=" flex items-center justify-around rounded min-w-max h-12 pl-2 pr-0 py-0 bg-dayoAshBlue hover:bg-blue-600 text-white"><CloudDownloadRounded /> <ReactHtmlTableToExcel className=' mx-2 h-full bg-inherit' buttonText="Download Flagged Branches as XLS" table="flagged-branches-table" filename="Download_flagged_branches" sheet="flagged_branches_xls" /></div>
                </div>
                
                <PaginatedTable data={selectDate !== 'All'? branches : campaignFlaggedBranches} table={table.flaggedBranches} pageLimit={10} dataLimit={selectDate !== 'All'? 5 : 10}  isAction={true} actionElements={[/*{text: "View Evaluator", onClick: testOnClick }, {text: "Unflag Report", onClick: handleUnflagReport, bgColor: 'bg-green-500' }*/]} />
                <Table hidden={true} data={selectDate !== 'All'? branches : campaignFlaggedBranches} table={table.flaggedBranches} id={"flagged-branches-table"} />
            
            </section>

            <section className={`flex justify-center text-white items-center w-4/5 h-full bg-transparent fixed ${confirmFormDisplay}`}>
                <form onSubmit={buttonAction == 'Approve'? onConfirmBulkApproveFlagged : onConfirmBulkDeleteFlagged} className={`flex flex-col w-1/2 min-w-max min-h-max h-1/3 lg:w-1/3 rounded-lg bg-slate-100 border border-slate-200 shadow-md`}>
                    <div className='flex w-full rounded-t-lg px-2 justify-between items-center bg-sideBarBlue'>
                        <h1 className="text-xl">You are about to {buttonAction == 'Approve'? 'Approve' : 'Return' } Entries</h1>
                        <button onClick={(e)=>{e.preventDefault(); setConfirmFormDisplay("hidden")}} className="bg-dayoAshBlue text-slate-100 font-bold px-2 py-0 text-center rounded-full my-4 hover:bg-red-500 hover:text-white">X</button>
                    </div>
                    <div className="w-full h-full px-2">
                        <h1 className="flex items-center justify-center text-slate-600 font-md h-1/2">{ buttonAction == 'Approve'? `Are you sure you want Approve ${selectedEntries.length} ${selectedEntries.length > 1? 'Entries' : 'Entry'}` : `Are you sure you want Return ${selectedEntries.length} ${selectedEntries.length > 1? 'Entries?' : 'Entry?'}` } </h1>
                        <p className="text-center text-red-600">{ buttonAction == 'Approve'? ('If confirmed, Entries are marked as NOT COMPLETED and can be reassigned') :  ('If confirmed, Entries are marked as COMPLETED and are moved to the Reports table')}</p>
                        <div className="flex justify-between">
                            <input type="submit" className="flex items-center justify-center w-1/2 p-3 my-2 bg-red-100 text-red-500 border border-red-600 hover:bg-red-500 hover:text-white font-bold rounded" value='Confirm' />
                            <button onClick={(e)=>{e.preventDefault(); setConfirmFormDisplay("hidden")}} className="flex items-center justify-center w-1/2 p-3 my-2 mx-1 border border-dayoAshBlue bg-dayoLightBlue text-sideBarBlue active:bg-dayoAshBlue hover:bg-sideBarBlue hover:text-white font-bold rounded" >Cancel</button>
                        </div>
                        
                    </div>
                                         
                </form>
            </section>
        </div>
    )
}

export default FlaggedIssues;