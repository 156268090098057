
import { useForm } from "react-hook-form";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { useSelector } from "react-redux";


const EvaluatorBulkUploadForm= ({onSubmitForm}) => {
    
    const { register, handleSubmit, errors } = useForm();
    const { allCampaigns } = useSelector(state => state.corporate);

    return (
        <div className={`flex w-max p-2 m-4 bg-white shadow-md rounded-lg`}>
            <form className={` flex flex-col w-1/2 lg:w-1/2 p-4 rounded border border-slate-300 bg-300 justify-center shadow-md `} onSubmit={handleSubmit(onSubmitForm)}>
                <h1 className="text-slate-600 font-bold m-2 ml-0">Upload Evaluators List File</h1>
                <input
                    {...register("file")}
                    type="file"
                    className="border border-slate-500 rounded p-2"
                    accepted=".csv .xlsx .xls"
                    required
                />
                <select defaultValue={"DEFAULT"}
                    {...register("campaign_id")}
                    className="my-2 p-3 rounded bg-white border border-slate-500">
                    <option value="DEFAULT" disabled>Select Campaign</option>
                    {
                        allCampaigns? allCampaigns.map(campaign => {
                            return <option key={campaign.campaign_id} value={campaign.campaign_id}>{campaign.campaign_name}</option>
                        }) : null
                    }
                </select>
                <input className="rounded w-full p-3 m-2 mx-auto text-slate-100 bg-sideBarBlue hover:font-bold border border-sideBarBlue hover:text-white" type="submit" value="Upload" />
            </form>

            <div className="m-3">
                <h1 className="text-blue-500 font-bold m-2 ml-0">Upload Directives (for beginners)</h1>
                <p>1. Download Template File</p>
                <p>2. Fill Excel file correctly</p>
                <p>3. Choose Evaluators file</p>
                <p>5. Click Upload</p>
                <ReactHtmlTableToExcel className="py-3 px-4 m-4 w-max h-max text-white bg-green-500" table={"templateEvaluatorUpload"} filename={"templateEvaluatorUpload"} sheet={"templateEvaluatorUpload"} buttonText={"Download Template File"} />
            </div>
            <table id="templateEvaluatorUpload" className="hidden">
                <thead>
                    <tr>
                        {
                            ["EVALUATOR ACCESS CODE", "EVALUATOR NAME", "EMAIL", "PHONE (Wihout Preceeding '0' e.g 8034567890", "EVALUATOR LGA", "ZONE", "STATE"].map(header => {
                                return <th key={header}>{header}</th>
                            })
                        }
                    </tr>
                </thead>
            </table>
        </div>
    )
}

export default EvaluatorBulkUploadForm;