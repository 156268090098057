import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	ChevronRightRounded,
	DashboardRounded,
	CollectionsBookmarkRounded,
	AssessmentRounded,
	BusinessRounded,
	AccountBoxRounded,
	ApartmentRounded,
	FlagRounded,
	SearchRounded,
	KeyboardArrowRightRounded,
	BarChartRounded,
} from '@material-ui/icons';
import AdminHeader from '../components/AdminHeader/AdminHeader';
import AdminSidebar from '../components/AdminSidebar/AdminSidebar';
import Branch from '../features/branch/Branch';
import Evaluator from '../features/evaluator/Evaluator';
import AdminSummary from '../features/adminSummary/AdminSummary';
import Reports, { today } from '../features/report/Report';

import { evaluatorListThunk } from '../features/evaluator/evaluatorSlice';
import { branchListThunk } from '../features/branch/branchSlice';
import { reportsThunk } from '../features/report/reportSlice';
import Corporate from '../features/corporate/Corporate';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { getAllAssessmentTypesThunk, getAllBanksThunk, getAllCampaignsThunk, getAllTasksThunk, getCampaignAssessmentTypesThunk, getCampaignBanksThunk } from '../features/corporate/corporateSlice';
import { dailyScopeCoverageThunk, pastWeekZoneScopeTrendThunk, overallScopeCoverageThunk, setCampaignID, loadDatesThunk, loadStatesThunk, loadLGAsThunk } from '../features/adminSummary/summarySlice';
//import { getUserFromSession } fro
import FlaggedIssues from '../features/flaggedIssues/FlaggedIssues';
import { allCampaignFlaggedThunk, flaggedThunk } from '../features/flaggedIssues/flaggedIssuesSlice';
import Insights from '../features/insights/Insight';
import { bankInsightThunk, bankInsightByStateThunk } from '../features/insights/insightSlice';

const pages = [
	{
		id: 0,
		title: 'Summary',
		icon: <DashboardRounded />,
		activeIcon: <KeyboardArrowRightRounded />,
		page: <AdminSummary />,
	},
	{
		id: 1,
		title: 'Manage Reports',
		icon: <AssessmentRounded />,
		activeIcon: <KeyboardArrowRightRounded />,
		page: <Reports />,
	},
	{
		id: 2,
		title: 'Manage Flagged Issues',
		icon: <FlagRounded />,
		activeIcon: <KeyboardArrowRightRounded />,
		page: <FlaggedIssues />,
	},
	{
		id: 3,
		title: 'Manage Evaluators',
		icon: <AccountBoxRounded />,
		activeIcon: <KeyboardArrowRightRounded />,
		page: <Evaluator />,
	},
	{
		id: 4,
		title: 'Manage Bank Branches',
		icon: <ApartmentRounded />,
		activeIcon: <KeyboardArrowRightRounded />,
		page: <Branch />,
	},
	{
		id: 5,
		title: 'Evaluation Insights',
		icon: <SearchRounded />,
		activeIcon: <KeyboardArrowRightRounded />,
		page: <Insights />,
		
	},
	{
		id: 6,
		title: 'Corporate Affairs',
		icon: <BusinessRounded />,
		activeIcon: <KeyboardArrowRightRounded />,
		page: <Corporate />,
		
	},
];

const AdminDashboard = () => {
	const navigation = useNavigate();
	const auth = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const { campaignID } = useSelector(state=> state.dataSummary);

	useEffect(()=>{
		dispatch(setCampaignID(sessionStorage.getItem('campaignID') != 'undefined' ? sessionStorage.getItem('campaignID') : 25));
		dispatch(getAllCampaignsThunk());
		dispatch(loadStatesThunk());
		dispatch(loadDatesThunk());
		dispatch(dailyScopeCoverageThunk(today));
		dispatch(overallScopeCoverageThunk());
		dispatch(loadLGAsThunk());
		dispatch(getCampaignAssessmentTypesThunk());
		dispatch(getCampaignBanksThunk());
		dispatch(getAllBanksThunk());
		dispatch(getAllAssessmentTypesThunk());
		/*
		dispatch(evaluatorListThunk());
		dispatch(branchListThunk());
		dispatch(reportsThunk());
		dispatch(getAllTasksThunk());
		dispatch(flaggedThunk(today));
		dispatch(allCampaignFlaggedThunk());*/
		dispatch(bankInsightThunk())
		.then(({meta})=>{
			toast.loading('Loading Insights...');
			if(meta.requestStatus === 'fulfilled') { toast.remove(); toast.success('Insight Fetched!') } else { toast.remove(); toast.error('Data fetch error') } 
		})

		//console.log({campaignSession: sessionStorage.getItem('campaignID')});
	}, [campaignID, dispatch])

	useEffect(()=>{
		const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : false;
		//const { token } = auth;
		if (!token) {
			navigation('/');
			toast.remove();
			toast('We have logged you out');
		} else{
			navigation('/admin');
		}
	}, [navigation, auth, campaignID]);


    // get value from session storage, if null default to 0
	const [currentPageID, setCurrentPageID] = useState(
		sessionStorage.getItem('admPage') ? Number(JSON.parse(sessionStorage.getItem('admPage'))) : 0
	);

	const setSelectedPageID = (id) => {
		setCurrentPageID(id);
		// persist selected page in session storage
		sessionStorage.setItem('admPage', id);
	};

	return (
		<div >
			<AdminHeader />
			<div className="flex h-full" style={{ height: '100%'}}>
				<AdminSidebar className='h-full' pages={pages} setPage={setSelectedPageID} currentPage={currentPageID} />
				<div style={{ flex: '4' }} className="overflow-x-hidden bg-slate-100 p-2">
					{/* map through pages and only render page that matches id */}
					{pages.map((page) =>
						page.id === currentPageID ? (
							<div key={page.title} className="max-w-full">
								{page.page}
							</div>
						) : null
					)}
				</div>
			</div>
		</div>
	);
};

export default AdminDashboard;