import axios from "axios";
import toast from "react-hot-toast";
//const campaignID = Number(sessionStorage.getItem('campaignID')) || 3;

const baseURL = 'https://mysteryshopper.connectifie.com/api/v1.0';

export const fetchBranchList = async (token, campaignID=3) => {
    const { data } = await axios.get(`${baseURL}/branch-list?campaign_id=${campaignID}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    
    //console.log({data})
    const branches = data.reverse().map(branch=>{
        return {
            id: branch.branch_id,
            name: branch.branch_name,
            address: branch.address,
            code: branch.branch_code,
            bank: branch.bank_name,
            state: branch.state,
            zone: branch.zone,
            group: branch.group_id
        }
    })

    return branches;

}

export const uploadBranch = async (token, formData) => {
    try {
        toast.remove();
        toast.loading('Uploading New Branch...');
        const { data } = await axios.post(`${baseURL}/branch/create`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        //console.log({data});
        toast.remove(); toast.success('Uploaded Successfully!');
        return data;
    }
    catch(e){
        toast.remove()
        toast.error(e.message)
    }

}

export const bulkUploadBranch = async (token, formData) => {
    
    toast.remove();
    toast.loading('Uploading File...');
    const { data } = await axios.post(`${baseURL}/branch/bulk-upload`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    //console.log({data});
    toast.remove(); toast.success('Uploaded Successfully!');
    return data;

}

export const updateBranch = async (token, branchID) => {
    const { data } = await axios.patch(`${baseURL}/branch/edit/${branchID}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    //console.log({data});
    return data;

}

export const deleteBranch = async (token, branchID) => {
    toast.remove();
    toast.loading('Deleting Branch...');
    const { data } = await axios.delete(`${baseURL}/branch/delete/${branchID}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    //console.log({data});
    toast.remove(); toast.success('Deleted Successfully!');
    return data;

}