import axios from "axios";
import toast from "react-hot-toast";
import { convertDate } from "../../utilities/utilFunctions";
import { today } from "../report/Report";
//const campaignID = Number(sessionStorage.getItem('campaignID')) || 3;

const baseURL = 'https://mysteryshopper.connectifie.com/api/v1.0';

export const fetchFlaggedReports = async (date= today, campaignID=3, token) => {
    try {
        const { data: flaggedEntries } = await axios.get(`${baseURL}/flag/flagged-branches/${date}?campaign_id=${campaignID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const flagged = flaggedEntries;
        const allFlag= flaggedEntries? flaggedEntries.allflag.map(entry=>{
            return { ...entry, /*date_created: convertDate(entry.date_created)*/ }
        }) : []
        //console.log({ flagged });

        return {
            branches: flagged.allBranchFlag,
            entries: allFlag,
        }
    } catch(error) {
        if(sessionStorage.token) toast(error.message)
    }
}

export const fetchFlagHistory = async (token, campaignID=3)=> {
    const { data } = await axios.get(`${baseURL}/flag_history/all?campaign=${campaignID}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    return data.reverse();
}

export const fetchEvaluatorsFeedback = async (token, campaignID=3)=> {
    const { data } = await axios.get(`${baseURL}/evaluator/feedback/${campaignID}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    console.log({feedbackData: data})

    return data.reverse();
}

export const allCampaignFlaggedReports = async (token, campaignID=3) => {
    try {
        const { data: flaggedEntries } = await axios.get(`${baseURL}/flag/all-flagged-branches?campaign_id=${campaignID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        const allFlag= flaggedEntries? flaggedEntries.allflag.map(entry=>{
            return { ...entry, date_created: convertDate(entry.date_created) }
        }) : []
        //console.log({ flaggedCampaign: flaggedEntries });

        return {
            campaignFlaggedBranches: flaggedEntries.allBranchFlag.reverse(),
            campaignFlaggedEntries: allFlag.reverse(),
        }
    } catch(error) {
        if(sessionStorage.token) toast(error.message)
    }
}

export const unflagReport = async (token, flagID) =>{
    try {
        const {data: response} = await axios.post(`${baseURL}/flag/delete/${flagID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        //console.log({response})
        response.success? toast.success(response.msg) : toast.error(response.msg);
        return response.success;
    } catch(e){
        toast.error(e.message);
    }
}

export const acceptFlagReport = async (token, flagID) =>{
    try {
        //console.log({flagID})
        const {data: response} = await axios.post(`${baseURL}/flag/approve/${flagID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        console.log({response})
        response.success? toast.success(response.msg) : toast.error(response.msg);
        return response.success;
    } catch(e){
        toast.error(e.message);
    }
}

export const bulkDeleteFlagged = async (token, flaggedIDsArray) => {
    try {
        //console.log({ allFlag: flaggedIDsArray })
        const {data: response} = await axios.post(`${baseURL}/flag/delete-bulk`, { allFlag: flaggedIDsArray }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        //console.log({response})
        response.success? toast.success(response.msg) : toast.error(response.msg);
        return response.success;
    } catch(e){
        toast.error(e.message);
    }
}

export const bulkApproveFlagged = async (token, flaggedIDsArray) => {
    try {
        const {data: response} = await axios.post(`${baseURL}/flag/approve-bulk`, { allFlag: flaggedIDsArray }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        //console.log({response})
        response.success? toast.success(response.msg) : toast.error(response.msg);
        return response.success;
    } catch(e){
        toast.error(e.message);
    }
}

