import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from "@material-ui/icons";
import { useState, useEffect } from "react";

const PaginatedTable = ({ data, table, pageLimit, dataLimit, id= 'theadReports', isSort, onClickSortTerm, sortDetails, isAction, isSelectable, onClickDeselectAll, onClickSelectAll, onClickSelect, actionElements=[] }) => {
    
    //console.log(data)
    const [pages, setPages] = useState( data && data.length? Math.ceil(data.length / dataLimit) : 1);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(()=>{
        const pages = data && data.length? Math.ceil(data.length / dataLimit) : 1;
		setPages(pages);
        if (currentPage > pages) setCurrentPage(pages);
    },[currentPage, data, dataLimit]);

    const [currentData, setCurrentData] = useState(data || []);
    
    // Methods
    const gotoNextPage = (e) =>{
        e.preventDefault();
        currentPage === pages? setCurrentPage(pages) :
        setCurrentPage((prev)=> {return prev + 1});
    };

    const gotoPreviousPage = (e) =>{
        e.preventDefault();
        currentPage === 1? setCurrentPage(1) :
        setCurrentPage((prev)=> prev - 1);
    };

    const changePage = (event) =>{
        event.preventDefault();
        Number(event.target.innerText) > pages ? setCurrentPage(pages) : setCurrentPage(Number(event.target.innerText));
    };
    
    const getDataByPage = () =>{
        const startIndex= (currentPage * dataLimit) - dataLimit;
        const endIndex= currentPage * dataLimit;
        const pageData = currentData && currentData.length? currentData.slice(startIndex, endIndex) : [];
        return pageData;
    };

    const getPaginationGroup = () => {
        let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
        const pagesGroup= new Array(pageLimit).fill().map((_, index) => start + index + 1);
        return pagesGroup;
    };


    const [actionDisplayIndex, setActionDisplayIndex] = useState(null);
    const toggleActionDisplayIndex = (index) => {
        index === actionDisplayIndex? setActionDisplayIndex(null) : setActionDisplayIndex(index);
    }
    const actionDisplay = (index) => {
        return index === actionDisplayIndex? "" : "hidden";
    }


    //=> Sort Order of Parent Data: sort by each score_category, total_score, total_score_percentage
    const [sortOrder, setSortOrder] = useState('asc');
    const handleSortOrder = () => {
        sortOrder === 'asc'? setSortOrder('desc') : setSortOrder('asc');
    }
    const [sortTerm, setSortTerm] = useState('');
    useEffect(()=> {
        try {
            setSortOrder('asc');
            if (sortTerm.length && data && data.length && Number(data[0][sortTerm])) { 
                const sortASC = data.sort((a, b)=> Number(a[`${sortTerm}`])- Number(b[`${sortTerm}`]));
                const sortDESC = data.sort((a, b)=> Number(a[`${sortTerm}`])- Number(b[`${sortTerm}`])).reverse();
                sortOrder === 'asc'? setCurrentData(sortASC) : setCurrentData(sortDESC);
            }
            else if (sortTerm.length &&  data && data.length && !Number(data[0][sortTerm])) {
                const sortASC = data.sort((a, b)=> a[`${sortTerm}`].localeCompare(b[`${sortTerm}`]));
                const sortDESC = data.sort((a, b)=> a[`${sortTerm}`].localeCompare(b[`${sortTerm}`])).reverse();
                sortOrder === 'asc'? setCurrentData(sortASC) : setCurrentData(sortDESC);
            }
            else { setCurrentData(data); }
        }
        catch(error) {
            console.log({error});
        }
        
    }, [sortTerm, sortOrder, data]);

    const handleSortTerm = (term) => {
        sortTerm === term? handleSortOrder() : setSortTerm(term);
    }



    return (
        <div>
            <div className='overflow-x-scroll'>
                <table
                    id="Data"
                    className="border overflow-scroll border-slate-200 w-full divide-y divide-gray-200 table-auto z-10 mx-auto"
                >
                    <thead id={id} className={`${currentData && currentData.length? 'bg-dayoAshBlue text-white' : 'bg-dayoLightBlue text-slate-700'} `}
                        
                    >
                        <tr>
                            {isSelectable? (
                            <th className="flex items-center justify-center w-fit py-2 px-1">
                                <label className="flex items-center w-fit">
                                    <input type='checkbox' className='form-checkbox rounded p-3 mx-1 text-sm' onChange={(e)=> { if(!e.target.checked) {onClickDeselectAll() } else {  onClickSelectAll() }} }/>
                                    Toggle Select All
                                </label>
                            </th>): null}
                            <th
                                scope="col"
                                className="p-3 text-xs font-medium tracking-wider text-center uppercase"
                            >
                                S/N
                            </th>
                            {table.tHeaders.map((tableheader, i) => {
                                return (
                                    <th
                                        scope="col"
                                        key={i}
                                        className="py-3 px-2 w-min text-xs font-medium tracking-wider text-center uppercase"
                                        onClick={isSort && tableheader !== 'Actions' ? handleSortTerm(table.tProperties[i]) : null}
                                    >
                                        {tableheader}
                                        {tableheader === sortTerm && sortOrder === 'desc'? <KeyboardArrowDownOutlined /> : tableheader === sortTerm && sortOrder === 'asc'? <KeyboardArrowUpOutlined /> : null}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {getDataByPage().map((record, i) => {
                            const index = (currentPage * dataLimit) - dataLimit + i;
                            return (
                                <tr className="hover:bg-gray-100" key={index}>
                                    {isSelectable? (
                                    <td className={`${isSelectable && record.checked? 'bg-slate-50' : ''} px-4 w-40`} >
                                        <input type='checkbox' className="form-checkbox checked:text-blue-400 checked:ring-none p-4 rounded" checked={record.checked} onClick={()=>{ onClickSelect(record, record.flag_id? record.flag_id : index) }}></input>
                                    </td>) : null}
                                    <td className={`${isSelectable && record.checked? 'bg-slate-50' : ''} py-4 px-6 w-min text-sm font-medium text-gray-900 text-center whitespace-normal`}>
                                        {(dataLimit * currentPage)-(dataLimit-1) + i}
                                    </td>
                                    {table.tProperties.map((property, i) => {
                                        return (	
                                            <td
                                                key={i}
                                                className={`${isSelectable && record.checked? 'bg-slate-50' : ''} status py-3 px-3 w-min text-sm font-medium text-center text-gray-900 whitespace-normal`}
                                            >
                                                { record[`${property}`] }
                                            </td>
                                        );
                                    })}
                                    {
                                        isAction && actionElements.length? (
                                        <td className="flex flex-col items-center justify-center w-full h-full p-2">
                                            <button id={'closeActionDD'} onClick={ (e)=> { e.preventDefault(); toggleActionDisplayIndex(index)}} className={`${actionDisplayIndex === index? "h-8 w-8 text-dayoRed bg-red-500 rounded-full hover:text-slate-100 hover:bg-red-600 " : "w-full bg-dayoNeutral px-3 py-2 underline" }`}>{actionDisplayIndex === index? "X" : "Actions"}</button>
                                            <div className={`${actionDisplay(index)} flex flex-col items-center px-1 rounded-md shadow-md`}>
                                            {
                                                actionElements.map((actionElement, i)=>{
                                                    return <button key={i} onClick={()=>{ actionElement.onClick(record) }} className={`${actionElement.class? actionElement.class : 'min-w-max w-full rounded bg-dayoAshBlue text-white m-1 p-2 focus:bg-sideBarBlue hover:bg-sideBarBlue'}`}>{actionElement.text}</button>
                                                })
                                            }
                                            </div>
                                        </td>
                                        ) : null
                                    }
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <div className="flex justify-center items-center w-full">
                {/* previous button */}
                <button
                    onClick={gotoPreviousPage}
                    className={`w-max h-full border m-1 px-3 py-2 rounded-full text-white hover:bg-slate-500 ${currentPage === 1 ? 'cursor-not-allowed' : 'bg-dayoAshBlue'}`}
                >
                    prev
                </button>

                {/* show page numbers */}
                {getPaginationGroup().map((item, index) => (
                    <button
                    key={index}
                    value={item}
                    onClick={changePage}
                    className={`w-max h-full border m-1 px-3 py-2 rounded-full text-white hover:bg-slate-500 ${currentPage === pages ? 'cursor-not-allowed' : ''} ${currentPage === item ? 'bg-slate-600' : 'bg-slate-200'}`}
                    >
                    <span>{item}</span>
                    </button>
                ))}

                {/* next button */}
                <button
                    onClick={gotoNextPage}
                    className={`w-max h-full border m-1 px-3 py-2 rounded-full text-white hover:bg-slate-500 ${currentPage === pages ? 'cursor-not-allowed' : 'bg-dayoAshBlue'}`}
                >
                    next
                </button>
            </div>
        </div>
        
    );
};

export default PaginatedTable;