import { useEffect, useState } from 'react';

const Table = ({ table, data, id, hidden }) => {
	const [checkedData, setCheckedData] = useState([]);
	useEffect(() => {
		data && data.length? setCheckedData(data): setCheckedData([]);
	}, [data]);

	return (
		<div className={`overflow-x-scroll ${hidden? 'hidden' : ''} `}>
			<table
				id={id || 'table'}
				className="border overflow-scroll border-slate-200 w-full divide-y divide-gray-200 table-auto z-10 mx-auto"
			>
				<thead className="bg-dayoLightBlue">
					<tr>
						<th
							scope="col"
							className="py-3 px-6 text-xs font-medium tracking-wider text-center text-gray-900 uppercase"
						>
							S/N
						</th>
						{table.tHeaders.map((tableheader, i) => {
							return (
								<th
									scope="col"
									key={i}
									className="py-3 px-6 text-xs font-medium tracking-wider text-center text-gray-900 uppercase"
								>
									{tableheader}
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody className="bg-white divide-y divide-gray-200">
					{checkedData.map((record, i) => {
						return (
							<tr className="hover:bg-gray-100" key={i}>
								<td className="py-4 px-6 text-sm font-medium text-gray-900 text-center whitespace-nowrap">
									{i+1}
								</td>
								{table.tProperties.map((property, i) => {
									return (	
										<td
											key={i}
											className={`status py-4 px-6 text-sm font-medium text-center text-gray-900 whitespace-nowrap`}
										>
											{record[`${property}`]}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

export default Table;
