
const SidebarItem = ({ onClick, icon, label, active, activeIcon }) => {
	const classes= `flex items-center justify-between opacity-85 rounded-md w-full h-max my-0 mx-1 md:px-4 md:py-2 p-4 text-left hover:scale-105 hover:pl-4 ${active? 'font-bold text-sideBarBG bg-slate-100' : ' text-dayoLightBlue  bg-inherit border-b border-dayoLightBlue shadow'}  ` // The Class Style 

	return (
		<li onClick={onClick} className={classes} data-active={active}>
			<div className="flex items-center">
				<span className={`${label.includes('Flag') && active? 'text-red-500 ' : !label.includes('Flag') && active? 'text-sideBarBlue' : ''}`}>{icon}</span> 
				<span className={`m-1 hidden md:block lg:block text-md ${active? 'text-dayoDarkGray' : 'text-white'} `}>{label}</span>
			</div>
			<span>{active? activeIcon : null}</span>
		</li>
	);
};

export default SidebarItem;
