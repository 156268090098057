
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";


const FormTable = ({ table, data= [], onCancel, onSubmitForm, editableProperties, constraintKeyInData, id, hidden }) =>  {
    
	const { register, handleSubmit } = useForm();

    const defaultFormData = data.map(({ evaluator_answer_id, max_score, score })=> {
        return {
            evaluator_answer_id, 
            max_score, 
            score
        }
    })


    const [formData, setFormData] = useState(defaultFormData || []);
    useEffect(()=>{
        setFormData(defaultFormData? defaultFormData : [])
    }, [ data ])

    const handleChange= (record) => { // Collate Changes
        // console.log({ record })

        setFormData((prev)=> {
            const removeDuplicate = prev.filter(object=> object.evaluator_answer_id != record.evaluator_answer_id)
            const correctScore = record.score > record.max_score? record.max_score : record.score < 0? 0 : record.score;
            if( record.score > record.max_score) {
                toast.remove(); toast(`Maximum Score of ${record.max_score}`)
            }
            else if( record.score < 0) {
                toast.remove(); toast(`Minimum Score of 0`)
            }
            return [...removeDuplicate, { evaluator_answer_id: record.evaluator_answer_id, max_score: record.max_score, score: correctScore }]
        })
    }

    //console.log({formData})


    return (
        <div className={`overflow-x-scroll ${hidden? 'hidden' : ''} `}>
            <div className="flex items-center justify-between">
                <h1 className="text-xl text-slate-700 mx-2">Alter Entry Scores</h1>
                <div className="w-1/2 flex justify-end">
                    <button className="rounded-full px-4 py-2 bg-red-400 text-white m-2" onClick={onCancel}>Cancel</button>
                    <button className="rounded-full px-4 py-2 bg-sideBarBlue text-white m-2" onClick={(e)=> {e.preventDefault(); onSubmitForm(formData)}}>Save Report</button>
                </div>
                
            </div>
            <table
                id={id || 'table'}
                className="border overflow-scroll border-slate-200 w-full divide-y divide-gray-200 table-auto z-10 mx-auto"
            >
                <thead className="bg-dayoLightBlue">
                    <tr>
                        <th
                            scope="col"
                            className="py-3 px-6 text-xs font-medium tracking-wider text-center text-gray-900 uppercase"
                        >
                            S/N
                        </th>
                        {table.tHeaders.map((tableheader, i) => {
                            return (
                                <th
                                    scope="col"
                                    key={i}
                                    className="py-3 px-6 text-xs font-medium tracking-wider text-center text-gray-900 uppercase"
                                >
                                    {tableheader}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {data.length? data.map((record, i) => {
                            return (
                                <tr className="hover:bg-gray-100" key={i}>
                                    <td className="py-4 px-6 text-sm font-medium text-gray-900 text-center whitespace-nowrap">
                                        {i+1}
                                    </td>
                                    {table.tProperties.map((property, i) => {
                                        if(!editableProperties.includes(property)) {
                                            return (
                                                <td
                                                    key={i}
                                                    className={`status max-w-min w-min py-4 px-2 text-sm font-medium text-center text-slate-700`}
                                                >
                                                    {record[`${property}`]}
                                                </td>
                                            );
                                        }else {
                                            return (
                                                <td
                                                    key={i}
                                                    className={`status py-4 px-6 text-sm font-medium text-center text-gray-900 whitespace-nowrap`}
                                                >
                                                    <input 
                                                        type="number" 
                                                        placeholder={record[`${property}`]}
                                                        onChange={(e)=>{ handleChange({evaluator_answer_id: record.evaluator_answer_id, max_score: record.max_score, score: Number(e.target.value)}) }}
                                                        max={record[constraintKeyInData]}
                                                        min="0"
                                                        value={formData.length && formData.find(obj=> obj.evaluator_answer_id == record.evaluator_answer_id)? formData.find(obj=> obj.evaluator_answer_id == record.evaluator_answer_id).score : 0}
                                                        className="w-max py-4 border-none bg-slate-200  text-center hover:bg-slate-100 focus:bg-slate-100" />
                                                </td>
                                            );
                                        }
                                        
                                    })}
                                </tr>
                            );
                        }) : <tr><td>Loading Data ...</td></tr>}
                    </tbody>
            </table>
            <div className="flex items-center justify-between">
                <h1 className="text-xl text-slate-700 mx-2">Alter Entry Scores</h1>
                <div className="w-1/2 flex justify-end">
                    <button className="rounded-full px-4 py-2 bg-red-400 text-white m-2" onClick={onCancel}>Cancel</button>
                    <button className="rounded-full px-4 py-2 bg-sideBarBlue text-white m-2" onClick={(e)=> {e.preventDefault(); onSubmitForm(formData)}}>Save Report</button>
                </div>
                
            </div>
        </div>        
    )
}

export default FormTable;