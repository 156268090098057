import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDataSummary, fetchZonalTrend, fetchOverallScopeCoverage, fetchDailyScopeCoverage, fetchCummulativeCampaignProgress, fetchDates, FetchStates, FetchLGAs } from "./summaryAPI";

const initialState = {
    campaignID: sessionStorage.getItem("campaignID")? sessionStorage.getItem("campaignID") : 3,
};
const reducers = {
    setCampaignID: (state, action) => {
        sessionStorage.setItem('campaignID', action.payload);
        return {...state, campaignID: action.payload}
    }
};

export const dataSummaryThunk = createAsyncThunk('dataSummary/load', async (_, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload = await fetchDataSummary(token, campaignID);
    //console.log(fetchDataSummary(token, campaignID))
    return {...payload};
});

export const pastWeekZoneScopeTrendThunk = createAsyncThunk('dataSummary/zoneTrend', async (dates, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload= await fetchZonalTrend(token, dates, campaignID);
    return { zonalTrend: payload };
})

export const overallScopeCoverageThunk = createAsyncThunk('dataSummary/loadOverallScopeCoverage', async (_, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload= await fetchOverallScopeCoverage(token, campaignID);
    return { overallScopeCoverage: payload };
})

export const dailyScopeCoverageThunk = createAsyncThunk('dataSummary/loadDailyScopeCoverage', async (date, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload= await fetchDailyScopeCoverage(token, date, campaignID);
    return { dailyScopeCoverage: payload };
})

export const dailyProgressThunk = createAsyncThunk('dataSummary/loadDailyCampaignProgress', async (dates, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload= await fetchCummulativeCampaignProgress(token, dates, campaignID);
    return { cummulativeCampaignProgress: payload };
})

export const cummulativeProgressThunk = createAsyncThunk('dataSummary/loadCummulativeCampaignProgress', async (_, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload= await fetchCummulativeCampaignProgress(token, campaignID);
    return { cummulativeCampaignProgress: payload };
})

export const loadDatesThunk = createAsyncThunk('dataSummary/loadDates', async (_, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload = await fetchDates(token, campaignID);
    return { dates: payload };
})

export const loadStatesThunk = createAsyncThunk('dataSummary/loadStates', async (_, { getState })=>{
    const { token } = getState().auth;
    //const { campaignID } = getState().dataSummary;
    const payload = await FetchStates(token);
    //console.log({ payload })
    return { states: payload};
});

export const loadLGAsThunk = createAsyncThunk('dataSummary/loadLGAs', async (_, { getState })=>{
    const { token } = getState().auth;
    //const { campaignID } = getState().dataSummary;
    const payload = await FetchLGAs(token);
    return { LGA: payload};
});



const dataSummarySlice = createSlice({
    name: 'dataSummary',
    initialState,
    reducers,
    extraReducers: (builder)=>{
        builder
            .addCase(loadDatesThunk.fulfilled, (state, action)=> ({...state, ...action.payload}))
            .addCase(loadStatesThunk.fulfilled, (state, action)=> ({...state, ...action.payload}))
            .addCase(dataSummaryThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(pastWeekZoneScopeTrendThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(overallScopeCoverageThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(dailyScopeCoverageThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(cummulativeProgressThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(loadLGAsThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
    }
});

export const { setCampaignID } = dataSummarySlice.actions; 

export default dataSummarySlice.reducer;
