import axios from "axios";
import toast from "react-hot-toast";
import { convertDate } from "../../utilities/utilFunctions";
//const campaignID = Number(sessionStorage.getItem('campaignID')) || 3;

const baseURL = 'https://mysteryshopper.connectifie.com/api/v1.0';

export const fetchEvaluatorList = async (token, campaignID=3) =>{
    const { data } = await axios.get(`${baseURL}/evalutor-list?campaign_id=${campaignID}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    //console.log({data})
    const evaluatorList = data.Evaluator.map(evaluator => {
        return {
            id: evaluator.evaluator_id,
            access_code: evaluator.evaluator_access_code,
            full_name: evaluator.evaluator_name,
            email: evaluator.evaluator_email,
            phone: evaluator.phone,
            image: evaluator.evaluator_image,
            address: evaluator.evaluator_address,
            lga: evaluator.evaluator_lga,
            zone: evaluator.evaluator_zone,
            state: evaluator.evaluator_state,
            status: evaluator.account_status,
            createdAt: convertDate(evaluator.date_created)
        }
    })

    return evaluatorList.reverse();
};

export const assignTask = async (data, campaignID=3, token) => {
    try {
        //console.log({ data });
        const { data: response } = await axios.post(`${baseURL}/evaluator/assessment/assign?campaign_id=${campaignID}`, { task: data }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    response && response.success? toast.success(response.msg): toast.error(response.msg);
    return response;
    } catch (error) {
        toast.error(error.message);
        return null;
    }
}

export const getEvaluatorData = async (access_code, campaignID=3, token) => {
    const { data: evaluatorPayment } = await axios.get(`${baseURL}/payment/evaluator/${access_code}?campaign_id=${campaignID}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    const { data: evaluatorSummary } = await axios.get(`${baseURL}/evaluator/report/${access_code}?campaign_id=${campaignID}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    const formatMoney = (money) => {
        return money.split('.')[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    //console.log({ ...evaluatorPayment, ...evaluatorSummary })
    return { ...evaluatorPayment, ...evaluatorSummary, wages: formatMoney(evaluatorPayment.wages) };
}

export const uploadOverwriteEvaluator = async (data, token) => {
    /*const { data: response } = await axios.post(`${baseURL}/evaluator/assessment/overwrite`, data, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response;*/
    console.log({ data });
}

export const uploadEvaluator = async (record, token) => {
    try {
        //console.log({record})
        toast.remove();
        toast.loading('Uploading New Evaluator...');
        const { data: response } = await axios.post(`${baseURL}/evaluator/create`, record, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        //console.log({ response });
        toast.remove(); toast.success('Uploaded Successfully!');
        return response;
    } catch (error) {
        console.log(error.message);
        toast.remove();
        toast.error(error.message);
    }
    
}

export const bulkUploadEvaluators = async (formData, token) => {
    try {
        //console.log({formData})
        const { data: response } = await axios.post(`${baseURL}/evaluator/create-bulk`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        //console.log({ response });
        return response;
    } catch (error) {
        console.log(error.message);
        toast.remove();
        toast.error(error.message);
    }
    
}

export const deactivateEvaluator = async (recordID, token) => {
        //console.log({recordID})
    
        //console.log({token})
        const { data: response } = await axios.post(`${baseURL}/evaluator/deactivate/${recordID}`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        //console.log({ response });
        return response;
}

export const deleteEvaluator = async (recordID, token) => {
    //console.log({recordID})
    const { data: response } = await axios.delete(`${baseURL}/evaluator/delete/${recordID}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    
    //console.log({ response });
    return response;
}



