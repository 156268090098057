import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BarChart from "../../components/charts/BarChart";
import { bankInsightByStateThunk, bankInsightThunk } from "./insightSlice";
//import GroupedBarChart from "../../components/charts/BarChartGrouped";


const Insights = () => {
    const { accessInsight, gtbInsight, stanbicInsight, zenithInsight, } = useSelector(state=> state.insight);
    const { states, campaignID } = useSelector(state=> state.dataSummary);
    const dispatch = useDispatch();

    const [selectedBanks, setSelectedBanks]= useState([]);
    const [selectedState, setSelectedState]= useState('All States');
    useEffect(()=>{
        if (selectedState !== 'All States') {
            dispatch(bankInsightByStateThunk(selectedState));
        }
        else{
            dispatch(bankInsightThunk());
        }
    }, [dispatch, selectedState, campaignID]);

    const handleLocationChange = (location) => {
        setSelectedState(location);
    }



    const [overallTotalAssessed, setOverallTotalAssessed] = useState([]);
    const [overallAverage, setOverallAverage] = useState([]);

    //console.log({ overallAverage })

    // EFFECT TO SET OVERALL DATA STATE: Total Assessed, Average
    useEffect(()=>{
        const overallTotalData = {
            access: accessInsight ? { total: accessInsight.total.totalAccess } : {},
            gtb: gtbInsight ? { total: gtbInsight.total.totalGtb } : {},
            stanbic: stanbicInsight ? { total: stanbicInsight.total.totalStanbic } : {},
            zenith: zenithInsight ? { total: zenithInsight.total.totalZenith } : {}
        }

        const totalAssessments = Object.values(overallTotalData).map(bank=> bank.total);
        setOverallTotalAssessed(totalAssessments);


        const overallAvgData = {
            access: accessInsight && campaignID != 3? { average: ((accessInsight.average.totalAccessBranch + accessInsight.average.fleScoreAccess + accessInsight.average.totalAccessSEC + accessInsight.average.totalAccessIntern)/ 3).toFixed(2) } : accessInsight && campaignID == 3? { average: accessInsight.average.totalAccessIntern } : {} /**scalability of campaign Specifity accessInsight.average.totalAccess`${campaignType}` and currentCampaignTypes.length instead of 3 */,
            gtb: gtbInsight && campaignID != 3? { average: ((gtbInsight.average.totalGtbBranch + gtbInsight.average.totalGtbFLE + gtbInsight.average.totalGtbSEC + gtbInsight.average.totalIntern)/ 3).toFixed(2) } : gtbInsight && campaignID == 3? { average: gtbInsight.average.totalIntern } : {} /**currentCampaignTypes.length instead of 3 */,
            stanbic: stanbicInsight && campaignID != 3? { average: ((stanbicInsight.average.totalStanbicBranch + stanbicInsight.average.totalStanbicFLE + stanbicInsight.average.totalStanbicSEC + stanbicInsight.average.totalIntern)/ 3).toFixed(2) } : stanbicInsight && campaignID == 3? { average: stanbicInsight.average.totalIntern } : {} /**currentCampaignTypes.length instead of 3 */,
            zenith: zenithInsight && campaignID != 3? { average: ((zenithInsight.average.totalZenithBranch + zenithInsight.average.totalZenithFLE + zenithInsight.average.totalZenithcSEC + zenithInsight.average.totalIntern)/ 3).toFixed(2) } : zenithInsight && campaignID == 3? { average: zenithInsight.average.totalIntern } : {} /**currentCampaignTypes.length instead of 3 */
        }

        //console.log({overallAvgData});

        const avgAssessments = Object.values(overallAvgData).map(bank=> bank.average);
        setOverallAverage(avgAssessments);

    },[accessInsight, campaignID, gtbInsight, stanbicInsight, zenithInsight])

    //=> Format "total" data for bar chart according to selected banks
    const makeOverallTotalData = () => {
        if (overallTotalAssessed.length) {
            let totalAssessed = selectedBanks.map(bankIndex => {
                return overallTotalAssessed[bankIndex];
            }) 
            return { totalAssessed };
        }
        else{
            return { totalAssessed: [] };
        }
    }

    //=> Format "average" data for bar chart according to selected banks
    const makeOverallAverageData = () => {
        if (overallAverage.length) {
            let averageAssessed = selectedBanks.map(bankIndex => {
                return (overallAverage[bankIndex]);
            }) 
            //console.log({overallAverage});
            return { averageAssessed };
        }
        else{
            return { averageAssessed: [] };
        }
    }

    const [banksBranchAssessment, setBanksBranchAssessment]= useState({});
    const [banksFLEAssessment, setBanksFLEAssessment]= useState({});
    const [banksSecAssessment, setBanksSecAssessment]= useState({});
    const [banksInternAssessment, setBanksInternAssessment]= useState({});

    const [banksGroup1Assessment, setBanksGroup1Assessment]= useState({}); // Group 1 (LPA)
    const [banksGroup2Assessment, setBanksGroup2Assessment]= useState({}); // Group 2
    const [banksGroup3Assessment, setBanksGroup3Assessment]= useState({}); // Group 3

    const [banksZoneNEAssessment, setBanksZoneNEAssessment]= useState({}); // North East
    const [banksZoneNCAssessment, setBanksZoneNCAssessment]= useState({}); // North Central
    const [banksZoneNWAssessment, setBanksZoneNWAssessment]= useState({}); // North West
    const [banksZoneSEAssessment, setBanksZoneSEAssessment]= useState({}); // South East
    const [banksZoneSSAssessment, setBanksZoneSSAssessment]= useState({}); // South South
    const [banksZoneSWAssessment, setBanksZoneSWAssessment]= useState({}); // South West


    // EFFECT TO SET ASSESSMENT DATA FOR EACH BANK: Types, Groups, Zones
    useEffect(()=>{

        // Types
        const banksBranchData = {
            access: accessInsight? { totalBranch: accessInsight.total.totalAccessBranch, averageBranch: accessInsight.average.totalAccessBranch } : {},
            gtb: gtbInsight? { totalBranch: gtbInsight.total.totalGtbBranch, averageBranch: gtbInsight.average.totalGtbBranch } : {},
            stanbic: stanbicInsight? { totalBranch: stanbicInsight.total.totalStanbicBranch, averageBranch: stanbicInsight.average.totalStanbicBranch } : {},
            zenith: zenithInsight? { totalBranch: zenithInsight.total.totalZenithBranch, averageBranch: zenithInsight.average.totalZenithBranch } : {}
        }

        const totalBranch= Object.values(banksBranchData).map(bank => bank.totalBranch);
        const averageBranch= Object.values(banksBranchData).map(bank => bank.averageBranch);
        setBanksBranchAssessment({totalBranch, averageBranch});

        const banksFLEData = {
            access: accessInsight? { totalFLE: accessInsight.total.totalAccessFLE, averageFLE: accessInsight.average.fleScoreAccess } : {},
            gtb: gtbInsight? { totalFLE: gtbInsight.total.totalGtbFLE, averageFLE: gtbInsight.average.totalGtbFLE } : {},
            stanbic: stanbicInsight? { totalFLE: stanbicInsight.total.totalStanbicFLE, averageFLE: stanbicInsight.average.totalStanbicFLE } : {},
            zenith: zenithInsight? { totalFLE: zenithInsight.total.totalZenithFLE, averageFLE: zenithInsight.average.totalZenithFLE } : {}
        }

        const totalFLE= Object.values(banksFLEData).map(bank => bank.totalFLE);
        const averageFLE= Object.values(banksFLEData).map(bank => bank.averageFLE);
        setBanksFLEAssessment({totalFLE, averageFLE});

        const banksSECData = {
            access: accessInsight? { totalSEC: accessInsight.total.totalAccessSEC, averageSEC: accessInsight.average.totalAccessSEC } : {},
            gtb: gtbInsight? { totalSEC: gtbInsight.total.totalGtbSEC, averageSEC: gtbInsight.average.totalGtbSEC } : {},
            stanbic: stanbicInsight? { totalSEC: stanbicInsight.total.totalStanbicSEC, averageSEC: stanbicInsight.average.totalStanbicSEC } : {},
            zenith: zenithInsight? { totalSEC: zenithInsight.total.totalZenithcSEC, averageSEC: zenithInsight.average.totalZenithcSEC } : {}
        }

        const totalSEC= Object.values(banksSECData).map(bank => bank.totalSEC);
        const averageSEC= Object.values(banksSECData).map(bank => bank.averageSEC);
        setBanksSecAssessment({totalSEC, averageSEC});

        const banksInternData = {
            access: accessInsight && accessInsight.total.totalAccessIntern? { totalIntern: accessInsight.total.totalAccessIntern, averageIntern: accessInsight.average.totalAccessIntern } : {},
            gtb: gtbInsight && gtbInsight.total.totalGtbIntern? { totalIntern: gtbInsight.total.totalGtbIntern, averageIntern: gtbInsight.average.totalIntern } : {},
            stanbic: stanbicInsight && stanbicInsight.total.totalStanbicIntern? { totalIntern: stanbicInsight.total.totalStanbicIntern, averageIntern: stanbicInsight.average.totalIntern } : {},
            zenith: zenithInsight && zenithInsight.total.totalZenithIntern? { totalIntern: zenithInsight.total.totalZenIntern, averageIntern: zenithInsight.average.totalIntern } : {}
        }

        //console.log({banksInternData})

        const totalIntern= Object.values(banksInternData).map(bank => bank.totalIntern);
        const averageIntern= Object.values(banksInternData).map(bank => bank.averageIntern);
        setBanksInternAssessment({totalIntern, averageIntern});


        // Groups
        const banksGroup1Data = {
            access: accessInsight? { totalGroup1: accessInsight.total.totalAccessGroup1, averageGroup1: accessInsight.average.totalAccessGroup1 } : {},
            gtb: gtbInsight? { totalGroup1: gtbInsight.total.totalGtbGroup1, averageGroup1: gtbInsight.average.totalGtbGroup1 } : {},
            stanbic: stanbicInsight? { totalGroup1: stanbicInsight.total.totalStanbicGroup1, averageGroup1: stanbicInsight.average.totalStanbicGroup1 } : {},
            zenith: zenithInsight? { totalGroup1: zenithInsight.total.totalZenGroup1, averageGroup1: zenithInsight.average.totalZenGroup1 } : {}
        }

        const totalGroup1= Object.values(banksGroup1Data).map(bank => bank.totalGroup1);
        const averageGroup1= Object.values(banksGroup1Data).map(bank => bank.averageGroup1);

        //console.log({totalGroup1, averageGroup1})

        setBanksGroup1Assessment({totalGroup1, averageGroup1});

        const banksGroup2Data = {
            access: accessInsight? { totalGroup2: accessInsight.total.totalAccessGroup2, averageGroup2: accessInsight.average.totalAccessGroup2 } : {},
            gtb: gtbInsight? { totalGroup2: gtbInsight.total.totalGtbGroup2, averageGroup2: gtbInsight.average.totalGtbGroup2 } : {},
            stanbic: stanbicInsight? { totalGroup2: stanbicInsight.total.totalStanbicGroup2, averageGroup2: stanbicInsight.average.totalStanbicGroup2 } : {},
            zenith: zenithInsight? { totalGroup2: zenithInsight.total.totalZenGroup2, averageGroup2: zenithInsight.average.totalZenGroup2 } : {}
        }

        const totalGroup2= Object.values(banksGroup2Data).map(bank => bank.totalGroup2);
        const averageGroup2= Object.values(banksGroup2Data).map(bank => bank.averageGroup2);
        setBanksGroup2Assessment({totalGroup2, averageGroup2});

        const banksGroup3Data = {
            access: accessInsight? { totalGroup3: accessInsight.total.totalAccessGroup3, averageGroup3: accessInsight.average.totalAccessGroup3 } : {},
            gtb: gtbInsight? { totalGroup3: gtbInsight.total.totalGtbGroup3, averageGroup3: gtbInsight.average.totalGtbGroup3 } : {},
            stanbic: stanbicInsight? { totalGroup3: stanbicInsight.total.totalStanbicGroup3, averageGroup3: stanbicInsight.average.totalStanbicGroup3 } : {},
            zenith: zenithInsight? { totalGroup3: zenithInsight.total.totalZenGroup3, averageGroup3: zenithInsight.average.totalZenGroup3 } : {}
        }

        const totalGroup3= Object.values(banksGroup3Data).map(bank => bank.totalGroup3);
        const averageGroup3= Object.values(banksGroup3Data).map(bank => bank.averageGroup3);
        setBanksGroup3Assessment({totalGroup3, averageGroup3}); 
        

        // Zones
        const NorthEast = {
            access: accessInsight? { zoneTotal: accessInsight.total.totalAccessNE, zoneAverage: accessInsight.average.totalAccessNE } : {},
            gtb: gtbInsight? { zoneTotal: gtbInsight.total.totalGtbNE, zoneAverage: gtbInsight.average.totalGtbNE  } : {},
            stanbic: stanbicInsight? { zoneTotal: stanbicInsight.total.totalStanbicNE, zoneAverage: stanbicInsight.average.totalStanbicNE } : {},
            zenith: zenithInsight? { zoneTotal: zenithInsight.total.totalZenNE, zoneAverage: zenithInsight.average.totalZenNE } : {},
        }

        const totalNorthEast = Object.values(NorthEast).map(bank=> bank.zoneTotal);
        const averageNorthEast = Object.values(NorthEast).map(bank=> bank.zoneAverage);
        setBanksZoneNEAssessment({totalNorthEast, averageNorthEast});

        const NorthCentral = {
            access: accessInsight? { zoneTotal: accessInsight.total.totalAccessNC, zoneAverage: accessInsight.average.totalAccessNC } : {},
            gtb: gtbInsight? { zoneTotal: gtbInsight.total.totalGtbNC, zoneAverage: gtbInsight.average.totalGtbNC  } : {},
            stanbic: stanbicInsight? { zoneTotal: stanbicInsight.total.totalStanbicNC, zoneAverage: stanbicInsight.average.totalStanbicNC } : {},
            zenith: zenithInsight? { zoneTotal: zenithInsight.total.totalZenNC, zoneAverage: zenithInsight.average.totalZenNC } : {},
        }

        const totalNorthCentral = Object.values(NorthCentral).map(bank=> bank.zoneTotal);
        const averageNorthCentral = Object.values(NorthCentral).map(bank=> bank.zoneAverage);
        setBanksZoneNCAssessment({totalNorthCentral, averageNorthCentral});

        const NorthWest = {
            access: accessInsight? { zoneTotal: accessInsight.total.totalAccessNW, zoneAverage: accessInsight.average.totalAccessNW } : {},
            gtb: gtbInsight? { zoneTotal: gtbInsight.total.totalGtbNW, zoneAverage: gtbInsight.average.totalGtbNW  } : {},
            stanbic: stanbicInsight? { zoneTotal: stanbicInsight.total.totalStanbicNW, zoneAverage: stanbicInsight.average.totalStanbicNW } : {},
            zenith: zenithInsight? { zoneTotal: zenithInsight.total.totalZenNW, zoneAverage: zenithInsight.average.totalZenNW } : {},
        }

        const totalNorthWest = Object.values(NorthWest).map(bank=> bank.zoneTotal);
        const averageNorthWest = Object.values(NorthWest).map(bank=> bank.zoneAverage);
        setBanksZoneNWAssessment({totalNorthWest, averageNorthWest});

        const SouthEast = {
            access: accessInsight? { zoneTotal: accessInsight.total.totalAccessSE, zoneAverage: accessInsight.average.totalAccessSE } : {},
            gtb: gtbInsight? { zoneTotal: gtbInsight.total.totalGtbSE, zoneAverage: gtbInsight.average.totalGtbSE  } : {},
            stanbic: stanbicInsight? { zoneTotal: stanbicInsight.total.totalStanbicSE, zoneAverage: stanbicInsight.average.totalStanbicSE } : {},
            zenith: zenithInsight? { zoneTotal: zenithInsight.total.totalZenSE, zoneAverage: zenithInsight.average.totalZenSE } : {},
        }

        const totalSouthEast = Object.values(SouthEast).map(bank=> bank.zoneTotal);
        const averageSouthEast = Object.values(SouthEast).map(bank=> bank.zoneAverage);
        setBanksZoneSEAssessment({totalSouthEast, averageSouthEast});

        const SouthSouth = {
            access: accessInsight? { zoneTotal: accessInsight.total.totalAccessSS, zoneAverage: accessInsight.average.totalAccessSS } : {},
            gtb: gtbInsight? { zoneTotal: gtbInsight.total.totalGtbSS, zoneAverage: gtbInsight.average.totalGtbSS  } : {},
            stanbic: stanbicInsight? { zoneTotal: stanbicInsight.total.totalStanbicSS, zoneAverage: stanbicInsight.average.totalStanbicSS } : {},
            zenith: zenithInsight? { zoneTotal: zenithInsight.total.totalZenSS, zoneAverage: zenithInsight.average.totalZenSS } : {},
        }

        const totalSouthSouth = Object.values(SouthSouth).map(bank=> bank.zoneTotal);
        const averageSouthSouth = Object.values(SouthSouth).map(bank=> bank.zoneAverage);
        setBanksZoneSSAssessment({totalSouthSouth, averageSouthSouth});

        const SouthWest = {
            access: accessInsight? { zoneTotal: accessInsight.total.totalAccessSW, zoneAverage: accessInsight.average.totalAccessSW } : {},
            gtb: gtbInsight? { zoneTotal: gtbInsight.total.totalGtbSW, zoneAverage: gtbInsight.average.totalGtbSW  } : {},
            stanbic: stanbicInsight? { zoneTotal: stanbicInsight.total.totalStanbicSW, zoneAverage: stanbicInsight.average.totalStanbicSW } : {},
            zenith: zenithInsight? { zoneTotal: zenithInsight.total.totalZenSW, zoneAverage: zenithInsight.average.totalZenSW } : {},
        }

        const totalSouthWest = Object.values(SouthWest).map(bank=> bank.zoneTotal);
        const averageSouthWest = Object.values(SouthWest).map(bank=> bank.zoneAverage);
        setBanksZoneSWAssessment({totalSouthWest, averageSouthWest});

    }, [accessInsight, gtbInsight, stanbicInsight, zenithInsight,])



    const [avgTotalFilterTerm, setAvgTotalFilterTerm] = useState({
        groups: 'total',
        types: 'total',
        zones: 'total',
    });

    const handleAvgTotalFilter = (section, filterValue) => {
        setAvgTotalFilterTerm({...avgTotalFilterTerm, [section]: filterValue});
    }


    // DATA MAKERS (Formatters)

    const makeAssessmentTypesData = () => {
        // xaxis = ['Access', 'GTB', 'Stanbic', 'Zenith']
        if (Object.keys(banksBranchAssessment).length) {
            const branch = selectedBanks.map(bankIndex => {
                return banksBranchAssessment[`${avgTotalFilterTerm.types}Branch`][bankIndex];
            });

            const FLE = selectedBanks.map(bankIndex => {
                return banksFLEAssessment[`${avgTotalFilterTerm.types}FLE`][bankIndex];
            });

            const SEC = selectedBanks.map(bankIndex => {
                return banksSecAssessment[`${avgTotalFilterTerm.types}SEC`][bankIndex];
            });

            const intern = selectedBanks.map(bankIndex => {
                return banksInternAssessment[`${avgTotalFilterTerm.types}Intern`][bankIndex] || 0;
            });

            return { branch, FLE, SEC, intern };
        }
        else {
            return { branch: [], FLE: [], SEC: [], intern: [] };
        }
    }

    //console.log({ check: makeAssessmentTypesData() })

    const makeAssessmentGroupData = () => {
        // xaxis = ['Access', 'GTB', 'Stanbic', 'Zenith']
        if (Object.keys(banksGroup1Assessment).length) {
            const group1 = selectedBanks.map(bankIndex => {
                return banksGroup1Assessment[`${avgTotalFilterTerm.groups}Group1`][bankIndex];
            });

            const group2 = selectedBanks.map(bankIndex => {
                return banksGroup2Assessment[`${avgTotalFilterTerm.groups}Group2`][bankIndex];
            });

            const group3 = selectedBanks.map(bankIndex => {
                return banksGroup3Assessment[`${avgTotalFilterTerm.groups}Group3`][bankIndex];
            });

            return { group1, group2, group3 };
        } else {
            return { group1: [], group2: [], group3: [] };
        }
    }

    const makeAssessmentZoneData = () => {
        // xaxis = ['Access', 'GTB', 'Stanbic', 'Zenith']
        if (Object.keys(banksZoneSWAssessment).length) {
            const zoneNE = selectedBanks.map(bankIndex => {
                return banksZoneNEAssessment[`${avgTotalFilterTerm.zones}NorthEast`][bankIndex];
            });
            
            const zoneNC = selectedBanks.map(bankIndex => {
                return banksZoneNCAssessment[`${avgTotalFilterTerm.zones}NorthCentral`][bankIndex];
            });

            const zoneNW = selectedBanks.map(bankIndex => {
                return banksZoneNWAssessment[`${avgTotalFilterTerm.zones}NorthWest`][bankIndex];
            });

            const zoneSE = selectedBanks.map(bankIndex => {
                return banksZoneSEAssessment[`${avgTotalFilterTerm.zones}SouthEast`][bankIndex];
            });

            const zoneSS = selectedBanks.map(bankIndex => {
                return banksZoneSSAssessment[`${avgTotalFilterTerm.zones}SouthSouth`][bankIndex];
            });

            const zoneSW = selectedBanks.map(bankIndex => {
                return banksZoneSWAssessment[`${avgTotalFilterTerm.zones}SouthWest`][bankIndex];
            });

            return { zoneNE, zoneNC, zoneNW, zoneSE, zoneSS, zoneSW };
        }
        else {
            return { zoneNE: [], zoneNC: [], zoneNW: [], zoneSE: [], zoneSS: [], zoneSW: [] };
        }
    }

    /*console.log(makeAssessmentZoneData().zoneNE);
    console.log(makeAssessmentTypesData().branch);
    console.log(makeAssessmentGroupData().group1);
    console.log(makeOverallTotalData().totalAssessed);
    console.log(makeOverallAverageData().averageAssessed);*/


    const handleSelectBank = (e) => {
        let { value } = e.target;
        const { checked } = e.target;
        value= Number(value);

        if (checked) {
            setSelectedBanks([...selectedBanks, value].sort());
        } else {
            setSelectedBanks(selectedBanks.filter(item => item !== value).sort());
        }
    }


    

    const banks = ['Access', 'GTB', 'Stanbic', 'Zenith'];
    const xaxis = selectedBanks.map(bankIndex => banks[bankIndex]);
    
    const bankColors = ['dayoOrange', 'chartBlue', 'beforeHover', 'gtbOrange'];
    const bankChartColors= ['#20366f', '#de4a09', '#0032a3', '#e3000f'];
    const bankChartColors2 = ['#1899DE', '#1DB4DE', '#6AD8F7', '#168BAB'];
    const chartColors = selectedBanks.map(bankIndex => bankChartColors[bankIndex]);

    return (
        <div className="flex flex-col items-center p-2 py-6 pt-10 bg-slate-200">
            <div className="fixed top-14 flex justify-between w-4/5 py-2 shadow-lg bg-white z-10">
                <div className="flex flex-wrap item-center justify-evenly p-2 bg-inherit w-1/2 justify-self-start">
                    <p className="p-2">
                        Show only banks in:  <span className="font-bold">{selectedState.length? selectedState : 'the Country'}</span>
                    </p>
                    <select onChange={ (e)=> { handleLocationChange(e.target.value)}} className="px-3 py-2 rounded border border-sideBarBlue focus:outline-none text-slate-500">
                        <option value='All States'>All States</option>
                        {
                            states? states.map((item, i) => {
                                return (
                                    <option key={i} value={item.state}>{item.state}</option>
                                )
                            }) : <option>Loading...</option>
                        }
                    </select>
                </div>
                <div className="flex justify-evenly items-center w-full py-2 bg-white">
                    
                    <h1 className="font-bold text-xl text-dayoAshBlue">Select Banks to Compare</h1>
                {
                    ['Access', 'GTB', 'Stanbic', 'Zenith'].map((bank, index)=>{
                        const color= bankColors[index];
                        return (
                            <label key={index} className="flex items-center font-bold" >
                                <input onChange={handleSelectBank} key={index} type='checkbox' value={index} className={`form-checkbox rounded  outline focus:outline-${color} border-none text-dayoAshBlue mx-2 w-20 min-w-3/4 h-8`} id={`checkbox${index}`} />
                                {bank}
                            </label>
                        )
                    })
                }
                </div>
            </div>

            {/**First section: Total Assessment, Overall Average 
            <section className="h-max w-full shadow-md rounded flex flex-col my-6 bg-white">
                <div className="w-full bg-dayoAshBlue text-white font-bold py-3 px-2 ">
                    <h1>Overall on Banks</h1>
                </div>
                <div className="overflow-x-scroll h-max w-full flex">
                    <BarChart horizontal={true} percentage={false} data={{ name: 'Total Assessed', data: makeOverallTotalData().totalAssessed }} xaxis={ xaxis } colorCode={ chartColors } titleText={'Overall Total Assessment'} className="mx-2 p-2 bg-blue-50 min-w-max w-1/2" />
                    <BarChart horizontal={true} percentage={false} data={ { name: 'Overall Average Score', data: makeOverallAverageData().averageAssessed } } xaxis={ xaxis } colorCode={ chartColors } titleText={'Overall Average Score'} className="mx-2 p-2 bg-blue-50 min-w-max w-1/2" />
                </div>
            </section>*/}

            <div className="w-full bg-slate-200 text-white font-bold py-3 px-2 my-6">
            </div>

            {/**Second section: Assessment Types (Total Assessment/ Overall Average) */}
            <section className="h-max w-full shadow-md rounded flex flex-col my-6 bg-white">
                <div className="flex items-center justify-between w-full bg-dayoAshBlue text-white font-bold py-3 px-2 ">
                    <h1>Insights on Assessment Types (Total/ Average Score)</h1>
                    <select defaultValue={'total'} onChange={(e)=> { handleAvgTotalFilter('types', e.target.value) }} className="form-select text-slate-500 rounded font-bold">
                        <option value="total">Total Assessed</option>
                        <option value="average">Average Scores</option>
                    </select>
                </div>
                <div className="overflow-x-scroll h-max w-full py-4 flex">
                    {campaignID != 3?
                    <>
                        <BarChart horizontal={true} data={{ name: 'Total Assessed', data: makeAssessmentTypesData().branch }} xaxis={xaxis} colorCode={ chartColors } titleText={'Branch'} className="mx-2 p-2 bg-blue-50 min-w-max w-1/3" />
                        <BarChart horizontal={true} data={{ name: 'Total Assessed', data: makeAssessmentTypesData().FLE }} xaxis={xaxis} colorCode={ chartColors } titleText={'FLE'} className="mx-2 p-2 bg-blue-50 min-w-max w-1/3" />
                        <BarChart horizontal={true} data={{ name: 'Total Assessed', data: makeAssessmentTypesData().SEC }} xaxis={xaxis} colorCode={ chartColors } titleText={'Security'} className="mx-2 p-2 bg-blue-50 min-w-max w-1/3" />
                    </>
                    : 
                    <>
                        <BarChart horizontal={true} data={{ name: 'Total Assessed', data: makeAssessmentTypesData().intern  }} xaxis={xaxis} colorCode={ chartColors } titleText={'Intern'} className="mx-2 p-2 bg-blue-50 min-w-max w-1/2" />
                    </>
                    }
                    {/**/}
                </div>
                {/*<div className="overflow-x-scroll h-max w-full flex">
                    <GroupedBarChart data={makeTypeGroupedData()} xaxis={['Branch', 'FLE', 'Security']}  isData2={true} titleText={'Total Assessments'} className="mx-2 p-2 bg-blue-50 min-w-max w-1/2" />
                    <GroupedBarChart data={makeTypeGroupedData()} xaxis={['Branch', 'FLE', 'Security']}  isData2={false} titleText={'Average Score'} className="mx-2 p-2 bg-blue-50 min-w-max w-1/2" />
                    {/*<GroupedBarChart data1={selectedBanks.includes(0)? accessTypeData : {}} data2={selectedBanks.includes(1)? gtbTypeData : {}} data3={selectedBanks.includes(2)? stanbicTypeData : {}} data4={selectedBanks.includes(3)? zenithTypeData : {}} xaxis={['Branch', 'FLE', 'Security']}  isData2={true} titleText={'Total Assessments'} className="mx-2 p-2 bg-blue-50 min-w-max w-1/3" />
                    
                </div>*/}
            </section>

            {/*Third section: Groups (Total Assessment/ Overall Average) */}
            <section className="h-max w-full shadow-md rounded flex flex-col my-6 bg-white">
                <div className="flex items-center justify-between w-full bg-dayoAshBlue text-white font-bold py-3 px-2 ">
                    <h1>Insights on Groups</h1>
                    <select defaultValue={'total'} onChange={(e)=> { handleAvgTotalFilter('groups', e.target.value) }} className="form-select text-slate-500 rounded font-bold">
                        <option value="total">Total Assessed</option>
                        <option value="average">Average Scores</option>
                    </select>
                </div>
                <div className="overflow-x-scroll h-max w-full flex">
                    <BarChart horizontal={true} data={{ name: 'Total Assessed', data: makeAssessmentGroupData().group1}} xaxis={ xaxis } colorCode={ chartColors }  titleText={'Group 1 (LPA)'} className="mx-2 p-2 bg-blue-50 min-w-max w-1/3" />
                    <BarChart horizontal={true} data={{ name: 'Total Assessed', data: makeAssessmentGroupData().group2}} xaxis={ xaxis } colorCode={ chartColors }  titleText={'Group 2'} className="mx-2 p-2 bg-blue-50 min-w-max w-1/3" />
                    <BarChart horizontal={true} data={{ name: 'Total Assessed', data: makeAssessmentGroupData().group3}} xaxis={ xaxis } colorCode={ chartColors }  titleText={'Group 3'} className="mx-2 p-2 bg-blue-50 min-w-max w-1/3" />
                </div>
            </section>

            {/**Fourth section: Zones (Total Assessment/ Overall Average)*/}
            <section className="h-max w-full shadow-md rounded flex flex-col my-6 bg-white">
                <div className="flex items-center justify-between w-full bg-dayoAshBlue text-white font-bold py-3 px-2 ">
                    <h1>Insights on Zones</h1>
                    <select defaultValue={'total'} onChange={(e)=> { handleAvgTotalFilter('zones', e.target.value) }} className="form-select text-slate-500 rounded font-bold">
                        <option value="total">Total Assessed</option>
                        <option value="average">Average Scores</option>
                    </select>
                </div>

                <div className="overflow-x-scroll h-max w-full flex">
                    <BarChart horizontal={true} data={{name: 'total assessments', data: makeAssessmentZoneData().zoneNE }} colorCode={ chartColors } xaxis={ xaxis } titleText={'North East'} className="mx-2 p-2 bg-blue-50 min-w-max w-1/6" />
                    <BarChart horizontal={true} data={{name: 'total assessments', data: makeAssessmentZoneData().zoneNC }} colorCode={ chartColors } xaxis={ xaxis } titleText={'North Central'} className="mx-2 p-2 bg-blue-50 min-w-max w-1/6" />
                    <BarChart horizontal={true} data={{name: 'total assessments', data: makeAssessmentZoneData().zoneNW }} colorCode={ chartColors } xaxis={ xaxis } titleText={'North West'} className="mx-2 p-2 bg-blue-50 min-w-max w-1/6" />
                    <BarChart horizontal={true} data={{name: 'total assessments', data: makeAssessmentZoneData().zoneSE }} colorCode={ chartColors } xaxis={ xaxis } titleText={'South East'} className="mx-2 p-2 bg-blue-50 min-w-max w-1/6" />
                    <BarChart horizontal={true} data={{name: 'total assessments', data: makeAssessmentZoneData().zoneSS }} colorCode={ chartColors } xaxis={ xaxis } titleText={'South South'} className="mx-2 p-2 bg-blue-50 min-w-max w-1/6" />
                    <BarChart horizontal={true} data={{name: 'total assessments', data: makeAssessmentZoneData().zoneSW }} colorCode={ chartColors } xaxis={ xaxis } titleText={'South West'} className="mx-2 p-2 bg-blue-50 min-w-max w-1/6" />
                </div>
                    
            </section>

        </div>
    )
}

export default Insights;