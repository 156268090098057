import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://mysteryshopper.connectifie.com/api/v1.0'  }),
    endpoints: (builder) => ({
        fetchDates: builder.query({
            query: (campaignID) => `/all-date/${campaignID}`,
            
        })
    })
});

export const {
    useFetchDatesQuery
} = apiSlice;