
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	DashboardRounded,
	KeyboardArrowRightRounded,
} from '@material-ui/icons';
import Header from '../components/components/header/Header';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Summary from '../features/clientSummary/Summary';
import { loadScoresByStateThunk } from '../features/clientSummary/summarySlice';

export const baseURL = process.env.REACT_APP_BASE_URL;
//console.log({ baseURL });

const pages = [
	{
		id: 0,
		title: 'Summary',
		icon: <DashboardRounded />,
		activeIcon: <KeyboardArrowRightRounded />,
		page: <Summary />,
	},
];

const ClientDashboard = () => {
	const navigation = useNavigate();
	const dispatch = useDispatch();

	const coreStates = [ 'Lagos', 'Rivers', 'FCT' ];
	useEffect(()=>{
		dispatch(loadScoresByStateThunk('All'))
		.then(({meta})=> {
			if (meta.requestStatus === 'fulfilled') dispatch(loadScoresByStateThunk([ 'Lagos', 'Rivers', 'FCT' ]));
		})
		.catch(e=> e.message)
	}, [dispatch]);

	/*useEffect(()=>{
		const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : false;
		//const { token } = auth;
		if (!token) {
			navigation('/');
			toast.remove();
			toast('We have logged you out');
		} else{
			navigation('/client');
		}
	}, [navigation]);*/


    // get value from session storage, if null default to 0
	const [currentPageID, setCurrentPageID] = useState(
		sessionStorage.getItem('clientPage') ? Number(JSON.parse(sessionStorage.getItem('clientPage'))) : 0
	);

	const setSelectedPageID = (id) => {
		setCurrentPageID(id);
		// persist selected page in session storage
		sessionStorage.setItem('clientPage', id);
	};

	return (
		<div >
			<Header />
			<div className="flex h-full" style={{ height: '100%'}}>
				{/*<AdminSidebar className='h-full' pages={pages} setPage={setSelectedPageID} currentPage={currentPageID} />*/}
				<div style={{ flex: '4' }} className="overflow-x-hidden bg-slate-100 p-2">
					{/* map through pages and only render page that matches id */}
					{pages.map((page) =>
						page.id === currentPageID ? (
							<div key={page.title} className="max-w-full">
								{page.page}
							</div>
						) : null
					)}
				</div>
			</div>
		</div>
	);
};

export default ClientDashboard;