const ProgressBar = ({ progressPercentage }) => {
    const progress = progressPercentage == 'Infinity'? 0 : progressPercentage > 100? 100 : progressPercentage;
    const progressColor= progress < 45 ? 'bg-red-600' : progress < 60? 'bg-orange-400' : 'bg-sideBarBlue';
    return (
        <div className="h-5 my-2 w-full bg-gray-300 rounded-full ">
            {
                progress? 
                <div 
                    className={`flex items-center justify-center rounded-full h-full text-white text-center ${progressColor}`} 
                    style={{ width: `${progress}%`}}
                >
                    <p className="font-bold">{progress}%</p>
                </div> : <div></div>
            }
        </div>
    )
};

export default ProgressBar;