import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createCampaign, getAllCampaigns, getAllTasks, getDayTasks, bulkAssignTasks, bulkAssignOverwriteTasks, getOutstandingTasks, getAdminActionHistory, getCampaignAssessmentTypes, getCampaignBanks, getAllBanks, getAllAssessmentTypes, addBank } from "./corporateAPI";

const initialState = {};
const reducers = {};

export const createCampaignThunk = createAsyncThunk('corporate/createCampaign', async (formData, {getState})=>{
    const state = getState();
    const { token } = state.auth;
    if (token) {
    const payload = await createCampaign(formData, token);
    return { campaignCreateSuccess: payload.success };
    }
});

export const getAllCampaignsThunk = createAsyncThunk('corporate/loadCampaigns', async (_, {getState})=>{
    const state = getState();
    const { token } = state.auth;
    if(token) {
        const payload = await getAllCampaigns(token);
        return { allCampaigns: payload };
    }
});

export const getAllTasksThunk = createAsyncThunk('corporate/loadTasks', async (_, {getState})=>{
    const state = getState();
    const { token } = state.auth;
    const { campaignID } = getState().dataSummary;
    if(token) {
        const payload = await getAllTasks(token, campaignID);
        return { allTasks: payload };
    }
});

export const getOutstandingTasksThunk = createAsyncThunk('corporate/loadOutstandingTasks', async (_, {getState})=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    if(token) {
        const payload = await getOutstandingTasks(token, campaignID);
        return { outstandingTasks: payload };
    }
})

export const getCampaignAssessmentTypesThunk = createAsyncThunk('corporate/loadCampaignAssessmentTypes', async (_, {getState})=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    if(token) {
        const payload = await getCampaignAssessmentTypes(token, campaignID);
        //console.log({ campaignTypes: payload })
        return { campaignTypes: payload };
    }
})

export const getAllAssessmentTypesThunk = createAsyncThunk('corporate/loadAllAssessmentTypes', async (_, {getState})=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    if(token) {
        const payload = await getAllAssessmentTypes(token, campaignID);
        console.log({ allTypes: payload })
        return { allTypes: payload };
    }
})

export const getCampaignBanksThunk = createAsyncThunk('corporate/loadCampaignBanks', async (_, {getState})=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    if(token) {
        const payload = await getCampaignBanks(token, campaignID);
        console.log({ campaignBanks: payload })
        return { campaignBanks: payload };
    }
})

export const getAllBanksThunk = createAsyncThunk('corporate/loadAllBanks', async (_, {getState})=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    if(token) {
        const payload = await getAllBanks(token, campaignID);
        return { allBanks: payload };
    }
})

export const getAdminActionHistoryThunk = createAsyncThunk('corporate/loadAdminHistory', async (_, {getState})=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    if(token) {
        const payload = await getAdminActionHistory(token, campaignID);
        return { adminActionHistory: payload };
    }
})

export const getDayTasksThunk = createAsyncThunk('corporate/loadTasks', async (date, {getState})=>{
    const state = getState();
    const { token } = state.auth;
    const { campaignID } = getState().dataSummary;
    if(token) {
        const payload = await getDayTasks(token, date, campaignID);
        return { dayTasks: payload };
    }
});

export const addBankThunk = createAsyncThunk('corporate/addBank', async (formData, {getState})=>{
    const { token } = getState().auth;
    if (token) {
        const payload = await addBank(formData, token);
        return payload;
    }
});

export const bulkUploadTaskThunk = createAsyncThunk('corporate/bulkUploadTask', async (formData, {getState})=>{
    const state = getState();
    const { token } = state.auth;
    if (token) {
        const payload = await bulkAssignTasks(formData, token);
        return { bulkUploadSuccess: payload.success };
    }
});

export const bulkUploadOverwriteTaskThunk = createAsyncThunk('corporate/bulkUploadOverwriteTask', async (formData, {getState})=>{
    const state = getState();
    const { token } = state.auth;
    if (token) {
        const payload = await bulkAssignOverwriteTasks(formData, token);
        return { bulkUploadSuccess: payload.success };
    }
});

const corporateSlice = createSlice({
    name: 'corporate',
    initialState,
    reducers,
    extraReducers: (builder)=>{
        builder
            .addCase(createCampaignThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(getAllCampaignsThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(getAllTasksThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(getOutstandingTasksThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(getAdminActionHistoryThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(getCampaignAssessmentTypesThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(getCampaignBanksThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(getAllBanksThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(getAllAssessmentTypesThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
    }
});

export default corporateSlice.reducer;