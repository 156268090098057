import axios from "axios";
import toast from "react-hot-toast";
import { convertDate } from "../../utilities/utilFunctions";
//const campaignID = Number(sessionStorage.getItem('campaignID')) || 3;

const baseURL = 'https://mysteryshopper.connectifie.com/api/v1.0';
const baseURL2 = 'https://mysteryshopper.connectifie.com/api/v1.1';

export const createCampaign = async (form, token) => {
    try{        
        toast.loading("Creating Campaign, Please Wait ...");
        const { data } = await axios.post(`${baseURL}/campaign/create`, form, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        toast.remove(); toast.success('Campaign Submitted');
        return data;
    } catch (error) {
        toast.remove()
        toast.error(error.message);
    }
}

export const getAllCampaigns = async (token) => {
    const { data } = await axios.get(`${baseURL}/campaign/all`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    const result= data.map(obj=> {
        return { ...obj, start_date: convertDate(obj.start_date), end_date: convertDate(obj.end_date) }
    })
    return result;
}

export const getAllBanks = async (token, campaignID) => {
    const { data } = await axios.get(`${baseURL2}/bank/all/2`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

    console.log({ allBAnks: data})
    return data;
}

export const getAllAssessmentTypes = async (token, campaignID) => {
    const { data } = await axios.get(`${baseURL}/assessment/all/2`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    const { data: intern } = await axios.get(`${baseURL}/assessment/all/3`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    console.log({ alltypes: [...data, ...intern ], sorted: [...new Set(data)]})
    return [...data, ...intern ];
}


export const getCampaignBanks = async (token, campaignID=3) => {
    const { data } = await axios.get(`${baseURL2}/bank/by-campaign/${campaignID}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

    //console.log({ campaignBanks: data})
    return data;
}

export const getCampaignAssessmentTypes = async (token, campaignID=3) => {
    const { data } = await axios.get(`${baseURL}/assessment/by-campaign/${campaignID}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

    //console.log({ campaignAssessmentTypes: data, sorted: [...new Set(data)]})
    return data;
}

export const getAllTasks = async (token, campaignID=3) => {
    const { data } = await axios.get(`${baseURL}/campaign/assessment/scope/list?campaign_id=${campaignID}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return data.reverse();
}

export const getDayTasks = async (token, date, campaignID=3) => {
    const { data } = await axios.get(`${baseURL}/campaign/assessment/scope/list/${date}?campaign_id=${campaignID}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

    //console.log({ dailyTasksAPI : data })
    return data;
}

export const getOutstandingTasks = async (token, campaignID=3) => {
    try {
            const { data } = await axios.get(`${baseURL}/scope/undone-assessment`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        
            //console.log({ outstandingTasks : data })
            const formatData = data.map(array=> array[0]);
            return formatData;
        } catch(e) {
            console.log(e.message)
        }
}

export const getAdminActionHistory = async (token, campaignID=3) => {
    try {
            const { data } = await axios.get(`${baseURL}/scope_history/all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        
            const formatData = data.reverse().map(item=> {
                return {
                    ...item,
                    date: item.date_created.split("GMT")[0],
                }
            })

            //console.log({ adminHistory : formatData })

            return formatData;
        } catch(e) {
            console.log(e.message)
        }
}


export const addBank = async (form, token) => {
    try{
        
        toast.remove(); toast.loading("Adding Bank, Please Wait ...");
        const { data } = await axios.post(`${baseURL2}/bank/create`, form, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        if (data.success) {
            toast.remove(); toast.success("Bank Added Successfully");
        } else {
            toast.remove(); toast.error("Error Adding Bank");
        }
    } catch (error) {
        toast.remove()
        toast.error(error.message);
        console.log({error});
    }
}
    

export const bulkAssignTasks = async (formData, token) => {
    try{
        const {data: uploadResponse } = await axios.post(`${baseURL}/scope/bulk-upload`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })

        if (uploadResponse.success) {
            toast.success(uploadResponse.msg);
            return uploadResponse;
        } else {
            toast.error(uploadResponse.msg);
        }
    } catch(e) {
        toast.error(e.message);
        console.log(e.message)
    }
}

export const bulkAssignOverwriteTasks = async (formData, token) => {
    try {
        toast.loading("Overwriting Tasks, Please Wait ...");
        const {data: deleteResponse} = await axios.delete(`${baseURL}/scope/delete-all/${formData.get('campaign_id')}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        if (deleteResponse.success) {
            //sconsole.log({ formData });
            const {data: uploadResponse } = await axios.post(`${baseURL}/scope/bulk-upload`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
    
            if (uploadResponse.success) {
                toast.remove();
                toast.success(uploadResponse.msg);
                return uploadResponse;
            } else {
                toast.remove();
                toast.error(uploadResponse.msg);
            }
        }
    } catch(e) {
        toast.error(e.message);
        console.log(e.message)
    }
}

export const editBank = async (form, token) => {
    try{
        
        toast.remove(); toast.loading("Adding Bank, Please Wait ...");
        const { data } = await axios.post(`${baseURL}/bank/create`, form, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        if (data.success) {
            toast.remove(); toast.success("Bank Added Successfully");
        } else {
            toast.remove(); toast.error("Error Adding Bank");
        }
    } catch (error) {
        toast.remove()
        toast.error(error.message);
        console.log({error});
    }
}