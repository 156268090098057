import { useEffect, useState } from 'react';
import './Summary.css';
import {
    DashboardRounded,
    KeyboardArrowUpRounded,
    KeyboardArrowDownRounded,
    SecurityRounded,
    BusinessRounded,
    GroupRounded,
    PersonRounded,

} from '@material-ui/icons';
import GroupedBarChart from '../../components/components/charts/BarChartGrouped';
import DonutChart from '../../components/components/charts/DonutChart';
import LineColumn from '../../components/components/charts/LineColumn';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { loadScoresByStateThunk } from './summarySlice';

function Summary(){
    /**MOCK DATAs */

    const donutChartData= [80, 70, 76] // Lagos, Rivers, FCT

    const groupBarChartData= [ // FLE, SECURITY, BRANCH
        {
            name: 'Access Bank', // Type Average Score per bank compared : 3 banks in this case
            data: [80, 70] // Numbered according to xAxis: 2 categories - campaign 1 & 2 in this case
        },
        {
            name: 'Competitor bank', // Type Average Score per bank compared : 3 banks in this case
            data: [83, 78] // Numbered according to xAxis: 2 categories - campaign 1 & 2 in this case
        },
    ]

/* END OF MOCK DATAs */

    
    //const { campaignID } = useSelector(state => state.dataSummary);
    

    const dispatch = useDispatch();

    const [campaignTypes, setCampaignTypes] = useState([]);
    useEffect(()=> {
        axios.get(`${process.env.REACT_APP_BASE_URL}/assessment/by-campaign/${2}`)
        .then(({data})=> {
            setCampaignTypes(data);
        })
        .catch(err => {
            console.error(err.message);
        })/*.finally(()=> {
            console.log('finally');
        });*/
    }, []);

    //console.log({ campaignTypes });
    
    const { clientByState } = useSelector(state => state.summary);
    const [stateData, setStateData] = useState({});
    const [selectState, setSelectState] = useState('All');

    useEffect(() => { // check for cached data or call API to fetch
        
        clientByState && clientByState[selectState]? setStateData(clientByState[selectState])
        :
        dispatch(loadScoresByStateThunk(selectState))
        .then(({meta})=> {
            if(meta.requestStatus === 'fulfilled'){
                //console.log(clientByState[selectState])
                setStateData(clientByState[selectState]);
            }
        }).catch(e=> console.log(e.message ));
    }, [ clientByState, dispatch, selectState]);


    function makeCurrentSummaryData(){
        const fleValue=  stateData && stateData.current && stateData.current.fleScore;
        const securityValue=  stateData && stateData.current && stateData.current.securityScore;
        const branchValue=  stateData && stateData.current && stateData.current.branchScore;
        const internValue= stateData && stateData.current && stateData.current.internScore;
        const bankScore= stateData && stateData.current && stateData.current.bankScore;
        const totalAssessment= stateData && stateData.current && stateData.current.totalAssessment;
        const totalFle = stateData && stateData.current && stateData.current.totalFle;
        const totalSecurity = stateData && stateData.current && stateData.current.totalSecurity;
        const totalBranch = stateData && stateData.current && stateData.current.totalBranch;
        const totalIntern = stateData && stateData.current && stateData.current.totalIntern;


        return {
            fleValue,
            securityValue,
            branchValue,
            internValue,
            bankScore,
            totalAssessment,
            totalFle,
            totalSecurity,
            totalBranch,
            totalIntern
        }
    }

    function makePreviousSummaryData(){
        const fleValue=  stateData && stateData.current && stateData.previous.fleScore;
        const securityValue=  stateData && stateData.current && stateData.previous.securityScore;
        const branchValue=  stateData && stateData.current && stateData.previous.branchScore;
        const internValue= stateData && stateData.current && stateData.previous.internScore;
        const bankScore= stateData && stateData.current && stateData.previous.bankScore;
        const totalAssessment= stateData && stateData.current && stateData.previous.totalAssessment;


        return {
            fleValue,
            securityValue,
            branchValue,
            internValue,
            bankScore,
            totalAssessment,
        }
    }

    function compareSummaryData(){
        const fleDifference = stateData && stateData.current && stateData.current.fleScore - stateData.previous.fleScore;
        const securityDifference = stateData && stateData.current && stateData.current.securityScore - stateData.previous.securityScore;
        const branchDifference = stateData && stateData.current && stateData.current.branchScore - stateData.previous.branchScore;
        const internDifference = stateData && stateData.current && stateData.current.internScore - stateData.previous.internScore;
        const bankScoreDifference = stateData && stateData.current && stateData.current.bankScore - stateData.previous.bankScore;
        const totalAssessmentDifference = stateData && stateData.current && stateData.current.totalAssessment - stateData.previous.totalAssessment;

        return {
            fleDifference,
            securityDifference,
            branchDifference,
            internDifference,
            bankScoreDifference,
            totalAssessmentDifference,
        }
    }

    function formatSummaryData(campaignTypesArray= campaignTypes || [{type: 'fle'}, {type: 'security'}, {type: 'branch'}, {type: 'intern'}]){
        console.log({campaignTypesArray});
        const types = campaignTypesArray.map(type=> type.type.toLowerCase());
        
        const assessmentTypeSummaryCards= [];
        if (types.includes('fle')){
            assessmentTypeSummaryCards.push({
                title: "FRONT LINE (FLE)", 
                titleIcon: <GroupRounded />, 
                value: makeCurrentSummaryData().fleValue, 
                totalValue: makeCurrentSummaryData().totalFle,
                smallValue: makePreviousSummaryData().fleValue, 
                smallValuePercentage: (compareSummaryData().fleDifference * 100)/makePreviousSummaryData().fleValue, 
                smallValueDirection: makeCurrentSummaryData().fleValue > makePreviousSummaryData().fleValue? 'positive' : makeCurrentSummaryData().fleValue < makePreviousSummaryData().fleValue? 'negative' : 'neutral', 
                smallValueIcon: null});
        }
        if (types.includes('security')){
            assessmentTypeSummaryCards.push({
                title: "SECURITY", 
                titleIcon: <SecurityRounded />, 
                value: makeCurrentSummaryData().securityValue, 
                totalValue: makeCurrentSummaryData().totalSecurity,
                smallValue: makePreviousSummaryData().securityValue, 
                smallValuePercentage: (compareSummaryData().securityDifference * 100)/makePreviousSummaryData().securityValue, 
                smallValueDirection: makeCurrentSummaryData().securityValue > makePreviousSummaryData().securityValue? 'positive' : makeCurrentSummaryData().securityValue < makePreviousSummaryData().securityValue? 'negative' : 'neutral', 
                smallValueIcon: null});
        }
        if (types.includes('branch')){
            assessmentTypeSummaryCards.push({
                title: "BRANCH", 
                titleIcon: <BusinessRounded />, 
                value: makeCurrentSummaryData().branchValue, 
                totalValue: makeCurrentSummaryData().totalBranch,
                smallValue: makePreviousSummaryData().branchValue, 
                smallValuePercentage: (compareSummaryData().branchDifference * 100)/makePreviousSummaryData().branchValue, 
                smallValueDirection: makeCurrentSummaryData().branchValue > makePreviousSummaryData().branchValue? 'positive' : makeCurrentSummaryData().branchValue < makePreviousSummaryData().branchValue? 'negative' : 'neutral', 
                smallValueIcon: null});
        }
        if (types.includes('intern')){
            assessmentTypeSummaryCards.push({
                title: "INTERN", 
                titleIcon: <PersonRounded />, 
                value: makeCurrentSummaryData().internValue, 
                totalValue: makeCurrentSummaryData().totalIntern,
                smallValue: makePreviousSummaryData().internValue, 
                smallValuePercentage: (compareSummaryData().internDifference * 100)/makePreviousSummaryData().internValue, 
                smallValueDirection: makeCurrentSummaryData().internValue > makePreviousSummaryData().internValue? 'positive' : makeCurrentSummaryData().internValue < makePreviousSummaryData().internValue? 'negative' : 'neutral', 
                smallValueIcon: null});
        }

        //console.log(assessmentTypeSummaryCards);

        return assessmentTypeSummaryCards;

    }

    function makeStatesByAssessmentTypeData(states=["Lagos", "Rivers", "FCT"], assessmentTypes= campaignTypes){
        // FLE - [Lagos, Rivers, FCT ]
        // Security - [Lagos, Rivers, FCT]
        const data= {};

        assessmentTypes.forEach((type)=> {
            
            states.forEach((state, i)=> {
                data[`${type.type.toLowerCase()}${state}`] = clientByState && clientByState[state] && clientByState[state].current[`${type.type.toLowerCase()}Score`];
            })
            //console.log({data})
        })
        return data;
    }

    //makeStatesByAssessmentTypeData(["Lagos", "Rivers", "FCT"])

    function donutChartDataFormatter(){
        if (clientByState) {
            const resultObj = {
            fle: [
                makeStatesByAssessmentTypeData().fleLagos || 0,
                makeStatesByAssessmentTypeData().fleRivers || 0,
                makeStatesByAssessmentTypeData().fleFCT || 0
            ],
            security: [
                makeStatesByAssessmentTypeData().securityLagos || 0,
                makeStatesByAssessmentTypeData().securityRivers || 0,
                makeStatesByAssessmentTypeData().securityFCT || 0
            ],
            branch: [
                makeStatesByAssessmentTypeData().branchLagos || 0,
                makeStatesByAssessmentTypeData().branchRivers || 0,
                makeStatesByAssessmentTypeData().branchFCT || 0
            ],
            }

            //console.log({ resultObj })

            return resultObj
        };
    }

    function comboChartDataFormatter(){
        if (clientByState) {
            const resultArray = [
            {
                assessmentType: 'FLE',
                chartType: "bar",
                data: [
                    makeStatesByAssessmentTypeData().fleLagos || 0,
                    makeStatesByAssessmentTypeData().fleRivers || 0,
                    makeStatesByAssessmentTypeData().fleFCT || 0
                ],
            }, 
            {
                assessmentType: "SECURITY",
                chartType: "bar",
                data: [
                    makeStatesByAssessmentTypeData().securityLagos || 0,
                    makeStatesByAssessmentTypeData().securityRivers || 0,
                    makeStatesByAssessmentTypeData().securityFCT || 0
                ],
            },
            {
                assessmentType: "BRANCH",
                chartType: "bar",
                data: [
                    makeStatesByAssessmentTypeData().branchLagos || 0,
                    makeStatesByAssessmentTypeData().branchRivers || 0,
                    makeStatesByAssessmentTypeData().branchFCT || 0
                ],
            },
            {
                assessmentType: "BM-SCORE",
                chartType: "line",
                data: [
                    ((makeStatesByAssessmentTypeData().branchLagos + makeStatesByAssessmentTypeData().fleLagos + makeStatesByAssessmentTypeData().securityLagos)/3).toFixed(2),
                    ((makeStatesByAssessmentTypeData().branchRivers + makeStatesByAssessmentTypeData().fleRivers + makeStatesByAssessmentTypeData().securityRivers)/3).toFixed(2),
                    ((makeStatesByAssessmentTypeData().branchFCT + makeStatesByAssessmentTypeData().fleFCT + makeStatesByAssessmentTypeData().securityFCT)/3).toFixed(2),
                ],
            }
            ];

            //console.log({ resultArray })


            return resultArray;
        }
    }



    const legendColors= ['#005a9a', '#00B8FF', '#0078cd' ];

    return (
        <div>
            <small className='text-sm text-slate-400 capitalize'>{selectState} Client Summary</small>

            <section className='w-full flex flex-col items-center'>
                <div className='w-full'>
                    <div className='w-3/4 mx-auto rounded-lg bg-white'>
                        <button onClick={(e)=> {  setSelectState(e.target.value)}} value='All' className={`m-0 w-1/4 rounded-l-lg ${selectState === 'All'? 'bg-dayoAshBlue text-slate-100' : 'text-slate-600'}`}>Nation-wide</button>
                        <button onClick={(e)=> {  setSelectState(e.target.value)}} value='Lagos' className={`m-0 w-1/4 border-x border-slate-200 ${selectState === 'Lagos'? 'bg-dayoAshBlue text-slate-100' : 'text-slate-600'}`}>Lagos</button>
                        <button onClick={(e)=> {  setSelectState(e.target.value)}} value='Rivers' className={`m-0 w-1/4 border-x border-slate-200 ${selectState === 'Rivers'? 'bg-dayoAshBlue text-slate-100' : 'text-slate-600'}`}>Rivers</button>
                        <button onClick={(e)=> {  setSelectState(e.target.value)}} value='FCT' className={`m-0 w-1/4 rounded-r-lg ${selectState === 'FCT'? 'bg-dayoAshBlue text-slate-100' : 'text-slate-600'}`}>FCT</button>
                    </div>
                </div>
                <div className='w-full flex items-center justify-stretch'>
                    <div className={'blue-gradient-card shadow-md flex flex-col w-1/6 h-max px-5 py-2 m-2 rounded-md bg-blue-600 transition-opacity'}>
                        <div className={`flex items-center text-slate-100 `}>
                            <DashboardRounded />
                            <p className="w-full mx-2 text-start text-md font-bold text-white">TOTAL ASSESSMENTS</p>
                        </div>
                        <div className='w-full'>
                            <p className="w-full text-center text-2xl text-slate-100">{makeCurrentSummaryData().totalAssessment}</p>
                        </div>
                        <div className='flex flex-col w-max float-right'>
                            <small className="text-sm text-white">Previous Campaign</small>
                            
                            
                            <div className={`flex items-center text-xs ${compareSummaryData().totalAssessmentDifference > 0? 'text-green-200' : compareSummaryData().totalAssessmentDifference < 0? 'text-red-300' : 'text-slate-300'}`}>
                                <small className="text-sm text-white"> {makePreviousSummaryData().totalAssessment} </small>
                                <small> {compareSummaryData().totalAssessmentDifference >= 0? "+" : "-"}{(makeCurrentSummaryData().totalAssessment - makePreviousSummaryData().totalAssessment) * 100/makePreviousSummaryData().totalAssessment}%  </small>
                                { compareSummaryData().totalAssessmentDifference > 0? <KeyboardArrowUpRounded /> : compareSummaryData().totalAssessmentDifference < 0? <KeyboardArrowDownRounded /> : null}
                            </div>
                        </div>
                    </div>

                {
                    // Dynamic Assessment type rendering function: renders the assessment type cards
                    formatSummaryData().map((type, index)=> {
                        return (
                            <div key={index} className={'gray-gradient-card shadow-md flex flex-col w-1/4 h-max px-5 py-2 m-2 rounded-md bg-white'}>
                                <div className={`flex items-center text-slate-600 `}>
                                    {type.titleIcon}
                                    <p className="w-full mx-2 text-start text-md font-bold text-slate-500">{type.title}</p>
                                </div>
                                <div className='w-full'>
                                    <p className="w-full text-center text-2xl text-slate-600">{type.value} <span className='text-sm'>{type.totalValue? `(${type.totalValue} assessments)`: ''}</span></p>
                                </div>
                                <div className='flex flex-col w-max float-right'>
                                    <small className="text-sm text-slate-400">Previous Campaign</small>
                                    
                                    <div className={`flex items-center ${type.smallValueDirection === 'positive'? 'text-green-500' : type.smallValueDirection === 'negative'? 'text-red-500' : 'text-slate-300'}`}>
                                        {type.smallValueIcon? type.smallValueIcon : null}
                                        <small className="text-sm text-slate-500"> {type.smallValue} </small>
                                        <small className='text-xs'>{type.smallValueDirection === 'positive'? "+" : type.smallValueDirection === 'negative'? "-" : "+"}{type.smallValuePercentage}%</small>
                                        { type.smallValueDirection === 'positive'? <KeyboardArrowUpRounded /> : type.smallValueDirection === 'negative'? <KeyboardArrowDownRounded /> : null}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                </div>
            </section>

            <section className='flex items-center w-full float-right my-1'>
                <div className={'blue-gradient-card flex flex-col w-1/5 h-80 px-5 py-2 m-2 rounded-md bg-blue-600 hover:scale-1'}>
                    <div className={`flex items-center text-slate-100 `}>
                        <p className="w-full mx-2 text-start text-md font-bold text-white">BANK SCORE</p>
                    </div>
                    <div className='flex items-center w-full my-10 h-1/2'>
                        <p className="w-full text-center text-6xl text-slate-100">{makeCurrentSummaryData().bankScore}</p>
                    </div>
                    <div className='flex flex-col w-max float-right'>
                        <small className="text-sm text-white">Previous Campaign</small>
                        
                        <div className={`flex items-center text-xs ${compareSummaryData().bankScoreDifference > 0? 'text-green-200' : compareSummaryData().bankScoreDifference < 0? 'text-red-200' : 'text-slate-300'}`}>
                            <small className="text-sm text-slate-100"> {makePreviousSummaryData().bankScore} </small>
                            <small> {compareSummaryData().bankScoreDifference >= 0? "+" : "-"}{(makeCurrentSummaryData().bankScore - makePreviousSummaryData().bankScore) * 100/makePreviousSummaryData().bankScore}%  </small>
                            { compareSummaryData().bankScoreDifference > 0? <KeyboardArrowUpRounded /> : compareSummaryData().bankScoreDifference < 0? <KeyboardArrowDownRounded /> : null}
                        </div>
                    </div>
                </div>

                <div className='flex flex-col items-center w-2/5 m-2 rounded-lg bg-white'>
                    <div className='flex items-center w-full py-12 m-2 mr-1 rounded-lg bg-white'>
                        <div className='w-1/3' >
                            {/**Chart */}
                            <DonutChart data={clientByState && donutChartDataFormatter().fle[0]? donutChartDataFormatter().fle : [1,1,1]} titleText={'FLE'} />
                        </div>
                        <div className='w-1/3' >
                            {/**Chart */}
                            <DonutChart data={clientByState && donutChartDataFormatter().security[0]? donutChartDataFormatter().security : [1,1,1]} titleText={'Security'} />
                        </div>
                        <div className='w-1/3' >
                            {/**Chart */}
                            <DonutChart data={clientByState && donutChartDataFormatter().branch[0]? donutChartDataFormatter().branch: [1,1,1]} titleText={'Branch'} />
                        </div>
                    </div>
                    
                    <div className='flex justify-evenly items-center my-2'>
                            {
                                // selectGroup
                                ['Lagos', 'Rivers', 'FCT'].map((state,index) => {
                                    return (
                                        <div key={index} className='flex items-center'>
                                            <div className='rounded-full h-3 w-3 mx-2' style={{backgroundColor: legendColors[index]}}></div>
                                            <p className='text-sm text-slate-500'>{state}</p>
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>

                <div className='w-3/5 my-2 flex justify-center items-center'>
                    <div className='w-full rounded bg-white'>
                        {/**Combo Chart */}
                        <LineColumn data={comboChartDataFormatter() || [{name: 'BM-SCORE', type: 'line', data: [0, 0, 0], }]} xaxis={ ['LAGOS', 'RIVERS', 'FCT']} />
                    </div>
                </div>
            </section>

            <section className='w-full flex items-center'>
                <div className='w-1/3 mx-1'>
                    <GroupedBarChart data={groupBarChartData} xaxis={['CampaignTitle', 'CampaignTitle']} titleText={"Front Line Employees"} />
                </div>
                <div className='w-1/3 mx-1'>
                    <GroupedBarChart data={groupBarChartData} xaxis={['CampaignTitle', 'CampaignTitle']} titleText={"Security Employees"} />
                </div>
                <div className='w-1/3 mx-1'>
                    <GroupedBarChart data={groupBarChartData} xaxis={['CampaignTitle', 'CampaignTitle']} titleText={"Branch"} />
                </div>

            </section>
            
        </div>
    )
}

export default Summary;