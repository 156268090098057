
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";


const EvaluatorSingleUploadForm= ({onSubmitForm}) => {
    
    const { register, handleSubmit, errors } = useForm();
    const { states, LGA= [] } = useSelector(state => state.dataSummary);

    const [selectedState, setSelectedState] = useState('');
    const [filteredLGA, setFilteredLGA] = useState(LGA? LGA : []);

    useEffect(()=>{
        if(LGA){
            const filtered = LGA.filter(lga=> lga.state.toLowerCase().includes(selectedState.toLowerCase()));
            setFilteredLGA(filtered);
        }
        else {
            setFilteredLGA([]);
        } 
    }, [LGA, selectedState])

    const selectZone = filteredLGA.length && filteredLGA[0].zone == 'SE'? 'South East' : filteredLGA.length && filteredLGA[0].zone == 'SW'? 'South West' : filteredLGA.length && filteredLGA[0].zone == 'SS'? 'South South' : filteredLGA.length && filteredLGA[0].zone == 'NE'? 'North East' : filteredLGA.length && filteredLGA[0].zone == 'NC'? 'North Central' : filteredLGA.length && filteredLGA[0].zone == 'NW'? 'North West' : '';
    const formattedStates = states? states.map(state=> state.state.split(" ").length < 3? state.state.split(" ")[0].toUpperCase() : (state.state.split(" ")[0] + " " + state.state.split(" ")[1]).toUpperCase()) : []
    //console.log(states? states.map(state=> state.state.split(" ").length < 3? state.state.split(" ")[0].toUpperCase() : (state.state.split(" ")[0] + " " + state.state.split(" ")[1]).toUpperCase()) : [])

    return (
        <form className={` flex flex-col justify-center items-start w-full min-w-max lg:w-1/2 p-4 rounded border border-slate-300 bg-slate-50 shadow-md `} onSubmit={handleSubmit(onSubmitForm)}>
            
            <h1 className="text-slate-600 font-bold m-2 ml-0">Add New Evaluator</h1>

            <div className='w-1/2 flex'>
                <label className="flex flex-col justify-center w-max font-bold text-slate-500 my-2 px-2">
                    Access Code
                    <input 
                        type='text' 
                        {...register("evaluator_access_code")} 
                        id='evaluator_access_code'
                        className="text-dayoAshBlue focus:outline-none rounded my-2 mr-2 p-3 rounded bg-slate-100 border border-slate-500" 
                        required
                    />
                </label>
                <label className="flex flex-col justify-center w-max font-bold text-slate-500 my-2 px-2">
                    Full Name
                    <input 
                        type='text' 
                        {...register("evaluator_name")} 
                        id='evaluator_name'
                        className="text-dayoAshBlue focus:outline-none rounded my-2 mr-2 p-3 rounded bg-slate-100 border border-slate-500" 
                        required
                    />
                </label>
                <label className="flex flex-col justify-center w-max font-bold text-slate-500 my-2 px-2">
                    Phone Number
                    <input 
                        type='number' 
                        {...register("phone")} 
                        id='phone'
                        className="text-dayoAshBlue focus:outline-none rounded my-2 mr-2 p-3 rounded bg-slate-100 border border-slate-500" 
                        required
                    />
                </label>   
            </div>

            <div className='w-full flex'>
                <label className="flex flex-col justify-center w-full font-bold text-slate-500 my-2 px-2">
                    Email Address
                    <input 
                        type='email' 
                        {...register("evaluator_email")} 
                        id='evaluator_email'
                        className="text-dayoAshBlue focus:outline-none rounded my-2 mr-2 p-3 rounded bg-slate-100 border border-slate-500" 
                        placeholder="example@email.com"
                        required
                    />
                </label>
                
                <label className="flex flex-col justify-center w-full font-bold text-slate-500 my-2 px-2">
                    Home Address
                    <input 
                        type='text' 
                        {...register("evaluator_address")} 
                        id='evaluator_address'
                        className="text-dayoAshBlue focus:outline-none rounded my-2 mr-2 p-3 rounded bg-slate-100 border border-slate-500" 
                        required
                    />
                </label>

            </div>

            <div className='flex w-full'>
                <label className="flex flex-col justify-center w-max font-bold text-slate-500 my-2 px-2">
                    State
                    <select
                        {...register("evaluator_state")}
                        onChange={(e)=> { const stateString= `${e.target.value} State`; setSelectedState(stateString); }}
                        id='evaluator_state'
                        className="text-dayoAshBlue focus:outline-none rounded my-2 mr-2 p-3 rounded bg-slate-100 border border-slate-500 uppercase"
                        required
                    >
                        <option value='' disabled>Select State</option>
                        {
                            states? states.map((state, index) => (
                                <option key={index} value={formattedStates[index]}>{state.state}</option>
                            )) : <option className="italic">loading...</option>
                        }
                    </select>
                </label>
                <label className="flex flex-col justify-center w-max font-bold text-slate-500 my-2 px-2">
                    LGA
                    <select 
                        {...register("evaluator_lga")} 
                        id='evaluator_lga'
                        className="text-dayoAshBlue focus:outline-none rounded my-2 mr-2 p-3 rounded bg-slate-100 border border-slate-500" 
                        required
                    >
                        <option value='' disabled>Select LGA</option>
                        {
                            LGA? filteredLGA.map((lga, index) => (
                                <option key={index} value={lga.lga}>{lga.lga}</option>
                            )) : <option className="italic">loading...</option>
                        }
                    </select>
                </label>
                <label className="flex flex-col justify-center w-max font-bold text-slate-500 my-2 px-2">
                    Zone
                    <input 
                        type='text' 
                        {...register("evaluator_zone")} 
                        id='evaluator_zone'
                        value={filteredLGA.length? filteredLGA[0].zone : 'Other'}
                        className="text-dayoAshBlue focus:outline-none rounded my-2 mr-2 p-3 rounded bg-slate-100 border border-slate-500" 
                        required
                    />
                </label>
            </div>

            <input className="rounded w-1/2 p-3 m-2 mx-auto text-slate-100 bg-sideBarBlue active:bg-dayoAshBlue hover:font-bold border border-sideBarBlue hover:text-white" type="submit" value="Upload" />

        </form>
    )
}

export default EvaluatorSingleUploadForm;