// column chart
import ReactApexChart from 'react-apexcharts';

const LineColumn = ({ data, isData2, xaxis, titleText, className }) => {
	const dataFormat = data && data.map((item) => {
		return {
			name: item.assessmentType,
			type: item.chartType,
			data: item.data || [0, 0, 0],
		}
	})
	const state = {
		series: dataFormat /*[
			{
				name: 'FLE',
				type: 'bar',
				data: [80, 78, 75,] // Three states: L, P, A
			}, 
			{
				name: 'SECURITY',
				type: 'bar',
				data: [70, 74, 70,] // Three states: L, P, A
			},
			{
				name: 'Branch',
				type: 'bar',
				data: [70, 78, 79,] // Three states: L, P, A
			}, 
			{
				name: 'BM Score',
				type: 'line',
				data: [75, 74, 77,] // Three states: L, P, A
			}
		]*/,
		options: {
			chart: {
			height: 350,
			type: 'line',
		  },
		  plotOptions: {
			bar: {
				columnWidth: '10%',
			}
		  },
		  stroke: {
			width: [0,0,0, 2],
			curve: 'smooth',
		  },
		  title: {
			text: 'BM Score',
		  },
		  dataLabels: {
			enabled: true,
			enabledOnSeries: [3]
		  },
		  labels: ['LAGOS', 'RIVERS', 'FCT'] || xaxis,
		  xaxis: {
			//category: ['LAGOS', 'RIVERS', 'FCT'],
		  },
		  yaxis: [{
			title: {
			  text: 'Assessment Type',
			},
		  
		  }, {
			opposite: true,
			title: {
			  text: 'BM Score',
			}
		  }],
		  colors: ['#005a9a', '#00B8FF', '#0078cd', 'green' ],
		}
	};

	return (
		<div className={className? className : 'w-full rounded-lg p-1 bg-white'}>
			<ReactApexChart options={state.options} series={state.series} type="line" height={300} />
		</div>
	);
};

export default LineColumn;