import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { assignTask, fetchEvaluatorList, uploadOverwriteEvaluator, uploadEvaluator, deactivateEvaluator, deleteEvaluator, bulkUploadEvaluators } from "./evaluatorAPI";

const initialState = [];
const reducers = {};

export const evaluatorListThunk = createAsyncThunk('evaluators/load', async (_, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload = await fetchEvaluatorList(token, campaignID);
    return payload;
});

export const assignTaskThunk = createAsyncThunk('evaluators/assign-task', async (data, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const preparedData = data.map(item => ({
        campaign_id: item.campaign_id,
        evaluator_access_code: item.evaluator_access_code,
        branch_id: item.branch_id,
        date_assigned: item.date_assigned,
        task_type: item.task_type,
    }));
    console.log({ preparedData });
    const payload = await assignTask(preparedData, campaignID, token);
    return payload;
});

export const bulkUploadEvaluatorsThunk = createAsyncThunk('evaluators/bulkUpload', async (formData, { getState })=>{
    const { token } = getState().auth;
    const payload = await bulkUploadEvaluators(formData, token);
    return payload;
});

export const uploadEvaluatorThunk = createAsyncThunk('evaluators/upload', async (record, { getState })=>{
    const { token } = getState().auth;
    const payload = await uploadEvaluator(record, token);
    return payload;
});

export const deactivateEvaluatorThunk = createAsyncThunk('evaluators/deactivate', async (recordID, { getState })=>{
    const { token } = getState().auth;
    const payload = await deactivateEvaluator(recordID, token);
    return payload;
});

export const deleteEvaluatorThunk = createAsyncThunk('evaluators/delete', async (recordID, { getState })=>{
    const { token } = getState().auth;
    const payload = await deleteEvaluator(recordID, token);
    return payload;
});



const evaluatorListSlice = createSlice({
    name: 'evaluators',
    initialState,
    reducers,
    extraReducers: (builder)=>{
        builder
            .addCase(evaluatorListThunk.fulfilled, (state, action)=> [ ...action.payload ])
    }
});

export default evaluatorListSlice.reducer;