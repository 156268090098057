import axios from "axios";
import toast from "react-hot-toast";

const baseURL = process.env.REACT_APP_BASE_URL;
const baseURL2 = process.env.REACT_APP_BASE_URLv2;
//console.log({ baseURL })

export const fetchBanks = async () => {
    const { data: allBanks } = await axios.get(`${baseURL2}/bank/all`);
    return allBanks;
}

export const fetchCampaignList = async () => {
    const { data: allBanks } = await axios.get(`${baseURL}/campaign/all`);
    return allBanks;
}

export const fetchBankScore = async ({token, bankID=1, campaignID=2, compareCampaignID}) => {
    toast.remove(); toast.loading('Loading National Bank Score...');
    
    const { data: current } = await axios.get(`${baseURL}/bank-manager/score/${campaignID}`);
    const { data: previous } = await axios.get(`${baseURL}/bank-manager/score/${compareCampaignID? compareCampaignID : campaignID}`);

    toast.remove(); toast.success('Loaded Successfully', { duration: 4000});
    //console.log({current, previous});

    const allAccessBank = { current: current.filter(bank => bank.bank_name === "ACCESS"), previous: previous.filter(bank => bank.bank_name === "ACCESS") };
    const allGTBank = { current: current.filter(bank => bank.bank_name === "GTB"), previous: previous.filter(bank => bank.bank_name === "GTB") };
    const allStanbicBank = { current: current.filter(bank => bank.bank_name === "STANBIC"), previous: previous.filter(bank => bank.bank_name === "STANBIC") };
    const allZenithBank = { current: current.filter(bank => bank.bank_name === "ZENITH"), previous: previous.filter(bank => bank.bank_name === "ZENITH") };

    //console.log({allAccessBank });


    // Current Campaign
    const currentAccessFLEScore = (allAccessBank.current.map(bank=> bank.fle_score).reduce((a,b)=> a+b, 0)/allAccessBank.current.length).toFixed(2);
    const currentAccessBranchScore = (allAccessBank.current.map(bank=> bank.branch_score).reduce((a,b)=> a+b, 0)/allAccessBank.current.length).toFixed(2);
    const currentAccessSecurityScore = (allAccessBank.current.map(bank=> bank.security_score).reduce((a,b)=> a+b, 0)/allAccessBank.current.length).toFixed(2);
    const currentAccessInternScore = (allAccessBank.current.map(bank=> bank.intern_score).reduce((a,b)=> a+b, 0)/allAccessBank.current.length).toFixed(2);
    const currentAccessBmScore = ((currentAccessFLEScore * 0.5) + (currentAccessSecurityScore * 0.25) + (currentAccessBranchScore * 0.25)).toFixed(1)
    //const currentAccessBmScore = (allAccessBank.current.map(bank=> bank.bmScore).reduce((a,b)=> a+b, 0)/allAccessBank.current.length).toFixed(2);
    const currentTotalFle = allAccessBank.current.map(bank=> bank.totalFle).reduce((a,b)=> a+b, 0);
    const currentTotalSecurity = allAccessBank.current.map(bank=> bank.totalSecurity).reduce((a,b)=> a+b, 0);
    const currentTotalBranch = allAccessBank.current.map(bank=> bank.totalBranch).reduce((a,b)=> a+b, 0);
    const currentTotalIntern = allAccessBank.current.map(bank=> bank.totalIntern).reduce((a,b)=> a+b, 0);

    // Previous Campaign
    const previousAccessFLEScore = (allAccessBank.previous.map(bank=> bank.fle_score).reduce((a,b)=> a+b, 0)/allAccessBank.previous.length).toFixed(2);
    const previousAccessBranchScore = (allAccessBank.previous.map(bank=> bank.branch_score).reduce((a,b)=> a+b, 0)/allAccessBank.previous.length).toFixed(2);
    const previousAccessSecurityScore = (allAccessBank.previous.map(bank=> bank.security_score).reduce((a,b)=> a+b, 0)/allAccessBank.previous.length).toFixed(2);
    const previousAccessInternScore = (allAccessBank.previous.map(bank=> bank.intern_score).reduce((a,b)=> a+b, 0)/allAccessBank.current.length).toFixed(2);
    const previousAccessBmScore = ((previousAccessFLEScore * 0.5) + (previousAccessSecurityScore * 0.25) + (previousAccessBranchScore * 0.25)).toFixed(1)
    //const previousAccessBmScore = (allAccessBank.previous.map(bank=> bank.bmScore).reduce((a,b)=> a+b, 0)/allAccessBank.previous.length).toFixed(2);
    const previousTotalFle = allAccessBank.previous.map(bank=> bank.totalFle).reduce((a,b)=> a+b, 0);
    const previousTotalSecurity = allAccessBank.previous.map(bank=> bank.totalSecurity).reduce((a,b)=> a+b, 0);
    const previousTotalBranch = allAccessBank.previous.map(bank=> bank.totalBranch).reduce((a,b)=> a+b, 0);
    const previousTotalIntern = allAccessBank.previous.map(bank=> bank.totalIntern).reduce((a,b)=> a+b, 0);

    // Formartting the data
    const access= {
        current: {
            totalAssessment: currentTotalSecurity + currentTotalBranch + currentTotalFle + currentTotalIntern,
            fleScore: Number(currentAccessFLEScore) || 0,
            branchScore: Number(currentAccessBranchScore) || 0,
            securityScore: Number(currentAccessSecurityScore) || 0,
            internScore: Number(currentAccessInternScore) || 0,
            bankScore: Number(currentAccessBmScore),
            totalFle: currentTotalFle,
            totalBranch: currentTotalBranch,
            totalSecurity: currentTotalSecurity,
            totalIntern: currentTotalIntern,
        },
        previous: {
            totalAssessment: previousTotalSecurity + previousTotalBranch + previousTotalFle + previousTotalIntern,
            fleScore: Number(previousAccessFLEScore) || 0,
            branchScore: Number(previousAccessBranchScore) || 0,
            securityScore: Number(previousAccessSecurityScore) || 0,
            internScore: Number(previousAccessInternScore) || 0,
            bankScore: Number(previousAccessBmScore),
            totalFle: currentTotalFle,
            totalBranch: currentTotalBranch,
            totalSecurity: currentTotalSecurity,
            totalIntern: currentTotalIntern,
        }
    }

    return access;
}

export const fetchBankScoreByState = async ({state='Lagos', bankID=1, campaignID=2, compareCampaignID, isArrayLoad= false}) => {
    if(!isArrayLoad) {toast.remove(); toast.loading(`Loading State Bank Score...`);}

    const { data: current } = await axios.get(`${baseURL}/bank-manager/score/by-state/${campaignID}`);
    const { data: previous } = await axios.get(`${baseURL}/bank-manager/score/by-state/${compareCampaignID? compareCampaignID : campaignID}`);

    if(!isArrayLoad) {toast.remove(); toast.success('Loaded Succesfully', { duration: 4000});}

    const currentStateData= current.allstate[`${state}`];
    const previousStateData= previous.allstate[`${state}`];

    console.log({ currentStateData, previousStateData});

    const allAccessBank = { current: currentStateData.filter(bank => bank.bank_name === "ACCESS"), previous: previousStateData.filter(bank => bank.bank_name === "ACCESS") };
    const allGTBank = { current: currentStateData.filter(bank => bank.bank_name === "GTB"), previous: previousStateData.filter(bank => bank.bank_name === "GTB") };
    const allStanbicBank = { current: currentStateData.filter(bank => bank.bank_name === "STANBIC"), previous: previousStateData.filter(bank => bank.bank_name === "STANBIC") };
    const allZenithBank = { current: currentStateData.filter(bank => bank.bank_name === "ZENITH"), previous: previousStateData.filter(bank => bank.bank_name === "ZENITH") };

    console.log({ allAccessBank });


    // Current Campaign
    const currentAccessFLEScore = (allAccessBank.current.map(bank=> bank.fle_score).reduce((a,b)=> a+b, 0)/allAccessBank.current.map(bank=> bank.fle_score).length).toFixed(1);
    const currentAccessBranchScore = (allAccessBank.current.map(bank=> bank.branch_score).reduce((a,b)=> a+b, 0)/allAccessBank.current.length).toFixed(1);
    const currentAccessSecurityScore = (allAccessBank.current.map(bank=> bank.security_score).reduce((a,b)=> a+b, 0)/allAccessBank.current.length).toFixed(1);
    const currentAccessInternScore = (allAccessBank.current.map(bank=> bank.intern_score).reduce((a,b)=> a+b, 0)/allAccessBank.current.length).toFixed(1);
    const currentAccessBmScore = ((currentAccessFLEScore * 0.5) + (currentAccessSecurityScore * 0.25) + (currentAccessBranchScore * 0.25)).toFixed(1)
    //const currentAccessBmScore = (allAccessBank.current.map(bank=> bank.bmScore).reduce((a,b)=> a+b, 0)/allAccessBank.current.length).toFixed(1);
    const totalFle = allAccessBank.current.map(bank=> bank.totalFle).reduce((a,b)=> a+b, 0);
    const totalSecurity = allAccessBank.current.map(bank=> bank.totalSecurity).reduce((a,b)=> a+b, 0);
    const totalBranch = allAccessBank.current.map(bank=> bank.totalBranch).reduce((a,b)=> a+b, 0);
    const totalIntern = allAccessBank.current.map(bank=> bank.totalIntern).reduce((a,b)=> a+b, 0);

    // Previous Campaign
    const previousAccessFLEScore = (allAccessBank.previous.map(bank=> bank.fle_score).reduce((a,b)=> a+b, 0)/allAccessBank.previous.length).toFixed(1);
    const previousAccessBranchScore = (allAccessBank.previous.map(bank=> bank.branch_score).reduce((a,b)=> a+b, 0)/allAccessBank.previous.length).toFixed(1);
    const previousAccessSecurityScore = (allAccessBank.previous.map(bank=> bank.security_score).reduce((a,b)=> a+b, 0)/allAccessBank.previous.length).toFixed(1);
    const previousAccessInternScore = (allAccessBank.previous.map(bank=> bank.intern_score).reduce((a,b)=> a+b, 0)/allAccessBank.current.length).toFixed(1);
    const previousAccessBmScore = ((previousAccessFLEScore * 0.5) + (previousAccessSecurityScore * 0.25) + (previousAccessBranchScore * 0.25)).toFixed(1)
    //const previousAccessBmScore = (allAccessBank.previous.map(bank=> bank.bmScore).reduce((a,b)=> a+b, 0)/allAccessBank.previous.length).toFixed(1);


    // Formartting the data: DATA FOR eg. LAGOS, RIVERS, FCT
    console.log({ total: allAccessBank.current.length, fleTotal: allAccessBank.current.map(bank=> bank.fle_score).length });
    const access= {
        current: {
            totalAssessment: allAccessBank.current.length,
            fleScore: Number(currentAccessFLEScore) || 0,
            branchScore: Number(currentAccessBranchScore) || 0,
            securityScore: Number(currentAccessSecurityScore) || 0,
            internScore: Number(currentAccessInternScore) || 0,
            bankScore: Number(currentAccessBmScore),
            totalFle,
            totalBranch,
            totalSecurity,
            totalIntern,
        },
        previous: {
            totalAssessment: allAccessBank.previous.length,
            fleScore: Number(previousAccessFLEScore) || 0,
            branchScore: Number(previousAccessBranchScore) || 0,
            securityScore: Number(previousAccessSecurityScore) || 0,
            internScore: Number(previousAccessInternScore) || 0,
            bankScore: Number(previousAccessBmScore),
        }
    }

    return access;
}

/*fetchCampaignList().then(allCampaigns => console.log({ allCampaigns }))
.catch(err => console.log({err}));*/

