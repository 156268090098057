// column chart
import ReactApexChart from 'react-apexcharts';
import './ColumnChart.css';

const ColumnChart = ({ onClickHandler, clickableText, data, xaxis, titleText, horizontal, animation= false, status }) => {
	
	const colors = ['#1ea5ee', '#afafaf', '#57a850'];
	const completedData = data.map(array=> array[0]);
	const deficitData = data.map(array=> array[1] > 0? array[1] : 0);

	console.log({ completedData, deficitData })
	
	const state = {
		series: [
			{	
				name: 'Target Completion',
				data: completedData,
				//color: '#41B883',
			},
			{	
				name: 'Target Deficit',
				data: deficitData,
				//color: '#00D8FF',
			},
		],
		options: {
			chart: {
				height: 350,
				width: '100%',
				type: 'bar',
				stacked: true,
				stackType: '100%',
				animation: animation === true? {
					enabled: animation,
					easing: "easeinout",
					speed: 800,
					animateGradually: {
						enabled: true,
					},
					dynamicAnimation: {
						enabled: true,
						speed: 350
					}
				} : { enabled: false },
				events: {
					click: function (event, chartContext, config) {
						if (clickableText && clickableText.includes(event.target.innerHTML)) {
							onClickHandler(event.target.innerHTML);
						} else {
							return null;
						}
						// onClickHandler(event.target.innerHTML); 
					},
				},
			},
			colors: colors,
			plotOptions: {
				bar: {
					columnWidth: '20px',
					distributed: false,
					//borderRadius: 15,
					horizontal,
				},
			},
			stroke: {
				width: 0.1,
				colors: ['#fff'],
			},
			dataLabels: {
				enabled: true,
				textAnchor: 'middle',
				offsetY: 0
			},
			legend: {
				show: true,
			},
			xaxis: {
				categories: xaxis,
				//colors: ['#fff', '#afafaf', '#afafaf'],
				labels: {
					show: horizontal? false : true,
					style: {
						colors: [],
						fontSize: '12px',
						fontFamily: 'Helvetica, Arial, sans-serif',
						fontWeight: 400,
						cssClass: 'apexcharts-xaxis-label',
					},
				},
			},
			yaxis: {
				labels: {
					show: horizontal? true : false,
					style: {
						colors: ['#FB6D21', '#09add6', '#09add6'],
						fontSize: '20px',
						fontFamily: 'Helvetica, Arial, sans-serif',
						fontWeight: 800,
						cssClass: 'y-axis-column-text',
					},
				},
			},
			title: {
				text: titleText,
				floating: true,
				style: {
					color: '#2A2F3A',
				}
			},
			grid: {
				show: false,
			},
			noData: {
				text: 'No data to show (...for now)',
				align: 'center',
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: '100%',
							height: 295,
						},			
						colors: ['#025dff', '#ccc'],
						dataLabels: {
							enabled: true,
						},
						legend: {
							position: 'bottom',
						},
						title: {
							text: titleText
						},
					},
				},
			],
		},
	};

	return (
		<div>
			<ReactApexChart options={state.options} series={state.series} type="bar" height={380} />
		</div>
	);
};

export default ColumnChart;