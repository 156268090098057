import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { fetchReport, patchEntry, deleteEntry, fetchAllReport, fetchAllTypeReport, getAlterEntry, postAlterEntry, } from "./reportAPI";

const initialState = {};
const reducers = {};

export const reportsThunk = createAsyncThunk('reports/load', async (date, { getState })=>{
    const { token } = getState().auth;
    const { campaignID, dates } = getState().dataSummary;
    const payload = dates? await fetchReport(date, campaignID, dates, token) : null;
    return payload;
});

export const allReportsThunk = createAsyncThunk('reports/loadAll', async (_, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload = await fetchAllReport(campaignID, token);
    return payload;
});

export const allBranchThunk = createAsyncThunk('reports/loadAllBranch', async (_, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload = await fetchAllTypeReport(3, campaignID, token);
    return { allReports: payload };
});

export const allFLEThunk = createAsyncThunk('reports/loadAllFLE', async (_, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload = await fetchAllTypeReport(1, campaignID, token);
    return { allReports: payload };
});

export const allSECThunk = createAsyncThunk('reports/loadAllSEC', async (_, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload = await fetchAllTypeReport(2, campaignID, token);
    return { allReports: payload };
});

export const allInternThunk = createAsyncThunk('reports/loadAllIntern', async (_, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload = await fetchAllTypeReport(4, campaignID, token);
    return { allReports: payload };
});




export const editEntryThunk = createAsyncThunk('reports/patchEntry', async (formData, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload = await patchEntry(formData, campaignID, token);
    return payload;
});

export const deleteEntryThunk = createAsyncThunk('reports/deleteEntry', async (assessmentID, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload = await deleteEntry(assessmentID, campaignID, token);
    return payload;
});

export const postAlterEntryThunk = createAsyncThunk('reports/postAlterEntry', async (formDataArray, { getState })=>{
    const { token } = getState().auth;
    const payload = await postAlterEntry(formDataArray, token);
    return payload;
});


const reportSlice = createSlice({
    name: 'reports',
    initialState,
    reducers,
    extraReducers: (builder)=>{
        builder
            //.addCase(reportsThunk.pending, (state, action)=> { toast.loading("Loading..."); })
            .addCase(reportsThunk.fulfilled, (state, action)=> ({ ...action.payload }))
            .addCase(allBranchThunk.fulfilled, (state, action)=> ({ ...action.payload }))
            .addCase(allFLEThunk.fulfilled, (state, action)=> ({ ...action.payload }))
            .addCase(allSECThunk.fulfilled, (state, action)=> ({ ...action.payload }))
            .addCase(allInternThunk.fulfilled, (state, action)=> ({ ...action.payload }))
    }
});

export default reportSlice.reducer;