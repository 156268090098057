

/*export const convertDate = (dateString) => {
    console.log({dateString})
    let str = new Date(String(dateString));
    var day = ("0"+String(str.getDate())).slice(-2);
    var month = ("0"+ String(str.getMonth()+1)).slice(-2);
    var year = str.getFullYear();
    let shortDate = year+"-"+month+"-"+day;
    return shortDate;
}*/

export const convertDate = (dateString) => {
    //console.log({dateString});
    let str = dateString? dateString.split("T")[0] : 'N/A';
    //console.log({str});
    return str;
}