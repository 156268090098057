import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { fetchBankScore, fetchBankScoreByState } from "./summaryAPI";



const initialState = {};
const reducers = {};

export const loadScoresByStateThunk = createAsyncThunk('summary/loadScoresByState', async (state= 'All', { getState })=> {
    //const loadedStates = getState().summary && getState().summary.clientByState;
    //const states = Object.keys(loadedStates);
    //console.log({loadedStates});
    const { campaignID } = getState().dataSummary;
    //console.log(Array.isArray(state));
    const returnObj= {};
    if(Array.isArray(state)) {
        toast.loading('Loading Insights...');
        for(let i = 0; i < state.length; i++) {
            const payload = await fetchBankScoreByState({  state: state[i], isArrayLoad: true });
            //console.log({ [state[i]]: payload}, 'ARRAY');
            returnObj[`${state[i]}`] = payload;
            if(i === 1) { toast.remove(); toast.loading('Formating Data...'); }
        }
        toast.remove(); toast.success('Insights Loaded Successfully');
    } else {
        const payload = state == 'All'? await fetchBankScore({  }) : await fetchBankScoreByState({  state });
        //console.log({ [state]: payload});
        returnObj[`${state}`] = payload;
    }

    return returnObj;
})

const summarySlice = createSlice({
    name: 'summary',
    initialState,
    reducers,
    extraReducers: builder => {
        builder
            .addCase(loadScoresByStateThunk.fulfilled, (state, action) => ({ ...state, clientByState: { ...state.clientByState, ...action.payload } }) )
    }
})

export default summarySlice.reducer;