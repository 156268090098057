/* eslint-disable react-hooks/exhaustive-deps */
import DonutChart from "../../components/charts/DonutChart";
import ColumnChart from "../../components/charts/ColumnChart";
import ProgressBar from "../../components/charts/ProgressBar";
import LineChart from "../../components/charts/LineChart";
import React, { useEffect, useState } from "react";
//import Table from "../../components/tables/Table";
import { useDispatch, useSelector } from "react-redux";
//import ReactHtmlTableToExcel from "react-html-table-to-excel";
//import { today } from "../report/Report";
import { cummulativeProgressThunk, dailyScopeCoverageThunk, pastWeekZoneScopeTrendThunk, setCampaignID } from "./summarySlice";
import { today } from "../report/Report";
import BarChart from "../../components/charts/BarChart";
import reportSlice from "../report/reportSlice";
import toast from "react-hot-toast";

const date= new Date();
const todayString= date.toLocaleString('en-us');

const AdminSummary = () =>{
    const dispatch = useDispatch();    
    const { campaignID, dates, overallScopeCoverage, zonalTrend, cummulativeCampaignProgress } = useSelector(state => state.dataSummary);
    const { allCampaigns } = useSelector(state=> state.corporate);
    const campaign = allCampaigns? allCampaigns.find(campaign=> campaign.campaign_id == campaignID): {};

    const [campaign_id, setCampaign_id] = useState(campaignID);
    useEffect(()=> {
        setCampaign_id(campaignID);
    }, [campaignID]);

    
    // ALL THINGS AND DATA DAILY SCOPE
    const { dailyScopeCoverage } = useSelector(state => state.dataSummary);
    const previousDay = dates? dates.find(date=> date !== today) : null;

    /**
     * The Page loads and data is fetched on every date // dailyScopeCoverage is also re-fetched on campaign change
     * Data is stored in state as dailyScopeCoverage
     * dailyScopeCoverage is passed through filters: parent filter which are the single filters and family filters which are the multiple filters (e.g. Group: state, Group: state : lga ..etc.)
     * filtered scopeCoverage is formatted in 2D arrays by a formetter function makeData() and passed to the chart as "data".
     * Each chart includes other details that are arranged in an object as title and xAxis (assessmentTypes of campaign, bank types of campaign, allGroups, allZones for parent filters etc)
     * --> This object acts as a database that is use to dynamically generate the charts static data - xAxis, title (data is a single makeData() function that takes in FILTERED DATA and formats it to feed the UI)
     * 
     */
    const [selectDate, setSelectDate] = useState(today);
    useEffect(()=>{
        toast.remove(); toast.loading("Loading Daily Scope Coverage"); // UI loading indicator
        dispatch(dailyScopeCoverageThunk(selectDate))
        .then(({meta})=> {
            if (meta.requestStatus === 'fulfilled'){
                toast.remove(); toast.success(`Scope for ${selectDate} fetched`) // UI success indicator
            }
        }) // dispatch daily scope data filter by date
    }, [selectDate])

    const [dailyScopeFilter, setDailyScopeFilter] = useState("type");
    const handleChangeDailyScopeFilter = (e) => {
        setDailyScopeFilter(e.target.value);
    };

    const makeDailyData = () => {
        if(dailyScopeCoverage && dailyScopeFilter === 'group' && selectDate != 'ALL'){
            return Object.values(dailyScopeCoverage.groupScope).map((group, index) => {
                return {
                    title: 'Group ' + (index + 1),
                    data: [group.done, (group.assigned - group.done)]
                };
            });
        } else if (dailyScopeCoverage && dailyScopeFilter === 'type' && selectDate != 'ALL'){
            console.log(dailyScopeCoverage?.assessmentTypeScope)
            return Object.values(dailyScopeCoverage.assessmentTypeScope).map((type, index) => {
                return {
                    data: [type.done, (type.assigned - type.done)]
                };
            });
        } else if (dailyScopeCoverage && dailyScopeFilter === 'zone' && selectDate != 'ALL'){
            return Object.values(dailyScopeCoverage.zoneScope).map(type => {
                return Math.round((type.done/type.assigned) * 100);
            });
        } else if (dailyScopeCoverage && dailyScopeFilter === 'bank' && selectDate != 'ALL'){
            return Object.values(dailyScopeCoverage.byBank).map((bank, index) => {
                const banks = ['Access', 'GTB', 'Stanbic', 'Zenith'];
                return {
                    title: banks[index],
                    data: [bank.done, (bank.assigned - bank.done)]
                };
            });
        } else if(overallScopeCoverage && dailyScopeFilter === 'group' && selectDate === 'ALL'){
            return Object.values(overallScopeCoverage.groupScope).map((group, index) => {
                return {
                    title: 'Group ' + (index + 1),
                    data: [group.done, (group.assigned - group.done)]
                };
            });
        } else if (overallScopeCoverage && dailyScopeFilter === 'type' && selectDate === 'ALL'){
            //console.log(overallScopeCoverage.assessmentTypeScope)
            return Object.values(overallScopeCoverage.assessmentTypeScope).map((type, index) => {
                return {
                    data: [type.done, (type.assigned - type.done)]
                };
            });
        } else if (overallScopeCoverage && dailyScopeFilter === 'zone' && selectDate === 'ALL'){
            return Object.values(overallScopeCoverage.zoneScope).map(type => {
                return Math.round((type.done/type.assigned) * 100);
            });
        } else if (overallScopeCoverage && dailyScopeFilter === 'bank' && selectDate === 'ALL'){
            return Object.values(overallScopeCoverage.byBank).map((bank, index) => {
                const banks = ['Access', 'GTB', 'Stanbic', 'Zenith'];
                return {
                    title: banks[index],
                    data: [bank.done, (bank.assigned - bank.done)]
                };
            });
        } else { return [
            {
                title: 'fetchin data ... ',
                data: [0, 0]
            }
        ] }
    };

    /**UNDRY CODE CHANGE ASAP=>  Don't Repeat Yourself (DRY)*/
    const dailyChartData= {
        groups: [ // can be [campaignGroups].map(  (group, index)=> ({ groupTitle, data: makeDailyData()[index] }) )
            {
                title: 'LPA group',
                data: dailyScopeCoverage? makeDailyData()[0].data : []// ([35, 40-35].data changed to type scope for more details) values gotten from summing the multiplication of branches scope of group and types scope
            },
            {
                title: 'Group 2',
                data: dailyScopeCoverage? makeDailyData()[1].data : []
            },
            {
                title: 'Group 3',
                data: dailyScopeCoverage? makeDailyData()[2].data : []
                //data: [13, 15-13] // lesser detail in forming graph: not best implementationm
            },
        ],

        types: [ // can be [campaignTypes].map(  (Type, index)=> ({ TypeTitle, data: makeDailyData()[index] }) )
            {
                title: 'Branch',
                data: makeDailyData()[0]? makeDailyData()[0].data: [0, 0]
            },
            {
                title: 'FLE',
                data: makeDailyData()[1]? makeDailyData()[1].data: [0, 0]
            },
            {
                title: 'Security',
                data: makeDailyData()[2]? makeDailyData()[2].data: [0, 0],
            },
            {
                title: 'Intern',
                data: makeDailyData()[3]? makeDailyData()[3].data: [0, 0],
            }
        ],

        banks: [ // can be [campaignBanks].map(  (Bank, index)=> ({ BankTitle, data: makeDailyData()[index] }) )
            {
                title: 'Access',
                data: makeDailyData()[0]? makeDailyData()[0].data : [0, 0]
            },
            {
                title: 'GTB',
                data: makeDailyData()[1]? makeDailyData()[1].data : [0, 0]
            },
            {
                title: 'Zenith',
                data:  makeDailyData()[2]? makeDailyData()[2].data : [0, 0],
            },
            {
                title: 'Stanbic',
                data: makeDailyData()[3]? makeDailyData()[3].data : [0, 0],
            }
        ],

    }

    const [dailyScopeChart, setDailyScopeChart] = useState([]);
    useEffect(() => {
        if(dailyScopeFilter === 'group'){
            setDailyScopeChart(dailyChartData.groups);
        }
        else if(dailyScopeFilter === 'type'){
            const campaignSpecific = [];

            /**HARD CODED LINE BELOW: CHANGE ASAP! */
            campaignID != '3'? [0, 1, 2].map(type => campaignSpecific.push(dailyChartData.types[type])) : [3].map(type => campaignSpecific.push(dailyChartData.types[type]));  // should be campaignID.types.map(type=> ...campaignSpecific)
            setDailyScopeChart(campaignSpecific);
        
        }
        else if(dailyScopeFilter === 'bank') {
            setDailyScopeChart(dailyChartData.banks);
        }
        else { return null }
    }, [dailyScopeFilter, campaignID, dailyScopeCoverage]);

   
    // ALL THINGS PROJECT CAMPAIGN SCOPE COVERAGE


    const progressBarData = () => {
        if (overallScopeCoverage && overallScopeCoverage.assessmentTypeScope) {
            const assignedArray = [];
            const doneArray = [];

            Object.values(overallScopeCoverage.assessmentTypeScope).forEach(type => {
                assignedArray.push(type.assigned);
                doneArray.push(type.done);
            })

            console.log({campaignID} , {assignedArray}, {doneArray});
            return campaignID == 3? Math.ceil((doneArray[3] / assignedArray[3]) * 100) : Math.ceil((doneArray.reduce((prev, curr)=> prev + curr) / assignedArray.reduce((prev, curr)=> prev + curr)) * 100)
        }
    }

    // Zonal trend data
    const makeZonalTrendData= () =>{
        const data = zonalTrend? [...zonalTrend].map(day=> {
            return [...Object.values(day)];
        }) : [];
        return {
            lpa: data.map(day=> day[0]).reverse(),
            nc: data.map(day=> day[1]).reverse(),
            ne: data.map(day=> day[2]).reverse(),
            nw: data.map(day=> day[3]).reverse(),
            se: data.map(day=> day[4]).reverse(),
            ss: data.map(day=> day[5]).reverse(),
            sw: data.map(day=> day[6]).reverse(),
        };
    }

    const pastWeekInDates = [];
    for (let i = 6; i >= 0; i--) {
        if(dates){
            if (dates[i]) pastWeekInDates.push(dates[i]);
        } 
    }

    useEffect(()=>{
        dispatch(pastWeekZoneScopeTrendThunk(dates));
        dispatch(cummulativeProgressThunk());
    }, [dates]);


    // To Filter Chart Display by Type, Group and Zone
    const [projectChartFilter, setProjectChartFilter] = useState("types");
    const handleChangeProjectChart = (e) => {
        setProjectChartFilter(e.target.value);
        // setProjectScopeFilter('groupTypes'); if there was groupStates this will be necessary
    };

    // To Filter Display of Each Group's Type (or state if it was available) scope coverage data
    const [projectScopeFilter, setProjectScopeFilter] = useState('groupTypes')
    const handleProjectScopeFilter= (e) => {
        setProjectScopeFilter(e.target.value);
    };

    const [showGroupNumber, setShowGroupNumber] = useState(0);
    const handleSetGroupDetailChart = (groupName) => {
        if (groupName === 'LPA') {
            setShowGroupNumber(0);
        } else if (groupName === 'Group 2') {
            setShowGroupNumber(1);
        } else if (groupName === 'Group 3') {
            setShowGroupNumber(2);
        } else { return null }
    }

    // Auto-generate data for overall project scope chart
    const makeProjectChartData = () => {
        if (overallScopeCoverage && projectChartFilter === 'groups'){
            return Object.values(overallScopeCoverage.groupScope).map(group => { // [[done, remaining], [done, remaining], [done, remaining]]
                return [group.done, (group.assigned - group.done)];
            });
        } 
        else if (overallScopeCoverage && projectChartFilter === 'types'){ // [[done, remaining], [done, remaining], [done, remaining]]
            
            /* [campaign.Types].map((item, index) => { // for scalable campaign specifity when campaignTypes is added to campaign info
                const assigned = overallScopeCoverage.assessmentTypeScope[`${index}`].assigned;
                const done = overallScopeCoverage.assessmentTypeScope[`${index}`].done;
                return [done, (assigned - done)];
            }))*/
            if (campaignID != 3) {
                console.log({check: Object.values(overallScopeCoverage.assessmentTypeScope)});
                return ['Branch', 'FLE', 'Security'].map((type, i) => {
                    const done = Object.values(overallScopeCoverage.assessmentTypeScope)[i].done;
                    const assigned = Object.values(overallScopeCoverage.assessmentTypeScope)[i].assigned;
                    return [done, (assigned- done)];
                });
            } else {
                console.log({check: Object.values(overallScopeCoverage.assessmentTypeScope)});
                const done = Object.values(overallScopeCoverage.assessmentTypeScope)[3].done;
                const assigned = Object.values(overallScopeCoverage.assessmentTypeScope)[3].assigned;
                return [[done, (assigned - done)]];
            }
        } else if (overallScopeCoverage && projectChartFilter === 'zones'){
            return Object.values(overallScopeCoverage.zoneScope).map(zone => {
                return zone.assigned > 1? [zone.done, (zone.assigned- zone.done)] : [0, 0];
            });
        } else if(overallScopeCoverage && projectChartFilter === 'banks'){
            return Object.values(overallScopeCoverage.byBank).map(bank => { // [[done, remaining], [done, remaining], [done, remaining]]
                return [bank.done, (bank.assigned - bank.done)];
            });
        } else { return [{ title: ' loading useful data ... ', data: [0, 0] }] }
    };

    //console.log({ projectData: makeProjectChartData()});

    // Auto-generate data for groupTypes scope coverage chart
    const makeGroupTypeData = () => {
        if(overallScopeCoverage && projectChartFilter === 'groups'){
            /*if (campaignID != 3) {
                return ['Branch', 'FLE', 'Security'].map((type, i) => {
                    const done = Object.values(overallScopeCoverage.groupScope[i].type).done;
                    const assigned = Object.values(overallScopeCoverage.groupScope[i].type).assigned;
                    console.log({done, assigned});
                    return [done, (assigned- done)];
                });
            }
            else if (campaignID == 3) {
                const done = Object.values(overallScopeCoverage.groupScope[3].type).done;
                const assigned = Object.values(overallScopeCoverage.groupScope[3].type).assigned;
                console.log({done, assigned});
                return [done, (assigned- done)];
            }*/
            const groupTypesScope = Object.values(overallScopeCoverage.groupScope).map(group => {
                const data= Object.values(group.type).map(type => [type.done, (type.branchAssigned - type.done)]);
                return data;
            })
            return showGroupNumber === 0? groupTypesScope[0] : showGroupNumber === 1? groupTypesScope[1] : groupTypesScope[2];
        } else{
            return [];
        }
        
    }

    // data skeleton for overall campaign charts
    const projectCampaignChartData= {
        groups: {
            titleText: 'Group Progress (%)',
            data: makeProjectChartData(),
            xaxis: ['LPA', 'Group 2', 'Group 3'],
        },
        types: {
            titleText: 'Assessment Type Progress (%)',
            data: makeProjectChartData(),
            xaxis: campaignID != 3? ['Branch', 'FLE', 'Security', 'Interns'] : ['Interns'], // campaignTypes to replace this for scalable campaign specifity
        },
        zones: {
            titleText: 'Zone Progress (%)',
            data: makeProjectChartData(),
            xaxis: ['South West', 'South East', 'South South', 'North East', 'North West', 'North Central'],
        },
        banks: {
            titleText: 'Bank Progress (%)',
            data: makeProjectChartData(),
            xaxis: ['Access', 'GTB', 'Zenith', 'Stanbic'],
        },
        groupStates: [
            {
                name: 'LPA Group Summary',
                cat: ['Lagos', 'Rivers', 'Abuja'],
                data: [50, 85, 55] //group project scope data by project scope filter: overall, branch, FLE, security
            },
            {
                name: 'Group 2 Summary',
                cat: ['Abia', 'Akwa-Ibom', 'Anambra', 'Cross Rivers', 'Delta', 'Edo', 'Enugu', 'Imo', 'Kaduna', 'Kano', 'Ogun', 'Oyo',],
                data: [40, 50, 55, 73, 44, 34, 67, 73, 44, 73, 44, 34,] //state project scope data data by project scope filter: overall, branch, FLE, security
            },
            {
                name: 'Group 3 Summary',
                cat: ['Adamawa', 'Bauchi', 'Bayelsa', 'Benue', 'Borno', 'Ebonyi', 'Ekiti', 'Gombe', 'Jigawa', 'Katsina', 'Kebbi', 'Kogi', 'Kwara', 'Nasarawa', 'Niger', 'Ondo', 'Osun', 'Plateau', 'Sokoto', 'Taraba', 'Yobe', 'Zamfara',],
                data: [40, 50, 55, 73, 44, 34, 67, 73, 44, 73, 44, 34, 40, 50, 55, 73, 44, 34, 67, 73, 44, 73,] //state project scope data data by project scope filter: overall, branch, FLE, security
            },
        ],
        groupTypes: [
            {
                name: 'LPA Assessment Type Summary',
                cat: ['Branch', 'FLE', 'Security', 'Intern'],
                data: makeGroupTypeData() //type project scope data by project scope filter: overall, branch, FLE, security
            },
            {
                name: 'Group 2 Assessment Type Summary',
                cat: ['Branch', 'FLE', 'Security', 'Intern'],
                data: makeGroupTypeData() //type project scope data data by project scope filter: overall, branch, FLE, security
            },
            {
                name: 'Group 3 Assessment Type Summary',
                cat: ['Branch', 'FLE', 'Security', 'Intern'],
                data: makeGroupTypeData() //type project scope data data by project scope filter: overall, branch, FLE, security
            },
        ],
    }

    // Set the data for the project overall scope coverage chart with auto makeProjectChartData()
    const [projectChart, setProjectChart] = useState(projectCampaignChartData.groups);
    useEffect(() => {
        if(projectChartFilter === 'groups'){
            makeGroupTypeData();
            setProjectChart(projectCampaignChartData.groups);
        }else if(projectChartFilter === 'types'){
            setProjectChart(projectCampaignChartData.types);
        }else if(projectChartFilter === 'zones'){
            setProjectChart(projectCampaignChartData.zones);
        }else if(projectChartFilter === 'banks'){
            setProjectChart(projectCampaignChartData.banks);
        }else { return null }
    }, [projectChartFilter, overallScopeCoverage]);


    // Set the data for the group type scope coverage chart with auto makeGroupTypeData()
    const [groupTypeScope, setGroupTypeScope] = useState(projectCampaignChartData.groupTypes[0]);
    useEffect(() => {
        /*if(projectScopeFilter === 'groupStates') {
            setgroupTypeScope(projectCampaignChartData.groupStates[showGroupNumber]);
        } else */
        if(projectScopeFilter === 'groupTypes') {
            setGroupTypeScope(projectCampaignChartData.groupTypes[showGroupNumber]);
        } else { return null };

    }, [projectScopeFilter, showGroupNumber]);

    const [displayCummulative, setDisplayCummulative] = useState(false);

    //console.log({ campaign_id, campaignID });



    return (
        <div className="flex flex-col w-full h-max bg-slate-100 p-4 items-center justify-start shadow">
            <div className="flex w-full my-2 justify-start items-center">
                <h1 className="font-bold w-max mx-3 text-xl text-slate-400">Campaign Summary - </h1>
                <p className="font-medium text-slate-500">
                    Here you can <span className="text-blue-400"> View Campaign Summary </span>
                </p>
            </div>
{/**Campaign Overall Progress Bar*/}
            <section className="w-full h-max mx-5 p-2 border-slate-200">
                <div className="w-full md:w-1/2">
                    <select onChange={(e)=> { dispatch(setCampaignID(e.target.value)); setCampaign_id(e.target.value) }}
                        defaultValue={campaign_id}
                        value={campaign_id}
                        className="w-1/2 p-2 my-2 rounded border focus:outline-none focus:border-dayoLightBlue text-slate-500"
                    >
                        <option value={'DEFAULT'} disabled>Select Campaign ID</option>
                        {
                            allCampaigns? allCampaigns.map((campaign, i) => {
                                return <option key={i++} value={campaign.campaign_id} >{campaign.campaign_name}</option>
                            }) : <option value={'LOADING'} disabled >No Campaigns</option>
                        }
                    </select>
                </div>
                <h1 className="self-start font-bold text-lg text-slate-600">{campaign? campaign.campaign_name : ""} Progress</h1>
                <ProgressBar progressPercentage={progressBarData()? progressBarData() : 0 } />
                <div>
                    <button onClick={(e)=> { setDisplayCummulative(!displayCummulative) }} className=" rounded text-md px-4 py-2 min-w-max w-1/4 bg-slate-100 text-sideBarBlue border border-slate-300 hover:bg-sideBarBlue hover:text-slate-100 active:bg-dayoAshBlue active:text-slate-100">View Progress Breakdown</button>
                </div>
            </section>

            <section className={`${displayCummulative? 'block' : 'hidden'} w-full p-2 mx-4 my-0 bg-white shadow-md rounded-lg`}>

                <div className="w-full h-max bg-slate-100 p-4 items-center shadow">
                    <BarChart data={{ name: 'progress day', data: cummulativeCampaignProgress? cummulativeCampaignProgress.map(obj=> obj.data).reverse() : []}} percentage={true} noRadius={true} yMax100={true} showYaxis={true} yaxisTitleText={'Campaign Progress Breakdown ( % )'} xaxis={cummulativeCampaignProgress? cummulativeCampaignProgress.map(obj=> obj.date).reverse() : []} titleText={'Cummulative of Campaign Progress'} className={`w-full`} />
                </div>
            </section>


{/**Daily Scope Completion by Groups and Assessment Types */}
            <section className="w-full m-4 bg-white shadow-md rounded-lg">
                
                 <div className="flex flex-wrap w-full h-max bg-slate-100 px-4 py-1 border items-center justify-between shadow">
                    <div className="">
                        <h1 className="self-start font-bold text-lg">Daily Scope Completion (<span className='text-sideBarBG'>{selectDate === today? 'Today': selectDate}</span>)</h1>
                        <p className="text-slate-600">Date: <span className="text-slate-400">{today}</span></p>
                    </div>

                    <div className="flex flex-wrap justify-center lg:justify-end items-center w-1/3 lg:w-1/2">
                        <div onClick={ (e)=> { if(dates) setSelectDate(previousDay) } } className={`px-2 py-3 m-1 w-1/6 min-w-max rounded-full cursor-pointer ${selectDate === previousDay? 'bg-sideBarBlue text-slate-100 font-bold' : 'bg-white text-slate-500 hover:text-sideBarBlue'} ${!date? 'cursor-not-allowed' : ''} text-center`}><p>Previous</p></div>
                        <div onClick={ (e)=> { setSelectDate('ALL') } } className={`px-2 py-3 m-1 w-1/6 min-w-max rounded-full cursor-pointer ${selectDate === 'ALL'? 'bg-sideBarBlue text-slate-100 font-bold' : 'bg-white text-slate-500 hover:text-sideBarBlue'} ${!date? 'cursor-not-allowed' : ''} text-center`}><p>All</p></div>
                        <select onChange={(e)=>{ setSelectDate(e.target.value )}} className="rounded-lg w-max h-12 m-3 p-1 text-slate-600 text-center focus:outline-none hover:border hover:border-slate-100 hover:shadow-md focus:border focus:border-slate-100 focus:shadow-md">
                            <option value={today}>Today</option>
                            {
                                dates? dates.filter(date=> date !== today).map((date, i) => {
                                    return <option value={date}>{date === today? 'Today': date}</option>
                                } ) : <option>loading date ... </option>
                            }
                        </select>                           
                        <select onChange={handleChangeDailyScopeFilter} className="rounded-lg w-max h-12 m-3 p-1 text-slate-600 focus:outline-none hover:border hover:border-slate-100 hover:shadow-md focus:border focus:border-slate-100 focus:shadow-md">
                            <option value="type">Assessment Types</option>
                            <option value="bank">Banks</option>
                            <option value="group">Groups</option>
                            {/*<option value="states">States</option>*/}
                        </select>
                    </div>
                    
                    
                 </div>
                {/*Charts */}
                <div className="flex flex-wrap p-6 w-full h-max justify-center">
                    {
                        dailyScopeChart.map((data, index) => {
                            return <DonutChart key={index} data={dailyScopeCoverage && overallScopeCoverage && makeDailyData()[index]? data.data : [0, 0]} titleText={data.title} />
                        })
                    }
                </div>
            </section>

{/**Project Scope Overall by Groups and Assessment Types and Zone */}
            <section className="w-full m-4 bg-white shadow-md rounded ">
                <div >
                    <div className="flex w-full h-max bg-slate-100 p-4 items-center justify-between border-t shadow">
                        <h1 className="font-bold text-lg">Project Scope Completion
                                (
                                <select onChange={handleChangeProjectChart} defaultValue={"types"} className="bg-inherit w-max text-sideBarBG focus:outline-none hover:scale-105 ">
                                    <option value="groups" >Groups</option>
                                    <option value="types">Types</option>
                                    <option value="zones">Zones</option>
                                    <option value="banks">Banks</option>
                                </select>
                                )
                        </h1>

                        {
                            projectChartFilter === 'groups'? (
                                <select onChange={handleProjectScopeFilter} defaultValue={"groupTypes"} className="w-max mx-3 px-3 py-2 text-slate-600 focus:outline-none hover:border hover:border-slate-100 hover:shadow-md focus:border focus:border-slate-100 focus:shadow-md">
                                    {/*<option value='groupStates' disabled>States</option>*/}
                                    <option value={'groupTypes'} >Assessment Types</option>
                                </select> 
                            ) 
                            :
                            null
                        }

                    </div>

                    {/**Campaign Categories Progress Summary Cards */}
                    <section className="flex flex-wrap justify-center w-full bg-slate-100">
                        {
                            overallScopeCoverage && projectChartFilter === 'types'? projectChart.data.map((type, i) => {
                                const currentCategoryAndName = projectChart.xaxis
                                return (
                                    <div className={`flex flex-col items-center justify-center py-4 px-2 my-2 ${projectChart.data.length > 1? `w-1/${projectChart.data.length}` : 'w-1/2'} rounded-md border bg-white `}>
                                        <h1 className="font-bold text-slate-600 text-lg">{currentCategoryAndName[i]}</h1>
                                        <p  className="font-bold text-sideBarBG text-lg"><span className="text-dayoAshBlue">{type[0]}</span> / {type[0] + type[1]} </p>
                                    </div>
                                )
                            })
                            :
                            overallScopeCoverage && projectChartFilter === 'groups'? Object.values(overallScopeCoverage.groupScope).map((type, i) => {
                                const currentCategoryAndName = ['LPA', 'Group 2', 'Group 3'];
                                return (
                                    <div className={`flex flex-col items-center justify-center py-4 px-2 my-2 w-1/${Object.values(overallScopeCoverage.groupScope).length} rounded-md border bg-white `}>
                                        <h1 className="font-bold text-slate-600 text-lg">{currentCategoryAndName[i]}</h1>
                                        <p  className="font-bold text-sideBarBG text-lg"><span className="text-dayoAshBlue">{type.done}</span> / {type.assigned} </p>
                                    </div>
                                )
                            })
                            :
                            overallScopeCoverage && projectChartFilter === 'zones'? Object.values(overallScopeCoverage.zoneScope).map((type, i) => {
                                const currentCategoryAndName = ['South West', 'South East', 'South South', 'North East', 'North West', 'North Central']
                                return (
                                    <div className={`flex flex-col items-center justify-center py-4 px-2 my-2 w-1/${Object.values(overallScopeCoverage.zoneScope).length} rounded-md border bg-white `}>
                                        <h1 className="font-bold text-slate-600 text-lg">{currentCategoryAndName[i]}</h1>
                                        <p  className="font-bold text-sideBarBG text-lg"><span className="text-dayoAshBlue">{type.done}</span> / {type.assigned} </p>
                                    </div>
                                )
                            })
                            :
                            overallScopeCoverage && projectChartFilter === 'banks'? Object.values(overallScopeCoverage.byBank).map((type, i) => {
                                const currentCategoryAndName = ['Access' ,'GTB' ,'Zenith' ,'Stanbic'];
                                return (
                                    <div className={`flex flex-col items-center justify-center py-4 px-2 my-2 w-1/${Object.values(overallScopeCoverage.byBank).length} rounded-md border bg-white `}>
                                        <h1 className="font-bold text-slate-600 text-lg">{currentCategoryAndName[i]}</h1>
                                        <p  className="font-bold text-sideBarBG text-lg"><span className="text-dayoAshBlue">{type.done}</span> / {type.assigned} </p>
                                    </div>
                                )
                            })

                            
                            : 'loading data...'
                        }
                    </section>
                </div>
                
                {/*Charts */}
                <div className="flex flex-wrap self-start w-full p-6">
                    <div className={`${projectChartFilter === 'groups'? 'w-1/3' : 'w-full'} h-max pl-4 pt-4 shadow-md`}>
                        <ColumnChart
                            data={ projectChart.data }
                            xaxis={ projectChart.xaxis } 
                            titleText={projectChart.titleText }
                            onClickHandler={handleSetGroupDetailChart}
                            clickableText={['LPA', 'Group 2', 'Group 3']}
                            horizontal={projectChartFilter !== 'groups'? false : true}
                        />
                    </div>
                    {
                        projectChartFilter === 'groups'? (
                            <div className="w-3/5 h-max pt-4 m-2 shadow-md">
                                <ColumnChart
                                    data={ makeGroupTypeData()}
                                    xaxis={ groupTypeScope.cat} 
                                    titleText={ groupTypeScope.name}
                                    horizontal={false}
                                />
                            </div>
                        ) : null
                    }
                </div>
            </section>
            
{/**Best and Worst Performing Evaluators */}
            {/*<section className="w-full p-5 m-4 bg-white shadow-md rounded ">
                <h1 className="self-start font-bold text-lg">Best and Worst Performers</h1>
                <div className="flex flex-wrap self-center justify-content-center w-full p-6">
                    {/*<div className="w-2/5 h-max pt-4 m-2 shadow-md">
                        <ColumnChart
                        data={[98, 97, 97, 96, 96]} 
                        xaxis={['Abigail SS0345', 'Sheriff SE4367', 'Joshua SS3433', 'Temi SW3439', 'Uche SE3789']} 
                        titleText={'Top 5 performers'} 
                        horizontal={false}
                        />
                    </div>
                    <div className="w-2/5 h-max pt-4 m-2 shadow-md">
                        <ColumnChart
                        data={[25, 26, 34, 36, 36]} 
                        xaxis={['Diirin SW0345', 'Mopol SE7367', 'Keshia SS3833', 'Tefiama SW3939', 'Ogbo SE3009']} 
                        titleText={'Bottom 5 performers'} 
                        horizontal={false}
                        />
                </div>}
                </div>
            </section>*/}

{/**zonal Scope Completion trend: Line graph */}
            <section className="w-full p-2 m-4 bg-white shadow-md rounded ">
                <LineChart
                    xaxis={dates? [...dates].reverse() : []}
                    data={makeZonalTrendData()}
                />
            </section>
            
        </div>
    )
};

export default AdminSummary;