import accessLogo from '../../../utilities/assets/accessLogo.svg';

function Header(){

    return (
        <div className=" flex items-center h-16 p-2 bg-slate-200">
            <h1 className="cedarvilleCursive flex items-center max-h-min text-md font-bold text-slate-600"><img src={accessLogo} className='mx-5 h-full w-32' alt='client logo'/> Connectifie </h1>
        </div>
    )
}

export default Header;