import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FetchBankInsight, FetchBankInsightByState } from "./insightAPI";
import toast from "react-hot-toast";

const initialState = {};
const reducers = {};

export const bankInsightThunk = createAsyncThunk('insight/load', async (_, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    //console.log({date})
    const payload = await FetchBankInsight(token, campaignID);
    //console.log({ payload })
    return {...payload};
});

export const bankInsightByStateThunk = createAsyncThunk('insight/loadByState', async (location, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload = await FetchBankInsightByState(token, campaignID, location);
    return {...payload};
});



const insightSlice = createSlice({
    name: 'insight',
    initialState,
    reducers,
    extraReducers: (builder)=>{
        builder
            .addCase(bankInsightThunk.fulfilled, (state, action)=> ({ ...action.payload }))
            .addCase(bankInsightByStateThunk.fulfilled, (state, action)=> ({ ...action.payload }))
    }
});

export default insightSlice.reducer;