
import axios from 'axios';
import toast from 'react-hot-toast';

const baseURL = 'https://mysteryshopper.connectifie.com/api/v1.0';

export const signin = async (formData) => {
    try {
        toast.remove();
        toast.loading('Signing you in...');
        sessionStorage.setItem('campaignID', formData.campaignID);
        const { data } = await axios.post(`${baseURL}/admin/login`, formData);
        toast.remove();
        data.success ? toast.success('Login Success!', { duration: 7000 }) : toast.error('Login Failed! Try again');
        Object.entries({ ...data, ...data.user }).forEach(data=> {
            sessionStorage[`${data[0]}`] = data[1];
        })
        //console.log( {...data, ...data.user, campaignID: formData.campaignID });
        return data.success ? {...data, ...data.user, campaignID: formData.campaignID } : {token: false};
    } catch (error) {
        toast.remove();
        toast.error(error.message);
        return null;
    }
};
