
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";


const BranchEditForm= ({onSubmitForm, onCancel, record}) => {
    
    const { register, handleSubmit, errors } = useForm();
    const { states, LGA= [] } = useSelector(state => state.dataSummary);

    const bankCode = record && record.code && (record.code[0] + record.code[1] + record.code[2]);
    const branchCode = record && record.code && (record.code[3] + record.code[4] + record.code[5]);

    const [selectedBank, setSelectedBank] = useState('ACCESS');
    const [selectedState, setSelectedState] = useState('Abia State');
    const [filteredLGA, setFilteredLGA] = useState(LGA? LGA : []);

    useEffect(()=>{
        if(LGA){
            const filtered = LGA.filter(lga=> lga.state == selectedState);
            setFilteredLGA(filtered);
        }
        else {
            setFilteredLGA([]);
        } 
    }, [LGA, selectedState])


    return (
        <form className={` flex flex-col justify-center items-start w-full min-w-max lg:w-1/2 p-4 rounded border border-slate-300 bg-slate-50 shadow-md `} onSubmit={handleSubmit(onSubmitForm)}>
            <h1 className="text-slate-600 font-bold m-2 ml-0">Edit Branch</h1>

            <div className='w-full flex'>
               {/*} <label className="flex flex-col justify-center w-1/4 font-bold text-slate-500 my-2 px-2">
                    Bank Name
                    <select
                        {...register("bank_name", {
                            onChange: (e)=> {
                                setSelectedBank(e.target.value)
                            }
                        })}
                        className="text-dayoAshBlue focus:outline-none rounded my-2 mr-2 p-3 rounded bg-slate-100 border border-slate-500" 
                        required
                    >
                        <option value="DEFAULT" disabled>Select Bank</option>
                        {
                            ['ACCESS', 'GTB', 'STANBIC', 'ZENITH'].map((bank, i)=>{
                                return <option key={i} value={bank} >{bank}</option>
                            })
                        }

                    </select>
                </label>*/}
                <label className="flex flex-col justify-center w-max font-bold text-slate-500 my-2 px-1">
                    Branch Code
                    <div className="flex w-3/4">
                        <input type='text'
                            value={bankCode}
                            {...register("bank_code")}
                            className="text-dayoAshBlue font-bold focus:outline-none rounded w-12 my-2 mr-0 p-3 pr-0 rounded bg-slate-100 border border-slate-500 border-r-0"  
                        />
                        <input 
                            type='number'
                            max="999"
                            pattern=".{3,}"
                            defaultValue={branchCode}
                            {...register("branch_code")}
                            className="text-dayoAshBlue font-bold focus:outline-none rounded w-16 my-2 mr-2 py-3 rounded bg-slate-100 border border-slate-500 border-l-0" 
                            required
                        />
                    </div>
                </label>
                <label className="flex flex-col justify-center w-full font-bold text-slate-500 my-2 px-2">
                    Branch Name
                    <input 
                        type='text' 
                        {...register("branch_name")}
                        defaultValue={record.name}
                        className="text-dayoAshBlue focus:outline-none rounded my-2 mr-2 p-3 rounded bg-slate-100 border border-slate-500" 
                        required
                    />
                </label>
                
            </div>
            <div className='flex max-w-3/4'>
                <label className="flex flex-col justify-center w-1/2 font-bold text-slate-500 my-2 px-2">
                    Branch Address
                    <input 
                        type='text' 
                        {...register("address")}
                        defaultValue={record.address}
                        className="text-dayoAshBlue focus:outline-none rounded my-2 mr-2 p-3 rounded bg-slate-100 border border-slate-500" 
                        required
                    />
                </label>
                <label className="flex flex-col justify-center w-max font-bold text-slate-500 my-2 px-2">
                    State
                    <select 
                        type='text' 
                        {...register("state", {
                            onChange: (e)=> {
                                setSelectedState(e.target.value)
                            }
                        })}
                        defaultValue={record.state}
                        className="text-dayoAshBlue focus:outline-none rounded my-2 mr-2 p-3 rounded bg-slate-100 border border-slate-500"
                        required
                    >
                        <option value='' disabled>Select State</option>
                        {
                            states? states.map((state, index) => (
                                <option key={index} value={state.state}>{state.state}</option>
                            )) : <option className="italic">loading...</option>
                        }
                    </select>
                </label>
                <label className="flex flex-col justify-center w-1/3 font-bold text-slate-500 my-2 px-2">
                    LGA
                    <select 
                        type='text' 
                        {...register("lga_id")}
                        className="text-dayoAshBlue focus:outline-none rounded my-2 mr-2 p-3 rounded bg-slate-100 border border-slate-500"
                        required
                    >
                        <option value='' disabled>Select LGA</option>
                        {
                            LGA? filteredLGA.map((LGA, index) => (
                                <option key={index} value={LGA.lga_id}>{LGA.lga}</option>
                            )) : <option className="italic">loading...</option>
                        }
                    </select>
                </label>
            </div>
            <div className="flex items-center justify-evenly w-full">
                <input className="rounded w-1/2 p-3 m-2 text-slate-100 bg-sideBarBlue active:bg-dayoAshBlue hover:font-bold border border-sideBarBlue hover:text-white" type="submit" value="Save Edit" />
                <button onClick={onCancel} className="rounded w-1/2 p-3 m-2 text-red-800 bg-dayoRed active:bg-red-200 hover:font-bold border border-red-800 hover:text-red-900" >Cancel</button>
            </div>
        </form>
    )
}

export default BranchEditForm;