// column chart
import ReactApexChart from 'react-apexcharts';
import './ColumnChart.css';

const BarChart = ({ data, xaxis, yaxisTitleText='', showYaxis= true, horizontal, noRadius=true, percentage, yMax100, colorCode, titleText, className }) => {
	const state = {
		series: [
			{
				name: data.name,
				data: data.data
			},
		],
		options: {
			chart: {
				height: '100%',
				width: '100%',
				type: 'bar',
				animation: {
					enabled: "true",
					easing: "easeinout",
					speed: 800,
					animateGradually: {
						enabled: true,
					},
					dynamicAnimation: {
						enabled: true,
						speed: 350
					}
				},
			},
			colors: colorCode? colorCode : ['#00B8FF', '#6AD8F7',],
			plotOptions: {
				bar: {
					columnWidth: '42',
					distributed: true,
					borderRadius: noRadius? 0 : 30,
					horizontal: horizontal? horizontal : false,
				},
			},
			stroke: {
				width: 0.1,
				colors: ['#fff'],
			},
			dataLabels: {
				enabled: true,
				formatter: function(val, opt) {
					//return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
					return percentage? val + '%': val;
				},
				background: {
					enabled: true,
					foreColor: '#2A2F3A',
					padding: 4,
					borderRadius: 2,
					borderWidth: 1,
					borderColor: '#fff',
					opacity: 0.9,
					dropShadow: {
						enabled: false,
						top: 1,
						left: 1,
						blur: 1,
						color: '#000',
						opacity: 0.45
					}
				},
			},
			legend: {
				show: false,
			},
			xaxis: {
				categories: xaxis? xaxis : ['Access', 'GTB', 'Stanbic', 'Zenith'],
				colors: ['#fff', '#afafaf', '#afafaf', '#ccc'],
				labels: {
					show: horizontal? false : true,
					style: {
						colors: [],
						fontSize: '12px',
						fontFamily: 'Helvetica, Arial, sans-serif',
						fontWeight: 400,
						cssClass: 'apexcharts-xaxis-label',
					},
				},
			},
			yaxis: {
				show: showYaxis,
				showAlways: false,
				title: {
					text: yaxisTitleText,
					style: {
						color: 'slate',
						fontSize: '12px',
						fontFamily: 'roboto, Arial, sans-serif',
						fontWeight: 600,
						cssClass: 'apexcharts-yaxis-title',
					},
				},
				max: yMax100? 100 : (val)=> { return val ; },
				labels: {
					style: {
						colors: !horizontal? 'gray' : 'slate' /*, 'darkOrange', 'blue', 'red'*/,
						fontSize: '18px',
						fontFamily: 'lato, Arial, sans-serif',
						fontWeight: 600,
						cssClass: 'y-axis-column-text',
					},
				},
			},
			title: {
				text: titleText? titleText : 'Title of Chart',
				floating: false,
				style: {
					color: 'slate',
					fontFamily: 'roboto, Arial, sans-serif',
					fontSize: '20px',
					fontWeight: 900,
				}
			},
			grid: {
				show: false,
			},
			noData: {
				text: 'No data to show (...for now)',
				align: 'center',
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: '100%',
							height: 295,
						},			
						colors: ['#025dff', '#ccc'],
						dataLabels: {
							enabled: true,
						},
						legend: {
							position: 'bottom',
						},
						title: {
							text: titleText? titleText : 'Title of Chart',
						},
					},
				},
			],
		},
	};

	return (
		<div className={className}>
			<ReactApexChart options={state.options} series={state.series} type="bar" height={380} />
		</div>
	);
};

export default BarChart;