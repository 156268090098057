import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bulkUploadTaskThunk, bulkUploadOverwriteTaskThunk, createCampaignThunk, getAllCampaignsThunk, getDayTasksThunk, getOutstandingTasksThunk, getAllTasksThunk, getAdminActionHistoryThunk, addBankThunk, getAllBanksThunk } from "./corporateSlice";
import Table from "../../components/tables/Table";
import { useForm } from "react-hook-form";
import PaginatedTable from "../../components/tables/PaginatedTable";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";


const Corporate = () => {
    const { register, handleSubmit, errors } = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token, account_type } = useSelector(state=> state.auth);
    const { allCampaigns, allTypes, allTasks, allBanks, dayTasks, outstandingTasks, adminActionHistory, campaignTypes } = useSelector(state=> state.corporate);
    const date= new Date();
    const today= `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;

    useEffect(() => {
		dispatch(getAllTasksThunk());
        dispatch(getAdminActionHistoryThunk());
        dispatch(getOutstandingTasksThunk());
    }, []);

    const tables= {
        campaign: {
            name: 'campaign',
            tHeaders: [ 'Campaign ID', 'Campaign Title', 'Description', 'Branch Scope (Target)', 'Start Date', 'End Date' ],
            tProperties: [ 'campaign_id', 'campaign_name', 'campaign_description', 'total_branch', 'start_date', 'end_date']
        },
        task: {
            name: 'task',
            tHeaders: [ 'Campaign ID', 'Evaluator Code', 'Branch Code', 'Assigned Date', 'Task Specifity' ],
            tProperties: [ 'campaign_id', 'evaluator_access_code', 'branch_id', 'date_assigned', 'task_type']
        },
        outstandingTask: {
            name: 'Outstanding Tasks',
            tHeaders: [ 'Branch Code', 'Bank', 'Branch Name', 'Address' ],
            tProperties: [ 'branch_id', 'bank_name', 'branch_name', 'address'],
        },
        adminHistory: {
            name: 'Admin Action History',
            tHeaders: [ 'Action', 'Admin', 'Data Affected (qty)', 'Date', ],
            tProperties: [ "action", "user", "record_affected", "date", ]
        },
    }

    const [selectDate, setSelectDate] = useState(null);
    useEffect(()=>{
        dispatch(getDayTasksThunk(selectDate));
    }, [dispatch, selectDate])

    const handleDateChange = (e) => {
        setSelectDate(e.target.value);
    }

    // CREATE CAMPAIGN
    const [formObj, setFormObj] = useState({});
    const handleFormChange = (e) => {
        const newForm= {...formObj};
        if ((e.target.name === 'bank' || e.target.name === 'assessment') && e.target.checked) {
            newForm[`${e.target.name}`] = newForm[e.target.name]? [...newForm[`${e.target.name}`], Number(e.target.value)] : [Number(e.target.value)];
        } else if ((e.target.name === 'bank' || e.target.name === 'assessment') && !e.target.checked) {
            newForm[`${e.target.name}`] = newForm[e.target.name].filter(id=> id !== Number(e.target.value));
        }
        else{
            newForm[`${e.target.name}`] = e.target.name === "compare_campaign_id" || e.target.name === "total_branch"? Number(e.target.value) : e.target.value;
        }
        
        setFormObj(newForm);
    }
    console.log({formObj})
    const handleFormSubmit = (e) => {
        e.preventDefault();
        console.log({ formObj });
        dispatch(createCampaignThunk(formObj))
        .then(({ meta })=>{
            if (meta.requestStatus == 'fulfilled') setFormDisplay("hidden"); dispatch(getAllCampaignsThunk());
        })
    }

    const [formDisplay, setFormDisplay] = useState("hidden");
    const showCreateCampaignForm = () => {
        // display form to create campaign
        setTaskFormDisplay("hidden");
        setBankFormDisplay("hidden");
        formDisplay === "hidden"? setFormDisplay("") : setFormDisplay("hidden");
    };

    const [taskFormDisplay, setTaskFormDisplay] = useState("hidden");
    const showTaskUploadForm = () => {
        setFormDisplay("hidden");
        setBankFormDisplay("hidden");
        
        taskFormDisplay === "hidden"? setTaskFormDisplay("") : setTaskFormDisplay("hidden");
    }
    const handleBulkUploadTask = ({taskFile, campaign_id, overwrite}) => {
        const formData = new FormData();
        formData.append('file', taskFile[0]);
        formData.append('campaign_id', campaign_id);

        overwrite? 
        dispatch(bulkUploadOverwriteTaskThunk(formData))
        .then(({meta})=> {
            if (meta.requestStatus === "fulfilled") { setTaskFormDisplay("hidden"); dispatch(getAllTasksThunk()); }
        })
         : 
        dispatch(bulkUploadTaskThunk(formData))
        .then(({meta})=> {
            if (meta.requestStatus === "fulfilled") { setTaskFormDisplay("hidden"); dispatch(getAllTasksThunk()); }
        });
        
        //console.log({ file: taskFile[0], campaign_id, overwrite });
    }

    const [bankFormDisplay, setBankFormDisplay] = useState("hidden");
    const showAddBankForm = () => {
        setTaskFormDisplay("hidden");
        setFormDisplay("hidden");
        bankFormDisplay === "hidden"? setBankFormDisplay("") : setBankFormDisplay("hidden");
    }
    const handleAddBank = ({bank_name, short_name}) => {
        dispatch(addBankThunk({bank_name, short_name}))
        .then(({meta})=> {
            if (meta.requestStatus === "fulfilled") { setBankFormDisplay("hidden"); dispatch(getAllBanksThunk()); }
        })
    }

    //useEffect(()=>{
        //dispatch(getOutstandingTasksThunk());
    //}, [])

    const [confirmActionDisplay, setConfirmActionFormDisplay] = useState('hidden')
    const [selectCampaign, setSelectCampaign] = useState({});
    const onClickDeleteCampaign = (record) => {
        console.log({record})
        setSelectCampaign(record);
        setConfirmActionFormDisplay('');
    }

    const handleDeleteCampaign = () => {
        // run delete campaign async logic and refresh page
    }

    const [selectTask, setSelectTask] = useState({});
    const onClickDeleteTask=(record)=>{
        console.log({record})
        setSelectTask(record);
        setConfirmActionFormDisplay('');
    }
    const handleDeleteTask = (e) => {
        e.preventDefault();
        console.log({selectTask, token})
        axios.delete(`https://mysteryshopper.connectifie.com/api/v1.0/campaign/assessment-scope/${selectTask.evaluator_scope_id}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        .then(({data})=>{
            console.log(data, "taskData")
            if (data.success) {
                toast.success("Task Delete Successful")
                dispatch(getAllTasksThunk());
                setConfirmActionFormDisplay('hidden');
            } else{
                toast.error("Error deleting task!")
            }
        }).catch(err=>{
            console.log(err.message);
        })
    }

    console.log({allTypes, allBanks, selectTask, campaignTypes})

    return (
        <div className="flex flex-col w-full h-max bg-slate-100 p-4 items-center justify-start shadow" >
            
            <div className="flex w-full my-2 justify-start items-center">
                <h1 className="font-bold w-max mx-3 text-xl text-slate-400">Corporate Affairs - </h1>
                <p className="font-medium text-slate-500">
                    Here you can <span className="text-blue-400"> Create Campaign and Assign tasks in bulk </span>
                </p>
            </div>
            <div className='flex justify-center w-full'>
                <div className="flex flex-col bg-white rounded border shadow items-center w-1/3 p-4 m-2">
                    <h1 className='font-bold text-blue-600'>Campaigns</h1>
                    <p className='font-bold text-xl text-slate-700'>{ allCampaigns? allCampaigns.length : 0}</p>
                </div>
                <div className="flex flex-col bg-white rounded border shadow items-center w-1/3 p-4 m-2">
                    <h1 className='font-bold text-blue-600'>Tasks Assigned</h1>
                    <p className='font-bold text-xl text-slate-700'>{allTasks? allTasks.length : 0}</p>
                </div>
                <div className="flex flex-col bg-white rounded border shadow items-center w-1/3 p-4 m-2">
                    <h1 className='font-bold text-blue-600'>All Banks</h1>
                    <p className='font-bold text-xl text-slate-700'>{allBanks? allBanks.length : 0}</p>
                </div>
            </div>

            
            <div className="flex mt-4 flex-wrap w-full h-max justify-start items-center">
                <div className='flex justify-center'>
                    <button onClick={showCreateCampaignForm} className="bg-dayoAshBlue text-white px-4 py-2 rounded-lg mx-2 my-4">Create Campaign</button>
                    <button onClick={showTaskUploadForm} className="bg-dayoAshBlue text-white px-4 py-2 rounded-lg mx-2 my-4">Bulk Upload Tasks</button>
                    <button onClick={showAddBankForm} className="bg-dayoAshBlue text-white px-4 py-2 rounded-lg mx-2 my-4">Add New Bank</button>
                    <button onClick={(e)=> { navigate('/client') }} className="bg-sideBarBG text-white px-4 py-2 rounded-lg mx-2 my-4">Sample Bank Client Dashboard</button>
                </div>
            </div>
            
            <div className={`${formDisplay} flex flex-col w-2/3 lg:w-1/3 min-w-max p-2 m-4 bg-white shadow-md rounded-lg`}>
                <form id='createCampaignForm' onChange={handleFormChange} onSubmit={handleFormSubmit} className={`flex flex-col w-full h-max p-4 rounded border border-slate-300 self-center justify-center shadow-md ${formDisplay}`}>
                    <h2 className="self-start min-width-max font-medium text-lg text-dayoAshBlue">Create Campaign</h2>
                    <div className="flex flex-col my-3 w-full h-max justify-center">
                        <label htmlFor="campaign_name" className=" min-w-max text-md text-slate-600">Campaign Name:</label>
                        <input id="campaign_name" name="campaign_name" type="text" value={formObj.campaign_name? formObj.campaign_name: ""} placeholder="A title to know your campaign by . . ." className="w-full h-max p-2 rounded border border-blue-100 text-blue-900 focus:outline-blue-300 hover:border-blue-300 hover:shadow-md" />
                    </div>
                    <div className="flex flex-col my-3 w-full h-max justify-center">
                        <label htmlFor="campaign_description" className=" min-w-max text-md text-slate-600">Campaign Description</label>
                        <textarea id="campaign_description" name="campaign_description" type="text" value={formObj.campaign_description? formObj.campaign_description: ""} placeholder="A little description about this campaign . . ." className="w-full h-max p-2 rounded border border-blue-100 text-blue-900 focus:outline-blue-300 hover:border-blue-300 hover:shadow-md" />
                    </div>
                    <div className="flex flex-col my-3 w-full h-max justify-center">
                        <label htmlFor="total_branch" className=" min-w-max text-md text-slate-600">Scope:</label>
                        <input id="total_branch" name="total_branch" type="number" value={formObj.total_branch? formObj.total_branch: ""} placeholder="Number of Bank Branches to be Evaluated (Project Scope)" className="w-full h-max p-2 rounded border border-blue-100 text-blue-900 focus:outline-blue-300 hover:border-blue-300 hover:shadow-md" />
                    </div>
                    <div className="flex flex-col my-3 w-full h-max justify-center">
                        <label htmlFor="start_date" className="min-w-max text-md text-slate-600">Start Date</label>
                        <input id="start_date" name="start_date" type="date" min={today} max='' placeholder="dd-mm-yyyy" value={formObj.start_date? formObj.start_date: ""} className="w-full h-max p-2 rounded border border-blue-100 text-blue-900 focus:outline-blue-300 hover:border-blue-300 hover:shadow-md bg-inherit" />
                    </div>
                    <div className="flex flex-col my-3 w-full h-max justify-center">
                        <label htmlFor="end_date" className=" min-w-max text-md text-slate-600">Finish Date</label>
                        <input id="end_date" name="end_date" type="date" value={formObj.end_date? formObj.end_date: ""} className="w-full h-max p-2 rounded border border-blue-100 text-blue-900 focus:outline-blue-300 hover:border-blue-300 hover:shadow-md bg-inherit" />
                    </div>
                    <div className="flex flex-col my-3 pt-3 w-full h-max justify-center border-t border-sideBarBlue">
                        <label htmlFor="compare_campaign_id" className=" min-w-max text-md text-slate-600">Compare with Campaign:</label>
                        <select id="compare_campaign_id" name="compare_campaign_id" className="rounded border border-blue-100 focus:outline-none text-slate-500 p-2" >
                            <option>Select Campaign</option>
                            {
                                allCampaigns && allCampaigns.map(campaign=> {
                                    return <option value={campaign.campaign_id}>{campaign.campaign_name}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="flex flex-col my-3 w-full h-max justify-center">
                        <h1>Select Banks Involved in Campaign</h1>
                        <div className="flex justify-evenly items-center">
                        {
                            allBanks && allBanks.map(bank=> (
                                <label className=" min-w-max text-md text-slate-600">
                                    <input 
                                        type='checkbox' 
                                        value={bank.bank_id} 
                                        name='bank'
                                        className="mx-2"
                                    />
                                    {bank.bank_name}
                                </label>
                            ))
                        }
                        </div>
                        
                    </div>
                    <div className="flex flex-col my-3 w-full h-max justify-center">   
                        <h1>Select Assessment Types Involved in Campaign</h1>
                        <div className="flex justify-evenly items-center">
                        {
                            allTypes && allTypes.map(type=> (
                                <label className=" min-w-max text-md text-slate-600">
                                    <input 
                                        type='checkbox' 
                                        value={type.assessment_type_id} 
                                        name='assessment'
                                        className="mx-2"
                                    />
                                    {type.type}
                                </label>
                            ))
                        }
                        </div>
                        
                    </div>

                    <input type='submit' value='Create Campaign' className="w-3/5 m-2 mx-auto hover:bg-blue-500 hover:text-white hover:scale-105 font-bold py-2 px-4 rounded border border-blue-500 text-blue-900" />
                </form>
            </div>

            <div className={`${taskFormDisplay} flex w-max p-2 m-4 bg-white shadow-md rounded-lg`}>
                <form className={` flex flex-col w-1/2 lg:w-1/2 p-4 rounded border border-slate-300 bg-300 justify-center shadow-md `} onSubmit={handleSubmit(handleBulkUploadTask)}>
                    <h1 className="text-slate-600 font-bold m-2 ml-0">Upload Tasks Mapping File</h1>
                    <input
                        {...register("taskFile")}
                        type="file"
                        className="border border-slate-500 rounded p-2"
                        accepted=".csv .xlsx .xls"
                        required
                    />
                    <select defaultValue={"DEFAULT"}
                        {...register("campaign_id")}
                     className="my-2 p-3 rounded bg-white border border-slate-500">
                        <option value="DEFAULT" disabled>Select Campaign</option>
                        {
                            allCampaigns? allCampaigns.map(campaign => {
                                return <option key={campaign.campaign_id} value={campaign.campaign_id}>{campaign.campaign_name}</option>
                            }) : null
                        }
                    </select>
                    <label className="font-bold text-red-500">
                        <input type='checkbox' {...register("overwrite")} id='overwrite' name='overwrite' className="form-checkbox text-dayoAshBlue focus:outline-none rounded w-6 h-6 my-2 mr-2 p-3 rounded bg-white border border-slate-500" />
                        Overwrite previous scope data
                    </label>
                    <input className="rounded w-full p-3 m-2 mx-auto text-slate-100 bg-sideBarBlue hover:font-bold border border-sideBarBlue hover:text-white" type="submit" value="Upload" />
                </form>

                <div className="m-3">
                    <h1 className="text-blue-500 font-bold m-2 ml-0">Upload Directives (for beginners)</h1>
                    <p>1. Download Template File</p>
                    <p>2. Fill Excel file correctly</p>
                    <p>3. Upload task mapping file</p>
                    <p>4 a. Check <strong className='text-red-500'>Overwrite</strong> to Replace (Overwrite) Current Scope</p>
                    <p>4 b. <strong className='text-red-500'> DO NOT CHECK OVERWRITE</strong> if you only want to Add New Tasks</p>
                    <p>5. Click Upload</p>
                    <ReactHtmlTableToExcel className="py-3 px-4 m-4 w-max h-max text-white bg-green-500" table={"templateTasks"} filename={"templateTasks"} sheet={"templateTasks"} buttonText={"Download Template File"} />
                </div>
                <table id="templateTasks" className="hidden">
                    <thead>
                        <tr>
                            {
                                ["EVALUATOR CODE","CAMPAIGN ID", "BRANCH CODE", "ASSESSMENT DATE (YYYY-MM-DD)", "ASSESSMENT TYPE [All, FLE, Security,]  or [Security]"].map(header => {
                                    return <th key={header}>{header}</th>
                                })
                            }
                        </tr>
                    </thead>
                </table>
            </div>

            <div className={`${bankFormDisplay} flex w-full justify-start p-2 m-4 bg-inherit shadow-md rounded-lg`}>
                <form className={` flex flex-col w-1/3 p-4 rounded border border-slate-300 bg-white justify-center shadow-md `} onSubmit={handleSubmit(handleAddBank)}>
                    <h1 className="text-slate-600 font-bold m-2 ml-0">Add New Bank</h1>
                    <label className="text-slate-700 w-full">
                        Bank Name <br />
                        <input
                            {...register("bank_name")}
                            type="text"
                            className="border border-slate-500 rounded p-2  w-full"
                            accepted=".csv .xlsx .xls"
                            required
                        />
                    </label>

                    <label className="text-slate-700 w-full">
                        Bank Code <br />
                        <input
                            {...register("short_name")}
                            type="text"
                            maxLength="3"
                            className="border border-slate-500 rounded p-2  w-full"
                            accepted=".csv .xlsx .xls"
                            required
                        />
                    </label>
                    
                    <input className="rounded w-full p-3 m-2 mx-auto text-slate-100 bg-sideBarBlue hover:font-bold border border-sideBarBlue hover:text-white" type="submit" value="Upload" />
                </form>
            </div>

            <section className="w-full p-2 mx-4 bg-white shadow-md rounded-lg">
                <h2 className="flex flex-wrap items-center text-lg font-bold text-blue-400 m-2">Tasks of Date: 
                    <input type='date' onChange={handleDateChange} defaultValue={today} placeholder='yyyy-mm-dd' className="w-1/4 h-max p-2 mx-2 rounded border border-blue-100 text-blue-900 focus:outline-blue-300 hover:border-blue-300 hover:shadow-md" />
                </h2>
                <div>
                    <PaginatedTable data={dayTasks} table={tables.task} pageLimit={5} dataLimit={10} isSelectable={false} />
                </div>
                <div>
                    <PaginatedTable data={allTasks} table={tables.task} pageLimit={14} dataLimit={14} isSelectable={false} isAction={account_type == '1'? true : false} actionElements={[{text: "Delete", onClick: onClickDeleteTask, class: 'w-full bg-dayoAshBlue rounded m-1 px-5 py-2 text-white hover:bg-sideBarBlue'},]} />
                </div>
            </section>

            {/*<section className="w-full p-2 m-4 bg-white shadow-md rounded-lg">
                <h2 className="flex flex-wrap items-center text-lg font-bold text-blue-400 m-2">Campaign Outstanding Tasks</h2>
                <div>
                    <PaginatedTable data={outstandingTasks} table={tables.outstandingTask} pageLimit={5} dataLimit={10} isSelectable={false} />
                </div>
                        </section>*/}

            <section className="w-full p-2 m-4 bg-white shadow-md rounded-lg">
                <h2 className=" text-lg font-bold text-blue-400 m-2">Admin Action History</h2>
                <div className="flex flex-col my-4 flex-wrap w-full h-max justify-center">
                    <PaginatedTable data={adminActionHistory} table={tables.adminHistory} pageLimit={10} dataLimit={10} />
                </div>
            </section>
            
            <section className="w-full p-2 m-4 bg-white shadow-md rounded-lg">
                <h2 className=" text-lg font-bold text-blue-400 m-2">Campaign List</h2>
                <div className="flex flex-col my-4 flex-wrap w-full h-max justify-center">
                    <PaginatedTable data={allCampaigns} table={tables.campaign} pageLimit={5} dataLimit={5} isAction={account_type == '1'? true : false} actionElements={[{text: 'Delete', onClick: onClickDeleteCampaign}]} />
                </div>
            </section>

            {/* Confirm Action */}
            <section className={`flex justify-center text-white items-center w-4/5 h-full bg-transparent fixed ${confirmActionDisplay}`}
                style={{backgroundColor: 'rgba(25, 25, 25, 0.2'}}
            >
                <form onSubmit={handleDeleteTask} className={`flex flex-col w-1/2 h-1/3 min-h-max lg:w-1/2 rounded-lg bg-slate-100 border border-slate-200 shadow-md`}>
                    <div className='flex w-full px-2 rounded-t-lg justify-between items-center bg-slate-200'>
                        <button onClick={(e)=>{e.preventDefault(); setConfirmActionFormDisplay("hidden")}} className="bg-white text-red-600 font-bold px-2 py-0 text-center rounded-full my-4 hover:bg-red-500 hover:text-white">x</button>
                    </div>
                    <div className="w-full h-full px-2">
                        <h1 className="flex items-center justify-center text-slate-600 font-md h-1/3">Are you sure you want to Delete this Task?</h1>
                        <div className="flex justify-between">
                            <input type="submit" className="flex items-center justify-center w-1/2 p-3 my-2 bg-red-600 hover:bg-red-500 font-bold rounded" value={`Delete Task`} />
                            <button onClick={(e)=>{e.preventDefault(); setConfirmActionFormDisplay("hidden")}} className="flex items-center justify-center w-1/2 p-3 my-2 mx-1 bg-dayoAshBlue hover:bg-sideBarBlue font-bold rounded" >Cancel</button>
                        </div>
                        
                    </div>
                                         
                </form>
            </section>

        </div>
    )
};

export default Corporate;