import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { bulkUploadBranch, deleteBranch, fetchBranchList, updateBranch, uploadBranch } from "./branchAPI";

const initialState = [];
const reducers = {};

export const branchListThunk = createAsyncThunk('branches/load', async (_, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload = await fetchBranchList(token, campaignID);
    return payload;
});

export const branchUploadThunk = createAsyncThunk('branches/upload', async (formData, { getState })=>{
    const { token } = getState().auth;
    const payload = await uploadBranch(token, formData);
    return payload;
});

export const branchBulkUploadThunk = createAsyncThunk('branches/bulkUpload', async (formData, { getState })=>{
    const { token } = getState().auth;
    const payload = await bulkUploadBranch(token, formData);
    return payload;
});

export const branchEditThunk = createAsyncThunk('branches/editBranch', async (branchID, { getState })=>{
    const { token } = getState().auth;
    const payload = await updateBranch(token, branchID);
    return payload;
});

export const branchDeleteThunk = createAsyncThunk('branches/deleteBranch', async (branchID, { getState })=>{
    const { token } = getState().auth;
    const payload = await deleteBranch(token, branchID);
    return payload;
});

const branchListSlice = createSlice({
    name: 'branches',
    initialState,
    reducers,
    extraReducers: (builder)=>{
        builder
            .addCase(branchListThunk.fulfilled, (state, action)=> [ ...action.payload ])
    }
});

export default branchListSlice.reducer;