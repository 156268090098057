import axios from "axios";
import toast from "react-hot-toast";
import { today } from "./Report";
//const campaignID = Number(sessionStorage.getItem('campaignID')) || 3;

const baseURL = 'https://mysteryshopper.connectifie.com/api/v1.0';

export const fetchReport = async (date= today, campaignID=3, dates, token) =>{
    try{
        const formattedDate= date.split('-')[0].length < 4? date.split('-').reverse().join('-') : date;


        toast.remove(); toast.loading(`Loading Evaluator reports for ${formattedDate}`);
        const { data } = await axios.get(`${baseURL}/home/${date}?campaign_id=${campaignID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        console.log({ date: formattedDate })

        const typeReports = await axios.get(`${baseURL}/evaluator/assessment/report/${formattedDate}?campaign_id=${campaignID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        toast.remove();
        if (date !== today) typeReports? toast.success("Data Fetched"): toast.error("No Data Found");
        
        const overview= data.allAssessment? data.allAssessment.map(entry => {
            const bankName = entry.campaign_assessment_branch_id.includes('ACB')? 'ACCESS' : entry.campaign_assessment_branch_id.includes('GTB')? 'GTB' : entry.campaign_assessment_branch_id.includes('STB')? 'STANBIC' : entry.campaign_assessment_branch_id.includes('ZNB')? 'ZENITH' : '';
            return {
                id: entry.evaluator_assessment_id,
                evaluator_code: entry.evaluator_access_code,
                bank_name: bankName,
                type: entry.type,
                branch_id: entry.campaign_assessment_branch_id,
                score: entry.totalScore
            }
        }) : [];
        console.log({ typeReports })

        const branch = typeReports && typeReports.data.BRANCH? typeReports.data.BRANCH.map(entry => {
            return {
                ...entry.scores_in_category,
                ...entry
            }
        }) : [];

        const fle= typeReports && typeReports.data.FLE? typeReports.data.FLE.map(entry => {
            return {
                ...entry.scores_in_category,
                ...entry
            }
        }) : [];

        const security= typeReports && typeReports.data.SECURITY? typeReports.data.SECURITY.map(entry => {
            return {
                ...entry.scores_in_category,
                ...entry
            }
        }) : [];

        const intern= typeReports && typeReports.data.INTERN? typeReports.data.INTERN.map(entry => {
            return {
                ...entry.scores_in_category,
                ...entry
            }
        }) : [];

        const evaluatorEntries = {
            overview: overview.reverse(),
            branch: branch.reverse(),
            fle: fle.reverse(),
            security: security.reverse(),
            intern: intern.reverse(),
        }

        //console.log({ evaluatorEntries })

        const dateOfReports= data.allDaysOfAssessment? data.allDaysOfAssessment.reverse() : [];
        const evaluatorsOfDay= data.numberOfEvalutor? data.numberOfEvalutor : 0;
        const bankAssessed= data.bankAssessed? data.bankAssessed : 0;
        const regionCover= data.regionCover? data.regionCover : 0;
        const staffAssessed= data.staffAssessed? data.staffAssessed : 0;
        const group= data.group? data.group : 0;

        return {
            entries: evaluatorEntries,
            dates: dateOfReports,
            totalEvaluators: evaluatorsOfDay,
            bankAssessed,
            staffAssessed,
            regionCover,
            group,
        };
    }catch(error){
        toast.remove();
        if(sessionStorage.token) toast.error("Error fetching data");
        console.log({error});
    };
    
};

export const fetchAllReport = async ( campaignID=3, token) =>{
    try{
        toast.loading(`Loading Evaluator reports for Campaign`);
        const { data } = await axios.get(`${baseURL}/evaluator/assessment/all-report/${campaignID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        //console.log({ data });

        

        toast.remove();
        data.success? toast.success("Data Fetched"): toast.error("No Data Found");
        
        const overview= data.allAssessment? data.allAssessment.map(entry => {
            const bankName = entry.campaign_assessment_branch_id.includes('ACB')? 'ACCESS' : entry.campaign_assessment_branch_id.includes('GTB')? 'GTB' : entry.campaign_assessment_branch_id.includes('STB')? 'STANBIC' : entry.campaign_assessment_branch_id.includes('ZNB')? 'ZENITH' : '';
            return {
                id: entry.evaluator_assessment_id,
                evaluator_code: entry.evaluator_access_code,
                bank_name: bankName,
                type: entry.type,
                branch_id: entry.campaign_assessment_branch_id,
                score: entry.totalScore
            }
        }) : [];

        return {
            entries: { overview: overview.reverse() },
        };
    }catch(error){
        toast.remove();
        if(sessionStorage.token) toast.error("Error fetching data");
        console.log({error});
    };
    
};

export const fetchAllTypeReport = async (assessmentTypeID, campaignID=3, token) =>{
    toast.loading('Loading Evaluator reports for Campaign');
    
    const {data: typeReports} = await axios.get(`${baseURL}/evaluator/assessment/all-report/${assessmentTypeID}/${campaignID}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    const type = assessmentTypeID === 3? 'BRANCH' : assessmentTypeID === 1? 'FLE' : assessmentTypeID === 2? 'SECURITY' : assessmentTypeID === 4? 'INTERN' : '';

    //console.log({typeReports});
    const formattedReport = typeReports[`${type}`]? typeReports[`${type}`].map(entry => {
        return {
            ...entry.scores_in_category,
            ...entry
        }
    }) : [];


    toast.remove(); toast.success("Data Fetched");
    
    //console.log({ formattedReport });
    return formattedReport;
}
 



export const patchEntry = async (formData, campaignID=3, token) => {
    try{
        //console.log({ ...formData })
        const { data } = await axios.patch(`${baseURL}/campaign/assessment/edit?campaign_id=${campaignID}`, {...formData}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        data.success? toast.success("Edited Entry Saved Successfully") : toast.error("Error Encountered: Edit not Saved");
        return data;
    } catch(e){
        console.log(e.message)
        toast.error(e.message)
    }
}

export const deleteEntry = async (assessmentID, campaignID=3, token) => {
    try{
        //console.log({ assessmentID })
        const { data } = await axios.delete(`${baseURL}/assessment/${assessmentID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        console.log({ deleteData: data })

        data.success? toast.success("Entry Deleted Successfully") : toast.error("Error Encountered: Delete Unsuccessful");
        return data;
    } catch(e){
        console.log(e.message)
        toast.error(e.message)
    }
}

export const getAlterEntry = async (assessmentID, token) => {
    try{
        //console.log({ assessmentID })
        const { data } = await axios.get(`${baseURL}/assessment/upgrade/${assessmentID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        //console.log({data: data.assessmentDetails})
        return data.assessmentDetails;
    } catch(e){
        console.log(e.message)
        toast.error(e.message)
    }
}

export const postAlterEntry = async (formDataArray, token) => {
    try{
        toast.loading("Saving Entry")
        const { data } = await axios.patch(`${baseURL}/score/edit`, 
        {
            allScore: formDataArray
        }, 
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        toast.remove();
        data.success? toast.success("Entry Saved Successfully") : toast.error("Error Encountered: Alteration Unsuccessful");
        return data;
    } catch(e){
        console.log(e.message)
        toast.error(e.message)
    }
}
