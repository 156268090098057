import axios from "axios";
import toast from "react-hot-toast";

const baseURL = 'https://mysteryshopper.connectifie.com/api/v1.0';

export const FetchBankInsight= async (token, campaignID=2) => {
    try{
        toast.remove(); toast.loading('Loading Insight');
        const { data } = await axios.get(`${baseURL}/report/general-insight/${campaignID}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        const { data: avg} = await axios.get(`${baseURL}/general-insight/average-score/${campaignID}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        //console.log({ avg });

        const authenticateNumber = (parentObj) => {
            const parentArray = Object.entries(parentObj);
            const newParentObj = {};
            parentArray.forEach(([key, value]) => {
                const cleanNumber = value == 'NaN' || !value? 0 : Number(value);
                return newParentObj[`${key}`]= cleanNumber;
            });

        }

        //console.log({ accessAvg: authenticateNumber(avg.AvgByBank.access) });


    
        const result = {
            accessInsight:  {
                total: data.totalByBank.access,
                average: avg.AvgByBank.access,
            },
            gtbInsight:  {
                total: data.totalByBank.gtb,
                average: avg.AvgByBank.gtb
            },
            stanbicInsight:  {
                total: data.totalByBank.stabic,
                average: avg.AvgByBank.stabic
            },
            zenithInsight:  {
                total: data.totalByBank.zenith,
                average: avg.AvgByBank.zenith
            },
        };

        //console.log({ result })
        toast.remove();
        toast.success('Insight Fetched');
        return result;
    } catch(e) {
        console.log(e.message)
    }
}


export const FetchBankInsightByState= async (token, campaignID=2, location) => {
    try{
        toast.remove();
        toast.loading('Loading State Insight');
        const { data } = await axios.get(`${baseURL}/report/general-insight/by-state/${location}/${campaignID}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        data? toast('Please be patient, this may take a while') : toast('Slow Network');

        const { data: avg} = await axios.get(`${baseURL}/general-insight/average-score/${campaignID}/${location}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });


    
        const result = {
            accessInsight:  {
                total: data.totalByBank.access,
                average: avg.AvgByBank.access,
            },
            gtbInsight:  {
                total: data.totalByBank.gtb,
                average: avg.AvgByBank.gtb
            },
            stanbicInsight:  {
                total: data.totalByBank.stabic,
                average: avg.AvgByBank.stabic
            },
            zenithInsight:  {
                total: data.totalByBank.zenith,
                average: avg.AvgByBank.zenith
            },
        };

        //console.log({ result })
        toast.remove();
        toast.success("State Insight Fetched");
        return result;
    } catch(e) {
        console.log(e.message)
    }
}
