import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import PaginatedTable from '../../components/tables/PaginatedTable';
import Table from '../../components/tables/Table';
import BranchBulkUploadForm from './BranchBulkUploadForm';
import BranchEditForm from './BranchEditForm';
import BranchSingleUploadForm from './BranchSingleUploadForm';
import { branchBulkUploadThunk, branchDeleteThunk, branchListThunk, branchUploadThunk } from './branchSlice';


const Branch = () => {
    const branches = useSelector(state => state.branches)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(branchListThunk());
    }, []);

    const table = {
        title : 'Branches',
        tHeaders : [ 'Branch', 'Address', 'Code', 'Bank', 'Group', 'State', 'Zone', 'Action' ],
        tProperties : [ 'name', 'address', 'code', 'bank', 'group', 'state', 'zone' ],
    }

    /* Upload Branches */
     /* ACTIONS */

    // Assign Task

    // Upload Evaluators
    const [uploadFormDisplay, setUploadFormDisplay] = useState("hidden");
    const [bulkUploadFormDisplay, setBulkUploadFormDisplay] = useState("hidden");
    const [confirmActionDisplay, setConfirmActionFormDisplay] = useState('hidden');

    const showSingleUploadForm = () => {
        setBulkUploadFormDisplay("hidden");
        uploadFormDisplay === "hidden"? setUploadFormDisplay("") : setUploadFormDisplay("hidden");
    }
    const showBulkUploadForm = () => {
        setUploadFormDisplay("hidden");
        bulkUploadFormDisplay === "hidden"? setBulkUploadFormDisplay("") : setBulkUploadFormDisplay("hidden");
    }

    const handleSingleUploadBranch = (record) => {
        const bankCode = record.bank_name == 'ACCESS'? 'ACB' : record.bank_name == 'GTB'? 'GTB' : record.bank_name == 'STANBIC'? 'STB' : record.bank_name == 'ZENITH'? 'ZNB' : ''
        /*console.log({
            ...record,
            branch_code: bankCode + record.branch_code
        })*/
        dispatch(branchUploadThunk({
            ...record,
            branch_code: bankCode + record.branch_code
        }))
        .then(({meta})=> {
            if (meta.requestStatus === "fulfilled") { setUploadFormDisplay("hidden"); dispatch(branchListThunk()); }
            else { toast.remove(); toast.error('Upload Failed, Please Try Again')}
        });
    }

    const handleBulkUploadTask = ({file}) => {
        const formData = new FormData();
        formData.append('file', file[0]);

        
        dispatch(branchBulkUploadThunk(formData))
        .then(({meta})=> {
            if (meta.requestStatus === "fulfilled") { setBulkUploadFormDisplay("hidden"); dispatch(branchListThunk()); }
        });
        
        //console.log({ file: file[0] });
    }


    const [record, setRecord] = useState({});

    const [editFormDisplay, setEditFormDisplay] = useState('hidden');

    const handleClickEditBranch = (record) => {
        console.log({record})
        setRecord(record);
        setUploadFormDisplay('hidden');
        setBulkUploadFormDisplay('hidden');
        setConfirmActionFormDisplay('hidden');
        setEditFormDisplay('');
    }

    const handleEditBranch = (record) => {
        console.log({record})
        const branchID = record.id;
    }

    const handleClickDeleteBranch = (record) => {
        //console.log({record})
        setRecord(record);
        setUploadFormDisplay('hidden');
        setBulkUploadFormDisplay('hidden');
        setEditFormDisplay('hidden');
        setConfirmActionFormDisplay('');
    }

    const handleDeleteBranch = (e) => {
        e.preventDefault();
        dispatch(branchDeleteThunk(record.id))
        .then(({meta})=> {
            if (meta.requestStatus === "fulfilled") { setConfirmActionFormDisplay("hidden"); dispatch(branchListThunk()); }
        });

    }





    /* FILTER LOGIC */

    const [filteredBranches, setFilteredBranches] = useState(branches)

    const [ searchTerm, setSearchTerm] = useState('');
    const handleSearchFilter = (e) => {
        setSearchTerm(e.target.value);
    }

    const [ groupFilterTerm, setGroupFilterTerm ] = useState('');
    const handleGroupFilter = (e) => {
        setGroupFilterTerm(e.target.value);
    }

    const [ bankFilterTerm, setBankFilterTerm ] = useState('');
    const handleBankFilter = (e) => {
        setBankFilterTerm(e.target.value);
    }

    useEffect(()=>{
        const ST = searchTerm.toLowerCase();
        const GFT = groupFilterTerm.toLowerCase();
        const BFT = bankFilterTerm.toLowerCase();

        if (ST.length && !GFT.length && !BFT.length) {
            setFilteredBranches(branches.filter(branch => branch.name.toLowerCase().includes(ST) || branch.address.toLowerCase().includes(ST) || branch.code.toLowerCase().includes(ST)/* || branch.bank.toLowerCase().includes(ST) || branch.group.toLowerCase().includes(ST) || branch.state.toLowerCase().includes(ST)*/))
        }
        else if (ST.length && GFT.length && !BFT.length) {
            setFilteredBranches(branches.filter(branch => (branch.name.toLowerCase().includes(ST) || branch.address.toLowerCase().includes(ST) || branch.code.toLowerCase().includes(ST)) && branch.group.toString() === GFT))
        }
        else if (ST.length && !GFT.length && BFT.length) {
            setFilteredBranches(branches.filter(branch => (branch.name.toLowerCase().includes(ST) || branch.address.toLowerCase().includes(ST) || branch.code.toLowerCase().includes(ST)) && branch.bank.toLowerCase() === BFT))
        }
        else if (!ST.length && GFT.length && !BFT.length) {
            setFilteredBranches(branches.filter(branch => branch.group.toString() === GFT))
        }
        else if (!ST.length && GFT.length && BFT.length) {
            setFilteredBranches(branches.filter(branch => branch.group.toString() === GFT && branch.bank.toLowerCase() === BFT))
        }
        else if (!ST.length && !GFT.length && BFT.length) {
            setFilteredBranches(branches.filter(branch => branch.bank.toLowerCase() === BFT))
        }
        else if (ST.length && GFT.length && BFT.length) {
            setFilteredBranches(branches.filter(branch => (branch.name.toLowerCase().includes(ST) || branch.address.toLowerCase().includes(ST) || branch.code.toLowerCase().includes(ST)) && branch.group.toString() === GFT && branch.bank.toLowerCase() === BFT))
        }
        else if (!ST.length && !GFT.length && !BFT.length) {
            setFilteredBranches(branches)
        } else { setFilteredBranches(branches) }


    }, [bankFilterTerm, branches, groupFilterTerm, searchTerm])
    

    return (
        <div className='flex flex-col bg-slate-100 py-4 px-1 '>
            <div className="flex w-full my-2 justify-start items-center">
                <h1 className="font-bold text-xl text-slate-400 mx-4 ">Manage Bank Branches - </h1>
                <p className="font-medium text-slate-500">
                    Here you can <span className="text-blue-400"> Create, Edit and Delete Branches</span>
                </p>
            </div>
            <div className='flex justify-center w-full'>
                <div className="flex flex-col bg-white rounded border shadow items-center w-1/3 p-4 m-2">
                    <h1 className='font-bold text-blue-600'>Branches</h1>
                    <p className='font-bold text-xl text-slate-700'>{branches.length}</p>
                </div>
                <div className="flex flex-col bg-white rounded border shadow items-center w-1/3 p-4 m-2">
                    <h1 className='font-bold text-blue-600'>Banks</h1>
                    <p className='font-bold text-xl text-slate-700'>{4}</p>
                </div>
            </div>

            <div className='flex justify-start'>
                <button onClick={showSingleUploadForm} className="bg-dayoAshBlue text-white px-4 py-2 rounded-lg mx-2 my-4">Add Branch</button>
                <button onClick={showBulkUploadForm} className="bg-dayoAshBlue text-white px-4 py-2 rounded-lg mx-2 my-4">Bulk Upload Branches</button>
            </div>

            {/* UPLOAD FORM */}
            <div className={`${uploadFormDisplay} overflow-x-scroll flex justify-center w-full my-4 bg-inherit rounded-lg`}>
                <BranchSingleUploadForm onSubmitForm={handleSingleUploadBranch} />
            </div>
            <div className={`${bulkUploadFormDisplay} overflow-x-scroll flex justify-center w-full py-4 my-4 bg-inherit rounded-lg`}>
                <BranchBulkUploadForm onSubmitForm={handleBulkUploadTask} />
            </div>

            <div className='flex flex-col border border-slate-100 shadow'>
                <div className="w-full m-1">
                    <input type='text' onChange={handleSearchFilter} className="w-1/3 m-3 px-3 py-2 rounded-full border border-slate-300 focus:outline-blue-400" placeholder="Search Branch Code, Branch Name" />
                    <select onChange={handleBankFilter} defaultValue={""} className="w-1/6 m-3 px-3 py-2 rounded border border-slate-300 focus:outline-blue-400 ">
                        <option value="">All Banks</option>
                        <option value="Access">Access</option>
                        <option value="GTB">GTB</option>
                        <option value="Stanbic">Stanbic</option>
                        <option value="Zenith">Zenith</option>
                    </select>

                    <select onChange={handleGroupFilter} defaultValue={""} className="w-1/6 m-3 px-3 py-2 rounded border border-slate-300 focus:outline-blue-400 ">
                        <option value="" >All Groups</option>
                        <option value="1">LPA</option>
                        <option value="2">Group 2</option>
                        <option value="3">Group 3</option>
                    </select>
                </div>
            </div>
            <PaginatedTable
                data={filteredBranches}
                table={table}
                dataLimit={14}
                pageLimit={10}
                isAction={true}
                actionElements={[{text: "Edit", onClick: handleClickEditBranch, class: 'w-full bg-dayoAshBlue rounded m-1 px-5 py-2 text-white hover:bg-sideBarBlue'}, {text: "Delete", onClick: handleClickDeleteBranch, class: 'w-full bg-dayoRed rounded px-5 m-1 py-2 text-red-700 hover:bg-red-400 hover:text-white'}]}
            />

            <section className={`flex justify-center text-white items-center w-4/5 h-full bg-transparent fixed ${confirmActionDisplay}`}
                style={{backgroundColor: 'rgba(25, 25, 25, 0.2'}}
            >
                <form onSubmit={handleDeleteBranch} className={`flex flex-col w-1/2 h-1/3 min-h-max lg:w-1/2 rounded-lg bg-slate-100 border border-slate-200 shadow-md`}>
                    <div className='flex w-full px-2 rounded-t-lg justify-between items-center bg-slate-200'>
                        <button onClick={(e)=>{e.preventDefault(); setConfirmActionFormDisplay("hidden")}} className="bg-white text-red-600 font-bold px-2 py-0 text-center rounded-full my-4 hover:bg-red-500 hover:text-white">x</button>
                    </div>
                    <div className="w-full h-full px-2">
                        
                    <h1 className="text-center text-slate-600 font-md"><span className={`font-bold text-red-500 `}>Delete</span> Branch: <span className='text-sideBarBG'>{record.code? `${record.code}`  : ''}</span></h1>
                        <h1 className="flex items-center justify-center text-slate-600 font-md h-1/3">Are you sure you want to Delete this Branch?</h1>
                        <div className="flex justify-between">
                            <input type="submit" className="flex items-center justify-center w-1/2 p-3 my-2 bg-red-600 hover:bg-red-500 font-bold rounded" value={`Delete Branch`} />
                            <button onClick={(e)=>{e.preventDefault(); setConfirmActionFormDisplay("hidden")}} className="flex items-center justify-center w-1/2 p-3 my-2 mx-1 bg-dayoAshBlue hover:bg-sideBarBlue font-bold rounded" >Cancel</button>
                        </div>
                        
                    </div>
                                         
                </form>
            </section>

            <section className={`flex justify-center text-white items-center w-4/5 h-full bg-transparent fixed ${editFormDisplay}`}
                style={{backgroundColor: 'rgba(25, 25, 25, 0.2'}}
            >
                <BranchEditForm onSubmitForm={handleEditBranch} onCancel={()=>{setEditFormDisplay('hidden') }} record={record} />
            </section>
            {/*<Table table={table} data={branches} />*/}
        </div>
    )
}

export default Branch;