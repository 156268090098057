import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser, signInThunk } from './authSlice';
import AdminSignin from './signInForms/AdminSignin';
import logo from '../../utilities/assets/logoOrange.svg';
import backgroundImage from "../../utilities/assets/blueBG.svg";
import manSitting from "../../utilities/assets/manSitting.svg";
import manHalo from "../../utilities/assets/manHalo.svg";
import blueBigCircle from "../../utilities/assets/blueBigCircle.svg";
import manSittingBG from "../../utilities/assets/manSittingBG.svg";
//import background from '../../images/cardbase.png';
//import AdminHeader from '../../components/AdminHeader/AdminHeader';

//const useAnimSnippet= <UseAnimations size={40} wrapperStyle={{ marginTop: '5px' }} animation={checkBox} />

export const greetTime = () => {
	const today = new Date();
	const hourOfDay = today.getHours(); //The current hour of today
	if (hourOfDay > 11 && hourOfDay <= 16) {
		return 'afternoon';
	} else if (hourOfDay > 16) {
		return 'evening';
	} else {
		return 'morning';
	}
}

export function SigninPage() {
	const dispatch = useDispatch();

	const auth = useSelector((state) => state.auth);

	const navigation = useNavigate();

	// navigate to dashboard on auth
	useEffect(() => {
		const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : false;
		//const { token } = auth;
		if (token) {
			dispatch(getCurrentUser())
			navigation('/admin');
		}
	}, [auth, dispatch, navigation]);

	const onSubmitForm = (e) => {
		dispatch(signInThunk({...e}));
	};

	return (
		<div
			className={`flex bg-white w-full min-h-max`}
			style={{ backgroundSize: 'cover', height: '100vh' }}
		>
			{/*<AdminHeader />*/}
			<div className='flex justify-center items-center w-0 md:w-1/3 md:w-3/5'>
				<img src={blueBigCircle} alt='bigCircle' className='fixed top-1/4 w-1/3 md:-top-1/2 md:-left-1/3 md:w-5/6 md:h-5/6 lg:-top-1/2 lg:-left-1/3 lg:w-9/12 lg:h-19/12' />
				<img src={manSittingBG} alt='furnitures Background' className='fixed z-0 w-full md:w-1/2 md:w-1/3 md:h-1/3 lg:h-1/2' />
				
				<div className='flex justify-center items-center overflow-x-hidden h-full '>
					<img src={manHalo} className='hidden md:block h-44 bottom-1/4 -right-8 md:h-28 relative md:bottom-28 md:-right-2/3 lg:h-36 lg:bottom-34' alt='halo'/>
					<img src={manSitting} className='h-3/4 md:h-2/5 md:relative md:-right-4 md:-bottom-12 lg:h-1/2 lg:-bottom-20 z-10' alt='man sitting criss-crossed'/>
				</div>
				
			</div>
			<div 
				className="z-10 w-full h-screen bg-white shadow-2xl md:rounded-none p-4 flex flex-col justify-center items-center md:w-2/5 h-full md:h-full lg:w-1/2 justify-self-end"
				style={{ 
					backgroundImage: `url(${backgroundImage})`, 
					backgroundSize: 'cover', height: '100vh' 
				}}
			>
				<div className='flex items-center mb-6'>
					<img className="w-1/2 md:w-full lg:w-full" src={logo} alt="logo" />

					<img src={manSitting} className='h-full w-1/2 md:hidden lg:hidden z-10' alt='man sitting criss-crossed'/>
				</div>
				
				<AdminSignin onSubmitForm={onSubmitForm} />
			
			</div>
		</div>
	);
}

export default SigninPage;
