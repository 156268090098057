import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { today } from "../report/Report";
import { fetchFlaggedReports, unflagReport, acceptFlagReport, bulkApproveFlagged, bulkDeleteFlagged, allCampaignFlaggedReports, fetchFlagHistory, fetchEvaluatorsFeedback } from "./flaggedIssuesAPI";

const initialState = {};
const reducers = {};

export const flaggedThunk = createAsyncThunk('flagged/load', async (date, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload = await fetchFlaggedReports(date, campaignID, token);
    if (date === today && payload.entries && payload.entries.length > 0) {toast.remove(); toast.error(`${payload.entries.length} flagged entries found.`)}
    return payload;
});

export const allCampaignFlaggedThunk = createAsyncThunk('flagged/campaign', async (_, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload = await allCampaignFlaggedReports(token, campaignID);
    //if (date === today && payload.entries && payload.entries.length > 0) {toast.remove(); toast.error(`${payload.entries.length} flagged entries found.`)}
    return payload;
});

export const allFlaggedHistoryThunk = createAsyncThunk('flagged/history', async (_, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload = await fetchFlagHistory(token, campaignID);
    //if (date === today && payload.entries && payload.entries.length > 0) {toast.remove(); toast.error(`${payload.entries.length} flagged entries found.`)}
    return {flaggedHistory: payload};
});

export const allFeedbackThunk = createAsyncThunk('flagged/feedback', async (_, { getState })=>{
    const { token } = getState().auth;
    const { campaignID } = getState().dataSummary;
    const payload = await fetchEvaluatorsFeedback(token, campaignID);
    //if (date === today && payload.entries && payload.entries.length > 0) {toast.remove(); toast.error(`${payload.entries.length} flagged entries found.`)}
    return {allFeedbacks: payload};
});

export const unflagReportThunk = createAsyncThunk('flagged/unflag', async (flagID, { getState })=>{
    const { token } = getState().auth;
    const payload = await unflagReport(token, flagID);
    return payload;
});

export const acceptFlagThunk = createAsyncThunk('flagged/acceptFlag', async (flagID, { getState })=>{
    const { token } = getState().auth;
    const payload = await acceptFlagReport(token, flagID);
    return payload;
});

export const bulkApproveFlagThunk = createAsyncThunk('flagged/bulkApproveFlag', async (flaggedArray, { getState })=>{
    const { token } = getState().auth;
    const flaggedIDs = flaggedArray.map(entry=>entry.flag_id);
    const payload = await bulkApproveFlagged(token, flaggedIDs);
    return payload;
});

export const bulkDeleteFlagThunk = createAsyncThunk('flagged/bulkDeleteFlag', async (flaggedArray, { getState })=>{
    const { token } = getState().auth;
    const flaggedIDs = flaggedArray.map(entry=>entry.flag_id);
    const payload = await bulkDeleteFlagged(token, flaggedIDs);
    return payload;
});

const flaggedSlice = createSlice({
    name: 'flagged',
    initialState,
    reducers,
    extraReducers: (builder)=>{
        builder
            .addCase(flaggedThunk.fulfilled, (state, action)=> ({ ...state, ...action.payload }))
            .addCase(allCampaignFlaggedThunk.fulfilled, (state, action)=> ({ ...state, ...action.payload }))
            .addCase(allFlaggedHistoryThunk.fulfilled, (state, action)=> ({ ...state, ...action.payload }))
            .addCase(allFeedbackThunk.fulfilled, (state, action)=> ({ ...state, ...action.payload }))
    }
});

export default flaggedSlice.reducer;