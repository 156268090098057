import classes from './AdminSidebar.module.css';
import SidebarItem from '../SidebarItem/SidebarItem';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setCampaignID } from '../../features/adminSummary/summarySlice';
/*import {
	ChevronRightRounded,
	KeyboardArrowDownRounded
} from '@material-ui/icons';*/

const AdminSidebar = ({ pages, setPage, currentPage }) => {
	// const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	const { allCampaigns } = useSelector(state => state.corporate);
	const campaignID = sessionStorage.getItem('campaignID');
	const campaign = allCampaigns? allCampaigns.find(campaign=> campaign.campaign_id == campaignID): {};

	const [campaign_id, setCampaign_id] = useState(campaignID);
    useEffect(()=> {
        setCampaign_id(campaignID);
    }, [campaignID]);

	return (
		<div className={`${classes.Sidebar} flex-1 sticky bg-sideBarBlue overflow-hidden `} style={{ /*backgroundColor: '#0c7af3',*/  }}>
			<h1 className="mt-4 py-3 font-bold text-lg text-slate-100 text-center w-full">{campaign? campaign.campaign_name : 'Campaign'} </h1>
			<div className="w-full px-2">
				<select onChange={(e)=> { dispatch(setCampaignID(e.target.value)); setCampaign_id(e.target.value) }}
					defaultValue={campaign_id}
					value={campaign_id}
					className="w-full px-2 py-3 my-2 bg-slate-100 rounded border border-dayoAshBlue focus:outline-none focus:border-dayoLightBlue text-dayoDarkGray text-sm"
				>
					<option value={'DEFAULT'} disabled>Select Campaign ID</option>
					{
						allCampaigns? allCampaigns.map((campaign, i) => {
							return <option key={i++} value={campaign.campaign_id} >{campaign.campaign_name}</option>
						}) : <option value={'LOADING'} disabled >No Campaigns</option>
					}
				</select>
			</div>
			<div>
				<div className="mb-3 p-0 ">
					{/* <h3>Dashboard</h3> */}
					<ul >
						{pages.map((page) => {
							// const activeIcon= page.id === currentPage? <KeyboardArrowDownRounded /> : <ChevronRightRounded />;
							return (
								<SidebarItem
									key={page.title}
									label={page.title}
									icon={page.icon}
									activeIcon={page.activeIcon}
									onClick={() => setPage(page.id)}
									active={currentPage === page.id}
								/>



							);
						})}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default AdminSidebar;
