/* eslint-disable max-len */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { signin } from './authAPI';

const initialState = { token: sessionStorage.getItem('token'), isLoading: false };
const reducers = {
    getCurrentUser: ()=>{ return {...sessionStorage}}
};

export const signInThunk = createAsyncThunk('auth/signin', async (formData) => {
    const payload = await signin(formData);
    return payload; //token is the payload
});

export const signOutThunk = createAsyncThunk('auth/signout', async () => ({token: false}));

// export const signOutThunk = createAsyncThunk('auth/signout', () => null);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder
        .addCase(signInThunk.pending, (state) => ({ isLoading: true }))
        .addCase(signInThunk.fulfilled, (state, action) => ({ ...action.payload, isLoading: false }))
        .addCase(signOutThunk.fulfilled, (state, action) => ({ ...action.payload, isLoading: false }));
  },
});

export const { getCurrentUser } = authSlice.actions;

export default authSlice.reducer;
