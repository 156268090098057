
import ReactApexChart from 'react-apexcharts';

const LineChart = ({ xaxis, data }) => {
    //console.log({ xaxis, data });
    const options = {
        series: [
            {
                name: "North West Trends",
                data: data.nw // for a week i.e 7 days
            },
            {
                name: "North Central Trends",
                data: data.nc // for a week i.e 7 days
            },
            {
                name: "North East Trends",
                data: data.ne // for a week i.e 7 days
            },
            {
                name: "South West Trends",
                data: data.sw // for a week i.e 7 days
            },
            {
                name: "South South Trends",
                data: data.ss // for a week i.e 7 days
            },
            {
                name: "South East Trends",
                data: data.se // for a week i.e 7 days
            },
            {
                name: "LPA Trends",
                data: data.lpa // for a week i.e 7 days
            }
        ],
        
			
        colors: ['#40d5d3', '#fccfd2', '#fb6d21', '#078eb0', '#1ea5ee', '#055a70', '#09add6'],
        chart: {
            height: 250,
            type: 'line',
            zoom: {
                enabled: true,
            },
        },
        dataLabels: {
            enabled: false,
            formatter: function (val) {
                //console.log({val})
                return val + "%";
            },
        },
        stroke: {
            curve: 'straight',
            width: 2,
            lineCap: 'butt'
        },
        title: {
            text: 'Daily Scope Completion trend by Zones (Percent)',
            align: 'left',
            style: {
                fontFamily: 'roboto, sans-serif',
                fontSize: '18px',
            }
        },
        noData: {
            text: 'No data to show (...for now)',
            align: 'center',
        },
        /*grid: {
            row: {
                colors: ['#41B883', '#afafaf', '#57a850'],
                opacity: 0.5
            }
        },*/
        yaxis: {
            show: true,
            max: 100,
            label: {
                show: true,
                formatter: (value) => { return value + '%'}
            },
            title: {
                text: 'Percentage coverage ( % )',
                style: {
                    fontFamily: 'roboto, sans-serif',
                    fontSize: '11px',
                }
            }
        },
        xaxis: {
            categories: xaxis,
        }
    };

    return (
        <div>
            <ReactApexChart options={options} series={options.series} height={300} />
        </div>
    )

};

export default LineChart;